import React, { useRef, useEffect } from 'react';

import useImageExpand from '../../stores/useImageExpand';
import { Container } from './style';

function ExpandImage() {
  const containerRef = useRef();
  const refLoadImage = useRef();
  const canvasRef = useRef();
  const imageRef = useRef();

  const imageExpand = useImageExpand((state) => state.imageExpand);
  const setImageExpand = useImageExpand((state) => state.setImageExpandStore);

  useEffect(() => {
    if (!imageExpand) return;

    const handleCloseImage = (event) =>
      event.keyCode === 27 && setImageExpand(() => null);

    document.addEventListener('keyup', handleCloseImage);

    return () => {
      document.removeEventListener('keyup', handleCloseImage);
    };
  }, [imageExpand, setImageExpand]);

  useEffect(() => {
    if (!imageExpand) return;
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    imageRef.current = null;
    img.src = imageExpand;

    img.onload = () => {
      imageRef.current = img;

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      if (refLoadImage.current)
        refLoadImage.current.remove();
    };

    img.onerror = () => {
      if (refLoadImage.current)
        refLoadImage.current.remove();
    };
  }, [imageExpand]);

  useEffect(() => {
    if (!imageExpand) return;
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const viewportTransform = {
      x: 0,
      y: 0,
      scale: 1
    };

    const render = () => {
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.setTransform(viewportTransform.scale, 0, 0, viewportTransform.scale, viewportTransform.x, viewportTransform.y);

      if (imageRef.current)
        ctx.drawImage(imageRef.current, 0, 0, imageRef.current.width, imageRef.current.height);
    }

    let previousX = 0, previousY = 0;

    const updatePanning = (e) => {
      const localX = e.clientX;
      const localY = e.clientY;

      viewportTransform.x += localX - previousX;
      viewportTransform.y += localY - previousY;

      viewportTransform.x = Math.min(0, Math.max(canvas.width * (1 - viewportTransform.scale), viewportTransform.x));
      viewportTransform.y = Math.min(0, Math.max(canvas.height * (1 - viewportTransform.scale), viewportTransform.y));

      previousX = localX;
      previousY = localY;
    };

    const updateZooming = (e) => {
      const oldX = viewportTransform.x;
      const oldY = viewportTransform.y;

      const localX = e.clientX;
      const localY = e.clientY;

      const previousScale = viewportTransform.scale;

      let newScale = viewportTransform.scale + e.deltaY * -0.002;
      newScale = Math.min(4, Math.max(1, newScale));

      let newX = localX - (localX - oldX) * (newScale / previousScale);
      let newY = localY - (localY - oldY) * (newScale / previousScale);

      if (newScale === 1) {
        newX = 0;
        newY = 0;
      } else {
        newX = Math.min(0, Math.max(canvas.width * (1 - newScale), newX));
        newY = Math.min(0, Math.max(canvas.height * (1 - newScale), newY));
      }

      viewportTransform.x = newX;
      viewportTransform.y = newY;
      viewportTransform.scale = newScale;
    };

    const onMouseMove = (e) => {
      updatePanning(e);
      render();
    };

    const onMouseWheel = (e) => {
      updateZooming(e);
      render();
    };

    const container = containerRef.current;

    const onMouseDown = (e) => {
      e.preventDefault();

      previousX = e.clientX;
      previousY = e.clientY;

      container.addEventListener("mousemove", onMouseMove);
    };

    const onMouseUp = (e) => {
      container.removeEventListener("mousemove", onMouseMove);
    };

    const onDoubleClick = (e) => {
      e.preventDefault();

      if (viewportTransform.scale === 1) {
        viewportTransform.scale = 2;
        viewportTransform.x = -canvas.width / 2;
        viewportTransform.y = -canvas.height / 2;
      } else {
        viewportTransform.x = 0;
        viewportTransform.y = 0;
        viewportTransform.scale = 1;
      }

      render();
    };

    canvas.addEventListener("wheel", onMouseWheel);
    canvas.addEventListener("mousedown", onMouseDown);
    canvas.addEventListener("dblclick", onDoubleClick);
    container.addEventListener("mouseup", onMouseUp);
    container.addEventListener("mouseleave", onMouseUp);

    render();

    return () => {
      canvas.removeEventListener("wheel", onMouseWheel);
      canvas.removeEventListener("mousedown", onMouseDown);
      canvas.removeEventListener("dblclick", onDoubleClick);
      container.removeEventListener("mouseup", onMouseUp);
      container.removeEventListener("mousemove", onMouseMove);
      container.removeEventListener("mouseleave", onMouseUp);
    };
  }, [imageExpand]);

  return (
    imageExpand && (
      <Container
        ref={containerRef}
      >
        <div
          className="tw-bg-bar dark:tw-bg-bar-dark"
          onClick={() => setImageExpand(() => null)}
        />

        <i className="fe fe-x" onClick={() => setImageExpand(() => null)} />
        <img src={`${process.env.PUBLIC_URL}assets/logo.png`} alt="logo-sak" />

        <span ref={refLoadImage} />

        <label>
          <canvas ref={canvasRef} />
        </label>
      </Container>
    )
  );
}

export { ExpandImage };
