import { useTranslation } from 'react-i18next';

import { Modal, ModalHeader, ModalTitle, ModalSubTitle, ModalCloseButton, ModalBody } from './Modal';
import { CheckBox } from '../../FormInput';
import Tooltip from '../../../contents/Tooltip';
import useModalStore from '../../../stores/useModalStore';
import usePreferencesStore from '../../../stores/usePreferencesStore';

export default function PrefsModal () {
  const closeModal = useModalStore((state) => state.closeModal);
  const prefStore = usePreferencesStore((state) => state);

  const { t } = useTranslation();

  return (
    <Modal open={true} onClose={closeModal} size="xl">
      <ModalHeader className="tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <ModalTitle>
            { t('Preferências') }
          </ModalTitle>
          <ModalSubTitle>
            { t('Configurações do aplicativo') }
          </ModalSubTitle>
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody className="tw-flex tw-flex-col tw-gap-8">
        <div>
          <span className="tw-font-semibold tw-text-gray-900 dark:tw-text-white">
            { t('Notificações') }
          </span>

          <div
            className="tw-flex tw-items-center tw-mt-4 tw-gap-2"
          >
            <CheckBox
              checked={prefStore.playNotificationSound}
              onChange={(e) => {
                prefStore.setPlayNotificationSound(e.target.checked);
              }}
            />
            <label className="tw-ml-2 tw-mb-0">
              {t('Reproduzir sons ao receber mensagens')}
            </label>
          </div>
        </div>
        <div>
          <span className="tw-font-semibold tw-text-gray-900 dark:tw-text-white">
            { t('Contatos') }
          </span>

          <div
            className="tw-flex tw-items-center tw-mt-4 tw-gap-2"
          >
            <CheckBox
              checked={prefStore.useLegacyContactSorting}
              onChange={(e) => {
                prefStore.setUseLegacyContactSorting(e.target.checked);
              }}
            />
            <label className="tw-ml-2 tw-mb-0">
              {t('Utilizar ordenação clássica')}
              <Tooltip text={t('Quando ativa, a lista de contatos será ordenada de forma decrescente com base na data da última mensagem.')}>
                <i className="fe fe-help-circle ml-3"></i>
              </Tooltip>
            </label>
          </div>
        </div>
        <div>
          <span className="tw-font-semibold tw-text-gray-900 dark:tw-text-white">
            { t('Desempenho') }
          </span>

          <div
            className="tw-flex tw-items-center tw-mt-4 tw-gap-2"
          >
            <CheckBox
              checked={prefStore.paginateContacts}
              onChange={(e) => {
                prefStore.setPaginateContacts(e.target.checked);
              }}
            />
            <label className="tw-ml-2 tw-mb-0">
              {t('Ativar paginação de contatos')}
              <Tooltip text={t('Quando ativa, a lista de contatos será dividida em páginas.')}>
                <i className="fe fe-help-circle ml-3"></i>
              </Tooltip>
            </label>
          </div>

          <div
            className="tw-flex tw-items-center tw-mt-4 tw-gap-2"
          >
            <CheckBox
              checked={prefStore.fetchUnreadContactsOnly}
              onChange={(e) => {
                prefStore.setFetchUnreadContactsOnly(e.target.checked);
              }}
            />
            <label className="tw-ml-2 tw-mb-0">
              {t('Carregar apenas contatos com mensagens não lidas')}
              <Tooltip text={t('Quando ativo, apenas contatos marcados como não-lido serão carregados na inicialização do aplicativo.')}>
                <i className="fe fe-help-circle ml-3"></i>
              </Tooltip>{' '}
              <p>
                <small>
                  <b style={{ color: 'red' }}>
                    *{t('Necessário recarregar a página')}*
                  </b>
                </small>
              </p>
            </label>
          </div>

          <div
            className="tw-flex tw-items-center tw-mt-4 tw-gap-2"
          >
            <CheckBox
              checked={prefStore.virtualizeContacts}
              onChange={(e) => {
                prefStore.setVirtualizeContacts(e.target.checked);
              }}
            />
            <label className="tw-ml-2 tw-mb-0">
              {t('Renderização experimental da barra de contatos')}
              <Tooltip text={t('Quando ativa, um método diferente de renderização será utilizado na lista de contatos. Esta funcionalidade pode melhorar o desempenho em dispositivos antigos.')}>
                <i className="fe fe-help-circle ml-3"></i>
              </Tooltip>
            </label>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
