import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import 'react-intl-tel-input/dist/main.css';
import { Button } from '@headlessui/react';

import { BsFilter, BsPlusLg, BsThreeDots  } from "react-icons/bs";
import { RiCloseFill, RiCheckboxCircleLine } from "react-icons/ri";
import { HiOutlineUserAdd, HiOutlineUsers } from "react-icons/hi";
import { HiOutlineChevronDown } from "react-icons/hi2";

import Tooltip from '../Tooltip';
import Errors from '../Errors';
import Contato from '../Contato';
import MensagemBusca from '../MensagemBusca';

import { normalizeText } from '../../utils/formatDados';
import useDadosSakStore from '../../stores/useDadosSakStore';
import useSocketStore from '../../stores/useSocketStore';
// import useRenderStore from '../../stores/useRenderStore';
import useMarcarLidoStore from '../../stores/useMarcarLidoStore';
import usePreferencesStore from '../../stores/usePreferencesStore';
import useModalStore from '../../stores/useModalStore';
// import MensagensAntigas from '../../components/mensagensAntigas';
import { StaticSearchBar } from '../../components/FormInput';
import { ErrorChat } from '../../components/errors';
import { Dropdown, DropdownButton, DropdownMenu, DropdownSection, DropdownItem, DropdownIcon } from '../../components/Dropdown';
import { ContatosPagination } from './components/ContatosPagination';

import { VariableSizeList as List, areEqual } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

function Aside({
  marcadores,
  lojas,
  mensagens,
  contatos,
  search,
  setSearch,
  tab,
  setTab
}) {
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  const { t } = useTranslation();

  const contatosAtuais = useMemo(() => contatos, [contatos]);
  const mensagensAtuais = useMemo(() => mensagens, [mensagens]);

  const virtualizedContactListRef = useRef(null);
  const containerListaContatos = useRef(null);
  const iconSearch = useRef(null);

  const ctt = useDadosSakStore((state) => state.contatos);

  // const shouldRender = useRenderStore((state) => state.shouldRender);

  const [msg, setMsg] = useState('');
  const [contatosPage, setContatosPage] = useState(0);
  const [paginationQuantity] = useState(50);
  const totalContatosCountRef = useRef(0);

  const paginateContacts = usePreferencesStore((state) => state.paginateContacts);
  const virtualizeContacts = usePreferencesStore((state) => state.virtualizeContacts);
  const useLegacyContactSorting = usePreferencesStore((state) => state.useLegacyContactSorting);

  const [notifTab, setNotifTab] = useState({
    Novos: 0,
    Meus: 0,
    Ativos: 0,
    Todos: 0,
  });

  const userLogado = useDadosSakStore((state) => state.userLogado);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const toggleMarcarLidoBoxAndCheckbox = useMarcarLidoStore(
    (state) => state.toggleMarcarLidoBoxAndCheckbox
  );
  const showMarcarLidoBtn = useMarcarLidoStore(
    (state) => state.showMarcarLidoBtn
  );
  const checkboxMarcadosComoLidoQuantity = useMarcarLidoStore(
    (state) => state.checkboxMarcadosComoLidoQuantity
  );
  const setToggleMarcarLidoBoxAndCheckbox = useMarcarLidoStore(
    (state) => state.setToggleMarcarLidoBoxAndCheckbox
  );

  const oneDay = 1000 * 60 * 60 * 24;
  const teenDays = ~~((Date.now() - oneDay * 10) / 1000);

  const [listDates] = useState({
    'Ultimas 24 Horas': ~~((Date.now() - oneDay) / 1000),
    'Ultimos 2 Dias': ~~((Date.now() - oneDay * 2) / 1000),
    'Ultimos 3 Dias': ~~((Date.now() - oneDay * 3) / 1000),
    'Ultimos 7 Dias': ~~((Date.now() - oneDay * 7) / 1000),
    'Ultimos 15 Dias': ~~((Date.now() - oneDay * 15) / 1000),
    'Ultimo Mês': ~~((Date.now() - oneDay * 30) / 1000),
    'Ultimo Semestre': ~~((Date.now() - oneDay * 180) / 1000),
    'Mostrar Tudo': 0,
  });

  const contactFilterRef = useRef(null);
  const contactFilterStateRef = useRef(null);
  const contactTimespanFilterRef = useRef(listDates[window.localStorage?.getItem('cFilter_timespan')] ?? listDates['Ultimos 3 Dias']);

  if (msg) mensagens = msg;

  // Paginação automática
  // useEffect(() => {
  //   if (!window.__hasAutoOptimized && !virtualizeContacts) {
  //     const numOfContacts = Object.keys(mensagens || {}).length;
  //     if (numOfContacts < 1536) return;

  //     window.__hasAutoOptimized = true;
  //     window.notifChat('Paginação ativada automaticamente para evitar problemas de performance. Você pode desativá-la a qualquer momento através da tela de otimizações.', 'info');
  //     // setOtimizationCheckbox(true);
  //   }
  // }, [virtualizeContacts, mensagens]);

  // Resetar paginação ao mudar de aba
  useEffect(() => {
    if (paginateContacts)
      setContatosPage(0);
  }, [tab, lojaAtivo, paginateContacts]);

  useEffect(() => {
    if (
      !ctt ||
      (ctt && !ctt[lojaAtivo]) ||
      (ctt[lojaAtivo] && !Object.values(ctt[lojaAtivo]).length)
    ) {
      setNotifTab({ Novos: 0, Meus: 0, Ativos: 0, Todos: 0 });
      return;
    }

    const notifTabInfo = { Novos: 0, Meus: 0, Ativos: 0, Todos: 0 };

    const contact = ctt?.[lojaAtivo];

    for (let index in contact) {
      if (!contact?.[index]?.['ultimamsg']) continue;
      if (contact?.[index]?.['ultimamsg'] <= teenDays) continue;
      if (Number(contact?.[index]?.['naolidas']) === 0) continue;
      if (!contact?.[index]?.['chatid']) continue;

      contact[index].userchat = String(contact[index].userchat);
      contact[index].arquivado = String(contact[index].arquivado);
      userLogado.userchat = String(userLogado.userchat);

      if (
        contact[index].userchat === '0' &&
        contact[index].arquivado === '0' &&
        contact[index].ultimamsgtexto
      )
        notifTabInfo['Novos']++;
      if (
        userLogado.userchat &&
        contact[index].userchat === userLogado.userchat &&
        contact[index].fechado === '0' &&
        contact[index].arquivado === '0'
      )
        notifTabInfo['Meus']++;
      if (
        contact[index].userchat !== '0' &&
        contact[index].fechado === '0' &&
        contact[index].arquivado === '0'
      )
        notifTabInfo['Ativos']++;

      notifTabInfo['Todos']++;
    }

    setNotifTab({ ...notifTab, ...notifTabInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctt, userLogado, lojaAtivo]);

  useEffect(() => {
    contactFilterRef.current = null;
    contactFilterStateRef.current = null;
    contactTimespanFilterRef.current = listDates[window.localStorage?.getItem('cFilter_timespan')] ?? listDates['Ultimos 3 Dias'];
    setMsg('');
  }, [lojaAtivo, listDates]);

  useEffect(() => {
    if (msg && search?.length) {
      contactFilterRef.current = null;
      contactFilterStateRef.current = null;
      setMsg('');
    }
  }, [msg, search])

  function validaTempoMensagem(mensagem, _total) {
    if (!mensagem) return;

    const filterDate = contactTimespanFilterRef.current || 0;
    const findLastTime = Object.values(mensagem).some((message) => {
      const msgDate = message?.['data_whats'] || message?.['inclusao'];
      const existContato =
        ctt[lojaAtivo] &&
        message['conversa'] &&
        ctt[lojaAtivo][message['conversa']];

      return (
        (search && search.length > 3) ||
        Number(filterDate) <= 0 ||
        Number(msgDate) > Number(filterDate) ||
        (existContato && Number(existContato['naolidas'])) > 0
      );
    });
    return findLastTime;
  }

  // function validaTempoMensagem(mensagem, total) {
  //   if (!mensagem) return;
  //   const findLastTime = Object.values(mensagem).some((message) => {
  //     const msgDate = message?.['data_whats'] || message?.['inclusao'];
  //     const existContato =
  //       ctt[lojaAtivo] &&
  //       message['conversa'] &&
  //       ctt[lojaAtivo][message['conversa']];

  //     if (
  //       msgDate < teenDays &&
  //       (!search || (search && search.length < 3) || total > 500)
  //     )
  //       return false;

  //     return (
  //       (search && search.length > 3) ||
  //       defaultFilterDate === 0 ||
  //       Number(msgDate) > Number(defaultFilterDate) ||
  //       (existContato && Number(existContato['naolidas'])) > 0
  //     );
  //   });
  //   return findLastTime;
  // }

  function handleFilter () {
    let filteredContats = (contatosAtuais && Object.values(contatosAtuais)) || [];
    filteredContats = filteredContats.filter(contactFilterRef.current);

    const newMessages = {};
    for (const contact of filteredContats) {
      if (!contact.chatid || !mensagensAtuais[contact.chatid]) continue;

      const selectedTimespan = contactFilterStateRef.current?.selectedTimespan || 0;
      if (selectedTimespan > contact.ultimamsg && !validaTempoMensagem(mensagensAtuais[contact.chatid])) continue;

      newMessages[contact.chatid] = mensagensAtuais[contact.chatid];
    }

    if (tab !== 'Todos') setTab('Todos');
    setMsg(newMessages);
  }

  function filtro_procura_contato(mensagens, search) {
    const contatosFiltrados =
      contatos &&
      Object.values(contatos).filter((contato) => {
        const searchByName =
          contato.nome &&
          contato.nome !== 'undefined' &&
          typeof contato.nome == 'string'
            ? contato.nome
            : contato.chatid &&
              contato.chatid.split('@') &&
              contato.chatid.split('@')[0];

        const searchByPhone =
          contato.chatid &&
          contato.chatid.split('@') &&
          contato.chatid.split('@')[0];

        return (
          normalizeText(searchByName, 'lower').indexOf(
            normalizeText(search, 'lower')
          ) !== -1 ||
          normalizeText(searchByPhone, 'lower').indexOf(
            normalizeText(search, 'lower')
          ) !== -1
        );
      });

    const novasMensagens = {};
    contatosFiltrados &&
      contatosFiltrados.forEach((contato, index) => {
        if (index <= 100 && contato.chatid && mensagens?.[contato.chatid]) {
          novasMensagens[contato.chatid] = mensagens[contato.chatid];
        }
      });

    return novasMensagens;
  }

  function filtro_procura_mensagem(mensagens, search) {
    try {
      const searchable = Object.values(mensagens)
        .map(m => Object.values(m)).flat()
        .filter(m => !!m.texto);
  
      // const matches = window.fuzzysort.go(search, searchable, { key:'texto', limit: 30, threshold: .2 })
      //   .map(m => ({
      //     msg: m.obj,
      //     text: m.highlight('*', '*'),
      //   }));

      const fuse = new window.Fuse(searchable, {
        keys: ['texto'],
        ignoreLocation: true,
        ignoreFieldNorm: true,
        minMatchCharLength: 2,
      });

      const matches = fuse.search(search, { limit: 30 })
        .map(result => ({ msg: result.item, text: result.item.texto }));
      
      return matches || [];
    } catch { return []; }
  }

  function contatosLoja() {
    function sortContatosMsg(msgs, desc = false) {
      if (!msgs) return;

      let sortable = Object.entries(msgs);

      if (!sortable) return;

      sortable.sort((a, b) => {
        const objA = a[1];
        const objB = b[1];

        const ultima_keyA = Object.keys(objA).slice(-1)[0];
        const ultima_keyB = Object.keys(objB).slice(-1)[0];

        const lastKeyA = objA[ultima_keyA];
        const lastKeyB = objB[ultima_keyB];

        const keyAConversa = lastKeyA?.['conversa'] || '';
        const keyBConversa = lastKeyB?.['conversa'] || '';

        const dataA =
          contatosAtuais?.[keyAConversa]?.['ultimamsg'] ||
          lastKeyA['data_whats'];
        const dataB =
          contatosAtuais?.[keyBConversa]?.['ultimamsg'] ||
          lastKeyB['data_whats'];

        return desc ? dataB - dataA : dataA - dataB;
      });

      return sortable;
    }

    function entriesToHopefullyOrderedObject(entries) {
      const newMessages = {};

      entries.forEach(([key, value]) => {
        newMessages[key] = value;
      });

      return newMessages;
    }

    const unsearchedMensagens = !paginateContacts && search
      ? mensagens : null;
    let mensagensLista = mensagens;

    if (search) {
      iconSearch.current && iconSearch.current.classList.remove('fe-search');
      iconSearch.current && iconSearch.current.classList.add('fe-x');
      mensagensLista = filtro_procura_contato(mensagens, search);
      if (tab !== 'Todos') setTab('Todos');
    } else {
      if (
        iconSearch.current &&
        !iconSearch.current.classList.contains('fe-search')
      ) {
        iconSearch.current.classList.remove('fe-x');
        iconSearch.current.classList.add('fe-search');
      }
    }

    let contatos_nao_lidos = {};
    let contatos_lidos = {};
    let totalContactNumber = 0;

    for (let index in mensagensLista) {
      const listMessages = mensagensLista?.[index] || null;
      if (!listMessages) continue;
      if (!msg && !validaTempoMensagem(listMessages, totalContactNumber))
        continue;

      if (Number(contatosAtuais?.[index]?.['naolidas']) === 0) {
        contatos_lidos[index] = listMessages;
      } else {
        contatos_nao_lidos[index] = listMessages;
      }
      totalContactNumber++;
    }

    let contactOrder = [];
    
    if (useLegacyContactSorting) {
      contactOrder = sortContatosMsg({ ...contatos_nao_lidos, ...contatos_lidos }, true);
    } else {
      contactOrder = [
        ...sortContatosMsg(contatos_nao_lidos),
        ...sortContatosMsg(contatos_lidos, true),
      ];
    }

    mensagens = entriesToHopefullyOrderedObject(contactOrder);
    contactOrder = contactOrder.map(([key]) => key);

    const otimizationContatosCondition = (mensagens) => {
      if (paginateContacts) {
        totalContatosCountRef.current = mensagens.length;
        return mensagens.slice(
          contatosPage * paginationQuantity,
          (contatosPage + 1) * paginationQuantity
        );
      } else {
        return mensagens;
      }
    };

    let dados = contactOrder?.filter((c) => {
      if (typeof contatosAtuais?.[c] != 'object') return false;

      contatosAtuais[c].userchat = String(contatosAtuais[c].userchat);
      userLogado.userchat = String(userLogado.userchat);
      contatosAtuais[c].fechado = String(contatosAtuais[c].fechado);
      contatosAtuais[c].naolidas = String(contatosAtuais[c].naolidas);
      contatosAtuais[c].arquivado = String(contatosAtuais[c].arquivado);

      switch (tab) {
        case 'Novos':
          if (
            contatosAtuais[c].userchat === '0' &&
            contatosAtuais[c].arquivado === '0' &&
            contatosAtuais[c].ultimamsgtexto
          ) return true;
          break;
        case 'Meus':
          if (
            userLogado.userchat &&
            contatosAtuais[c].userchat === userLogado.userchat &&
            contatosAtuais[c].fechado === '0' &&
            contatosAtuais[c].arquivado === '0'
          ) return true;
          break;
        case 'Ativos':
          if (
            contatosAtuais[c].userchat !== '0' &&
            contatosAtuais[c].fechado === '0' &&
            contatosAtuais[c].arquivado === '0'
          ) return true;
          break;
        case 'Todos':
          return true;
        default:
          return false;
      }

      return false;
    });

    dados = otimizationContatosCondition(dados);

    if (virtualizeContacts && !unsearchedMensagens) {
      const temMensagemNaoLida = contatosAtuais && Object.entries(contatosAtuais).some((el) => el[1]['naolidas']);

      const ContatoByIndex = (index) => {
        const c = dados[index];
  
        const lastMessage = mensagens[contatosAtuais[c].chatid]
        ? Object.values(mensagens[contatosAtuais[c].chatid]).slice(-1)[0]
        : '';
  
        return (
          <Contato
            key={c}
            contato={contatosAtuais[c]}
            lastMessage={lastMessage}
            temMensagemNaoLida={temMensagemNaoLida}
          />
        );
      };

      const ContatoRow = memo(({ index, style, isScrolling }) => (
        <div className="tw-pr-2" style={style}>
          { ContatoByIndex(index) }
          {/* { isScrolling || ContatoByIndex(index) } */}
        </div>
      ), areEqual);

      const getItemSize = index => {
        if (!marcadores) return 72;
        const contato = contatosAtuais[dados[index]];
        const temMarcadores = contato?.marcadores?.some?.(m => m in marcadores);
        return temMarcadores ? 96 : 72;
      }

      // Gambia para resetar a lista virtualizada
      if (virtualizedContactListRef.current) {
        if (
          virtualizedContactListRef.current.prevCount !== dados.length ||
          virtualizedContactListRef.current.prevAccount !== lojaAtivo
        ) {
          virtualizedContactListRef.current.prevCount = dados.length;
          virtualizedContactListRef.current.prevAccount = lojaAtivo;
          virtualizedContactListRef.current.resetAfterIndex(0);
        }
      }
      
      return (
        <AutoSizer
          className="[div.tab-scroll:has(&)]:tw-overflow-hidden"
        >
          {({ height, width }) => (
            <List
              // useIsScrolling
              // className="List"
              ref={virtualizedContactListRef}
              className="
                [&::-webkit-scrollbar-thumb]:tw-bg-scrollbar [&::-webkit-scrollbar-thumb]:dark:tw-bg-scrollbar-dark
                [&::-webkit-scrollbar-track]:tw-rounded-[10px] [&::-webkit-scrollbar]:tw-w-1
              "
              height={height}
              itemCount={dados.length}
              itemSize={getItemSize}
              // width={width}
              width={width + 8}
            >
              {ContatoRow}
            </List>
          )}
        </AutoSizer>
      );
    }

    const temMensagemNaoLida = contatosAtuais && Object.entries(contatosAtuais).some((el) => el[1]['naolidas']);

    dados = dados.map(c => {
      const lastMessage = mensagens[contatosAtuais[c].chatid]
        ? Object.values(mensagens[contatosAtuais[c].chatid]).slice(-1)[0] : '';

      // const temMensagemNaoLida = Object.entries(contatosAtuais).some((el) => el[1]['naolidas']);

      return (
        <Contato
          key={c}
          contato={contatosAtuais[c]}
          lastMessage={lastMessage}
          temMensagemNaoLida={temMensagemNaoLida}
        />
      );
    });
    
    if (unsearchedMensagens) {
      const matches = filtro_procura_mensagem(unsearchedMensagens, search);

      const els = matches.map(m => {
        return (<MensagemBusca
          key={'search_' + m.msg.id}
          texto={m.text}
          mensagem={m.msg}
          contato={contatosAtuais[m.msg.conversa]}
        />)
      });

      if (els.length) {
        const msgdiv = (
          <div key='search_msgdiv' className='p-6 font-bold'>
            {t('Mensagens')}
          </div>
        );

        const msgdisc = (
          <div key='search_msgdisc' className='p-6'>
            <small>
              <i className='fe fe-alert-circle pr-2' />
              {t('Mensagens mais antigas que 1 semana podem não aparecer no resultado da busca')}
            </small>
          </div>
        );

        if (dados.length) {
          const chatdiv = (
            <div key='search_chatdiv' className='p-6 font-bold'>
              {t('Conversas')}
            </div>
          );

          dados.unshift(chatdiv);
        }
        
        dados.push(msgdiv, ...els, msgdisc);
      }
    }

    return dados;
  }

  const dictionary = {
    Novos: t('Novos'),
    Meus: t('Meus'),
    Ativos: t('Ativos'),
    Todos: t('Todos'),
  };

  /**
   * marca conversas selecionadas como lidas
   * @param {Array} chatIds
   */
  function marcarConversaLido(chatIds) {
    chatIds.forEach((id) => {
      if (
        !sendSocketDados({
          function: 'enviarMarcarLido',
          data: [
            {
              chatid: id,
              userserv: lojaAtivo,
              userchat: userLogado.userchat,
            },
          ],
        })
      );
      //CONDIÇÃO APENAS PARA ATUALIZAR A VIEW MAIS RÁPIDO (SE NÃO QUISER ESPERAR A RESPOSTA DO WEBSOCKET PODE COLOCAR **PODE FICAR PESADO)
      // setContatos((prev) => {
      //   const updatedContatos = { ...prev };
      //   Object.keys(updatedContatos[lojaAtivo]).forEach((id) => {
      //     updatedContatos[lojaAtivo][id] = {
      //       ...updatedContatos[lojaAtivo][id],
      //       naolidas: '0',
      //     };
      //   });
      //   return updatedContatos;
      // }, true);
    });
  }

  function arquivarConversa(chatIds) {
    chatIds.forEach((id) => {
      if (
        !sendSocketDados({
          function: 'arquivarContato',
          data: [
            {
              id: id,
              userserv: lojaAtivo,
              userchat: userLogado.userchat,
            },
          ],
        })
      );
    });
  }

  return (
    <>
      <StaticSearchBar
        className="tw-mx-5 //tw-shadow-sm"
        placeholder={t('Buscar contato por nome ou tel')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        inputProps={{ 'aria-label': t('Procurar') }}
      />

      <div className="d-flex align-items-center justify-content-between tw-mx-5 tw-mt-2.5">
        <Dropdown as="div">
          <DropdownButton
            className="
              tw-h-9
              tw-px-3 tw-rounded-md tw-relative
              tw-text-gray-500 dark:tw-text-gray-400
              tw-bg-west-bar dark:tw-bg-transparent
              hover:tw-brightness-95 dark:hover:tw-bg-west-bar-dark
              focus:tw-outline-none
              tw-flex tw-gap-1.5 tw-items-center
            "
          >
            { dictionary[tab] }
            <HiOutlineChevronDown className="tw-w-5 tw-h-5 tw-text-gray-400"/>

            {
              Object.entries(notifTab).some(([key, value]) => value > 0 && key !== tab ) && (
                <span
                  className="
                    tw-absolute tw-top-0.5 tw-right-0.5
                    tw-ml-1 tw-size-2 tw-rounded-full
                    tw-bg-red-500 tw-ring-1 tw-ring-red-500/20
                  "
                />
              )
            }
          </DropdownButton>

          <DropdownMenu anchor="bottom start">
            <DropdownSection>
              {
                Object.entries(notifTab)?.map(([texto, count], index) => (
                  <DropdownItem
                    key={'notifTab_' + index}
                    onClick={() => setTab(texto)}
                  >
                    { dictionary[texto] }

                    {
                      count > 0 && (
                        <sup
                          className="
                            tw-ml-1 tw-size-2 tw-rounded-full
                            tw-bg-red-500 tw-ring-1 tw-ring-red-500/20
                          "
                        />
                      )
                    }
                  </DropdownItem>
                ))
              }
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>

        <div className="tw-flex tw-gap-2 tw-items-center">
          <Dropdown as="div">
            <Tooltip text="Mais">
              <DropdownButton
                className="
                  tw-p-2 tw-rounded-md
                  tw-bg-west-bar dark:tw-bg-transparent
                  tw-text-gray-500 dark:tw-text-gray-400
                  hover:tw-brightness-95 dark:hover:tw-bg-west-bar-dark
                  focus:tw-outline-none
                "
              >
                <BsPlusLg className="tw-w-5 tw-h-5 tw-text-gray-400"/>
              </DropdownButton>
            </Tooltip>

            <DropdownMenu anchor="bottom end">
              <DropdownSection>
                <DropdownItem
                  onClick={() => {
                    useModalStore.getState().setModal('add_contact');
                  }}
                >
                  <DropdownIcon icon={HiOutlineUserAdd} />
                  {t('Novo Contato')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    useModalStore.getState().setModal('join_group');
                  }}
                >
                  <DropdownIcon icon={HiOutlineUsers} />
                  {t('Juntar-se a um grupo')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setToggleMarcarLidoBoxAndCheckbox(
                      !toggleMarcarLidoBoxAndCheckbox
                    );
                  }}
                >
                  <DropdownIcon icon={RiCheckboxCircleLine} />
                  {t('Selecionar conversas')}
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>

          <Tooltip text="Filtros">
            <Button
              type="submit"
              className="
                tw-p-2 tw-rounded-md
                tw-bg-west-bar dark:tw-bg-transparent
                tw-text-gray-500 dark:tw-text-gray-400
                hover:tw-brightness-95 dark:hover:tw-bg-west-bar-dark
                focus:tw-outline-none
              "
              onClick={() => {
                useModalStore.getState().setModal('filter_contacts', {
                  prevState: contactFilterStateRef.current,
                  onFilter: (filterFunction, newState) => {
                    contactFilterRef.current = filterFunction;
                    contactFilterStateRef.current = newState;
                    handleFilter();
                  }
                })
              }}
            >
              <BsFilter className="tw-w-5 tw-h-5 tw-text-gray-400"/>
            </Button>
          </Tooltip>
        </div>
      </div>

      {toggleMarcarLidoBoxAndCheckbox ? (
        <section
          className="
            tw-mx-5 tw-mt-2.5 tw-gap-2 tw-py-2 tw-px-3
            tw-flex tw-flex-wrap tw-items-center tw-justify-between
            tw-text-gray-500 dark:tw-text-gray-400
            tw-bg-west-bar dark:tw-bg-west-bar-dark tw-rounded-md"
        >
          <div className="tw-flex tw-items-center tw-gap-2">
            <span
              className="tw-cursor-pointer"
              onClick={() => {
                const checkboxes = Array.from(document.querySelectorAll('[name="checkbox_marcar_lido"]:checked'));

                if (checkboxes.length > 0) {
                  const checkedSetter = Object.getOwnPropertyDescriptor(checkboxes[0].constructor.prototype, 'checked').set;

                  checkboxes.forEach(el => checkedSetter.call(el, false));
                  checkboxes[0].dispatchEvent(new Event('click', { bubbles: true }));
                }

                setToggleMarcarLidoBoxAndCheckbox(false);
              }}
            >
              <RiCloseFill className="tw-w-5 tw-h-5" />
            </span>
            <span>{t('Selecionados')}: {checkboxMarcadosComoLidoQuantity}</span>
          </div>

          <Dropdown as="div">
            <Tooltip text="Mais">
              <DropdownButton
                className="
                  tw-p-2 tw-rounded-md
                  tw-bg-west-bar dark:tw-bg-transparent
                  tw-text-gray-500 dark:tw-text-gray-400
                  hover:tw-brightness-95 dark:hover:tw-bg-west-bar-dark
                  focus:tw-outline-none
                "
              >
                <BsThreeDots className="tw-w-5 tw-h-5"/>
              </DropdownButton>
            </Tooltip>

            <DropdownMenu anchor="bottom end">
              <DropdownSection>
                <DropdownItem
                  onClick={() => {
                    const checkboxes = Array.from(document.querySelectorAll('[name="checkbox_marcar_lido"]')).slice(0, 100);

                    if (checkboxes.length > 0) {
                      const checkedSetter = Object.getOwnPropertyDescriptor(checkboxes[0].constructor.prototype, 'checked').set;

                      checkboxes.forEach(el => checkedSetter.call(el, true));
                      checkboxes[0].dispatchEvent(new Event('click', { bubbles: true }));
                    }
                  }}
                >
                  {t('Selecionar todos')}
                </DropdownItem>

                {
                  showMarcarLidoBtn && (
                    <DropdownItem
                      onClick={() => {
                        const values = Array.from(
                          document.querySelectorAll(
                            '[name="checkbox_marcar_lido"]:checked'
                          )
                        ).map((el) => el.value);
                        marcarConversaLido(values);
                      }}
                    >
                      {t('Marcar selecionados como lido')}
                    </DropdownItem>
                  )
                }

                {
                  showMarcarLidoBtn && (
                    <DropdownItem
                      onClick={() => {
                        const values = Array.from(
                          document.querySelectorAll(
                            '[name="checkbox_marcar_lido"]:checked'
                          )
                        ).map((el) => el.value);
                        arquivarConversa(values);
                      }}
                    >
                      {t('Arquivar selecionados')}
                    </DropdownItem>
                  )
                }
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
        </section>
      ) : null}

      {
        lojas?.chatsak === '0' && (
          <div className="d-flex rounded-0 pt-3 pb-3 align-items-center bg-danger text-white">
            <div
              className="ml-4 mr-1 p-4 bg-white rounded-circle d-flex align-items-center justify-content-center"
              style={{ height: '45px', width: '45px', flexShrink: '0', color: 'rgb(56,54,49)', fontSize: '1.5rem' }}
            >
              <i className="fe fe-alert-octagon"/>
            </div>
            <div className="d-flex flex-column p-4">
              <strong>Chat não habilitado</strong>
              <p>
                O chat encontra-se desabilitado para esta conta de WhatsApp.
                As mensagens não serão sincronizadas em tempo real.
                Caso deseje habilitar esta funcionalidade, acesse as <u><a className="text-white" href="https://app.sak.com.br/whatsapps">configurações de WhatsApp</a></u> no painel do SAK.
              </p>
            </div>
          </div>
        )
      }

      <Errors />
      <div
        className="
          tab-scroll
          tw-overflow-y-scroll
          tw-mt-2.5 tw-mx-2 tw-pl-3 tw-pr-2
          [&::-webkit-scrollbar]:tw-w-1
          [&::-webkit-scrollbar-thumb]:tw-bg-scrollbar [&::-webkit-scrollbar-thumb]:dark:tw-bg-scrollbar-dark
        "
        ref={containerListaContatos}
        role="grid"
      >
        {/* {!search && <MensagensAntigas />} */}
        <ErrorChat codeError={777}>{contatosLoja()}</ErrorChat>
        {paginateContacts && (
          <ContatosPagination
            contatosPage={contatosPage}
            setContatosPage={setContatosPage}
            // mensagens={mensagens}
            totalCount={totalContatosCountRef.current}
            paginationQuantity={paginationQuantity}
          />
        )}

        {mensagens && Object.values(mensagens)?.length < 15 && !virtualizeContacts && (
          <button
            type="button"
            className="
              sak--novo-contato tw-flex tw-flex-col
              tw-w-full
              tw-items-center tw-justify-center
              tw-text-gray-500 dark:tw-text-gray-400
              tw-text-sm tw-leading-6 tw-font-normal
              tw-rounded-md tw-mt-2.5 tw-border-none
              hover:tw-bg-west-bar dark:hover:tw-bg-west-bar-dark 
              dark:hover:tw-text-white
            "

            onClick={() => useModalStore.getState().setModal('add_contact')}
          >
            <span>{t('Não encontrou o que procura?')}</span>
            <span className="tw-text-active dark:tw-text-active-dark">{t('Adicione um novo contato')}</span>
          </button>
        )}
      </div>
    </>
  );
}

export default memo(Aside);
