import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { classnames } from '../../utils/usefulFunctions';


export function Dropdown ({ className, ...props }) {
  return <Menu className={classnames(className, 'tw-relative tw-inline-block tw-text-left')} {...props} />
}

export function DropdownMenu ({ anchor = 'bottom', className, ...props }) {
  return <MenuItems
    {...props}
    transition
    anchor={anchor}
    className={classnames(className, `
      tw-absolute tw-right-0 tw-z-10
      tw-mt-2 tw-w-56 tw-origin-top-right
      tw-divide-y tw-divide-gray-200 dark:tw-divide-gray-700
      tw-rounded-md tw-shadow-lg
      tw-bg-chat-area dark:tw-bg-chat-area-dark
      tw-ring-1 tw-ring-opacity-5
      tw-ring-black dark:tw-ring-west-bar-dark
      tw-transition
      focus:tw-outline-none
      data-[closed]:tw-scale-95 data-[closed]:tw-transform
      data-[closed]:tw-opacity-0 data-[enter]:tw-duration-100
      data-[leave]:tw-duration-75 data-[enter]:tw-ease-out data-[leave]:tw-ease-in

      [&::-webkit-scrollbar]:tw-w-1
      [&::-webkit-scrollbar-thumb]:!tw-bg-scrollbar
      [&::-webkit-scrollbar-thumb]:dark:!tw-bg-scrollbar-dark
    `)}
  />
}

export function DropdownButton ({ as, ...props }) {
  return <MenuButton {...props} as={as} />
}

export function DropdownItem ({ className, ...props }) {
  const Element = props.as || (props.href ? 'a' : 'span');

  return (
    <MenuItem>
      <Element
        {...props}
        className={classnames(className, `
          tw-group tw-cursor-pointer
          tw-flex tw-items-center
          tw-px-4 tw-py-2
          tw-text-sm
          tw-text-gray-500 dark:tw-text-gray-400
          data-[focus]:tw-bg-gray-100 data-[focus]:dark:tw-bg-neutral-700
          data-[focus]:tw-text-gray-700 data-[focus]:dark:tw-text-gray-100
        `)}
      />
    </MenuItem>
  )
}

export function DropdownSection ({ className, ...props }) {
  return (
    <div className={classnames(className, 'tw-py-1')} {...props} />
  )
}

export function DropdownIcon ({ icon: Icon, className, ...props }) {
  return <Icon
    {...props}
    aria-hidden="true"
    className={classnames(className, `
      tw-mr-3 tw-size-5
      tw-text-gray-400 group-hover:tw-text-gray-500
      dark:tw-text-gray-500 dark:group-hover:tw-text-gray-400
    `)}
  />
}
