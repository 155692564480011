import { Transition } from "@headlessui/react";
import useSWR from "swr";

import { AudioPlayer } from "../AudioPlayer"
import { sakBaseUrl } from "../../../../utils/usefulFunctions";
import useIntersectionObserver from "../../../../hooks/useIntersectionHook";

function useTranscription (audio_url) {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
    audio_url
  });

  const queryUrl = audio_url ? sakBaseUrl('/Js/TranscreverAudio?' + queryParams.toString()) : null;

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: Number.MAX_SAFE_INTEGER,
    revalidateIfStale: false,
    shouldRetryOnError: false
  });

  return {
    transcription: data?.data?.transcription || '',
    isLoading,
    error,
    mutate
  };
}

/*
  TODO: reimplementar e otimizar
*/

export default function VoiceMessage ({ msg }) {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });

  const canTranscribe = !msg.texto && entry?.isIntersecting && msg.me < 1 && !msg.conversa.endsWith('@g.us');
  const { transcription, isLoading } = useTranscription(canTranscribe && msg.anexo);

  return (
    <div>
      <AudioPlayer src={msg.anexo} />
      
      <div ref={ref}>
        {
          isLoading && (
            <div
              className="
                tw-mt-7 tw-flex tw-flex-col tw-gap-0.5
                [&>div]:tw-w-full [&>div]:tw-h-4 [&>div]:tw-rounded-md
                [&>div]:tw-animate-pulse [&>div]:tw-bg-gray-200
              "
            >
              <div/>
              <div/>
            </div>
          )
        }
        
        <Transition show={ !!transcription || !!msg.texto }>
          <div
            className="
              tw-mt-7 tw-text-xs tw-text-gray-600 dark:tw-text-neutral-200
              tw-transition tw-duration-300 tw-ease-in data-[closed]:tw-opacity-0
            "
          >
            <blockquote className="tw-italic tw-max-w-[18rem]">
              { transcription || msg.texto }
            </blockquote>
          </div>
        </Transition>
      </div>
    </div>
  )
}
