import { create } from 'zustand';

const useSideExtensionStore = create((set) => ({
  isSideExtensionOpen: false,
  setIsSideExtensionOpen: (value) => set({ isSideExtensionOpen: value }),
  toggleIsSideExtensionOpen: () => set((state) => ({ isSideExtensionOpen: !state.isSideExtensionOpen })),

  drawer: null,
  setDrawer: (name, data = {}) => {
    set({ drawer: { name, data } });
  },
  closeDrawer: () => {
    set({ drawer: null });
  }
}));

export default useSideExtensionStore;
