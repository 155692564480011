import { useTranslation } from 'react-i18next';
import { HiOutlineMenu, HiOutlineX } from 'react-icons/hi';

import { Dropdown, DropdownButton, DropdownMenu, DropdownSection, DropdownItem } from '../../../../components/Dropdown';
import { formatTime, ansiTimestringToDate } from '../../../../utils/formatDados';
import { sakBaseUrl } from '../../../../utils/usefulFunctions';
import useSideExtensionStore from '../../../../stores/useSideExtensionStore';

function overwriteChatInput (text) {
  const chatInputEl = document.getElementById('chat-id-1-input');
  Object.getOwnPropertyDescriptor(chatInputEl, 'value').set.call(chatInputEl, text);
  chatInputEl.dispatchEvent(new Event('input', { bubbles: true }));
}

function copyToClipboard (text) {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };

  document.body.appendChild(el);
  el.select();

  document.execCommand('copy');
  document.body.removeChild(el);

  if (text)
    window.notifChat('Copiado com sucesso!', 'success');
}

export default function FAQDrawer () {
  const { t } = useTranslation();

  const { data } = useSideExtensionStore(state => state.drawer);
  const closeDrawer = useSideExtensionStore(state => state.closeDrawer);

  // console.log(data.article)

  return (
    <div
      className="
        tw-absolute tw-z-30
        tw-top-0 tw-left-0 tw-right-0 tw-bottom-0
        tw-w-full tw-h-full

        tw-bg-bar dark:tw-bg-bar-dark
        tw-flex tw-flex-col tw-items-center
      "
    >
      <div
        className="
          tw-w-full
          tw-mt-3.5
          tw-py-[calc(34px/2)] tw-px-5
          tw-border-b tw-border-seg-border dark:tw-border-seg-border-dark
          tw-flex tw-justify-between tw-items-center //tw-flex-row-reverse
        "
      >
        <Dropdown as="div" className="!tw-flex">
          <DropdownButton
            className="focus:tw-outline-none"
          >
            <HiOutlineMenu className="tw-w-6 tw-h-6 tw-text-gray-900 dark:tw-text-white" />
          </DropdownButton>
          <DropdownMenu anchor="bottom start">
            <DropdownSection>
              <DropdownItem
                href={sakBaseUrl('/faqadm#artigo=' + data.article.id_artigo)}
                target="_blank"
                rel="noreferrer"
              >
                { t('Editar artigo') }
              </DropdownItem>
              <DropdownItem
                href={data.config.faq_url + 'faq/' + data.article.slug_artigo}
                target="_blank"
                rel="noreferrer"
              >
                { t('Visualizar artigo') }
              </DropdownItem>
              <DropdownItem
                onClick={() => overwriteChatInput(data.config.faq_url + 'faq/' + data.article.slug_artigo)}
              >
                { t('Enviar link do artigo') }
              </DropdownItem>
              <DropdownItem
                onClick={() => copyToClipboard(data.config.faq_url + 'faq/' + data.article.slug_artigo)}
              >
                { t('Copiar link do artigo') }
              </DropdownItem>
              <DropdownItem
                onClick={() => copyToClipboard(data.article.busca)}
              >
                { t('Copiar texto do artigo') }
              </DropdownItem>
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>

        <span
          className="
            tw-text-lg/5 tw-font-semibold
            tw-text-gray-900 dark:tw-text-white
          "
        >
          { t('FAQ') }
        </span>

        <span
          className="tw-cursor-pointer"
          onClick={closeDrawer}
        >
          <HiOutlineX className="tw-w-6 tw-h-6 tw-text-gray-900 dark:tw-text-white" />
        </span>
      </div>
      
      <div
        className="
          tw-mt-5
          tw-min-h-0
          tw-overflow-y-auto

          [&::-webkit-scrollbar]:tw-w-1
          [&::-webkit-scrollbar-thumb]:!tw-bg-scrollbar
          [&::-webkit-scrollbar-thumb]:dark:!tw-bg-scrollbar-dark
        "
      >
        <div
          className="
            tw-mx-5
            tw-text-2xl tw-leading-normal
            tw-font-bold
            tw-text-gray-900 dark:tw-text-white
          "
        >
          { data.article.nome }
        </div>

        <div
          className="
            tw-flex
            tw-mt-2 tw-mx-5
            tw-text-sm tw-font-normal
            tw-text-gray-400 dark:tw-text-neutral-300
          "
        >
          <div>
            <p>{ t('Escrito por') } { data.article.autor }</p>
            <p>{ t('Atualizado em') } { formatTime(ansiTimestringToDate(data.article.tempo).getTime() / 1e3) }</p>
          </div>
        </div>

        <div
          className="
            tw-mx-5 tw-mt-5
            //[&_*]:!tw-bg-[unset]
          "
          dangerouslySetInnerHTML={{ __html: data.article.descricao }}
        />
      </div>
    </div>
  );
}