import { useTranslation } from 'react-i18next';

const LANG_NAMES = {
  'pt-BR': 'Português do Brasil',
  'es': 'Español de Latam',
  // 'es': 'Español de Latinoamérica',
};

export default function LanguageSelector ({
  children
}) {
  const { i18n } = useTranslation();

  return (
    <span
      onClick={() => {
        i18n.changeLanguage(i18n.language === 'pt-BR' ? 'es' : 'pt-BR');
      }}
    >
      { children?.(LANG_NAMES[i18n.language] || i18n.language) }
    </span>
  )
}
