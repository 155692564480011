import { create } from 'zustand';

const useModalStore = create((set) => ({
  modal: null,
  setModal: (name, data = {}) => {
    set({ modal: { name, data } });
  },
  closeModal: () => {
    set({ modal: null });
  }
}));

export default useModalStore;
