import styled from 'styled-components';
import { classnames } from '../../utils/usefulFunctions';

export const Container = styled.div`
  flex-direction: column;
  overflow-x: visible;
  gap: .625rem;

  display: flex;
  background: transparent;
  position: relative;
  z-index: 0;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #7e8896;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--scroll);
    border-radius: 20px;
  }

  @media (max-width: 1200px) {
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1366px) {
    ::-webkit-scrollbar {
      height: 8px;
    }
  }
`;

export const ContainerLoja = styled.div`
  height: 100%;
`;

export const Icon = styled.div
  .attrs(_ => ({
    className: `
      [--tw-color-red-500:theme(colors.red.500)]
      [--tw-colors-gray-400:theme(colors.gray.400)] dark:[--tw-colors-gray-400:theme(colors.neutral.400)]
      [--tw-color-active:theme(colors.active.DEFAULT)] dark:[--tw-color-active:theme(colors.active.dark)]
    `
  }))`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: ${(props) => (props.color ? props.color : '#ccc')};
  background: ${(props) => (
    props.img
      ? `${props.color ? props.color : '#ccc'} url(${props.img}) no-repeat center center / cover` : ''
  )};
  color: ${(props) => props.img ? 'transparent' : 'white'};
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.15);
  }

  &:before {
    width: .25rem;
    height: ${(props) => props.select === 'selected' ? '100%' : '.625rem'};
    top: ${(props) => props.select === 'selected' ? '0' : 'calc(50% - .625rem / 2)'};
    right: calc(100% + .5rem);
    border-radius: 0 .25rem .25rem 0;
    background-color: var(${(props) => props.select === 'selected' ? '--tw-color-active' : '--tw-colors-gray-400'});

    content: '';
    position: absolute;
  }

  &:after {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
    background-color: var(--tw-color-red-500);
    width: 1rem;
    height: 1rem;

    position: absolute;
    top: -.125rem;
    right: -.125rem;

    border-radius: 12px;
    color: white;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    content: '${(props) => (props.notif > 99 ? '99+' : props.notif)}';
    display: ${(props) => (props.notif && props.notif > 0 ? 'flex' : 'none')};
  }
`;
