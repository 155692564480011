import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const usePreferencesStore = create(
  persist(
    (set) => ({
      playNotificationSound: false,
      setPlayNotificationSound: (value) => set({ playNotificationSound: value }),
      useLegacyContactSorting: false,
      setUseLegacyContactSorting: (value) => set({ useLegacyContactSorting: value }),
      paginateContacts: false,
      setPaginateContacts: (value) => set({ paginateContacts: value }),
      fetchUnreadContactsOnly: false,
      setFetchUnreadContactsOnly: (value) => set({ fetchUnreadContactsOnly: value }),
      virtualizeContacts: false,
      setVirtualizeContacts: (value) => set({ virtualizeContacts: value }),
    }),
    { name: 'preference-storage' }
  )
);

export default usePreferencesStore;
