import { forwardRef } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { classnames } from '../../utils/usefulFunctions';


function _Flyout ({ className, ...props }, ref) {
  return <Popover ref={ref} className={classnames(className, 'tw-relative')} {...props} />
}

export const Flyout = forwardRef(_Flyout)

export function FlyoutPanel ({ anchor = 'bottom', className, ...props }) {
  return <PopoverPanel
    {...props}
    transition
    anchor={anchor}
    className={classnames(className, `
      tw-absolute //tw-left-1/2 tw-z-[1100]
      tw-mt-5 
      tw-flex tw-w-screen !tw-max-w-max
      //-tw-translate-x-1/2
      tw-px-4 tw-py-4
      tw-transition
      data-[closed]:tw-translate-y-1
      data-[closed]:tw-opacity-0
      data-[enter]:tw-duration-200
      data-[leave]:tw-duration-150
      data-[enter]:tw-ease-out
      data-[leave]:tw-ease-in
    `)}
  />
}

export function FlyoutButton ({ as, ...props }) {
  return <PopoverButton {...props} as={as} />
}
