import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IntlTelInput from 'react-intl-tel-input';

import { Modal, ModalFooter, ModalHeader, ModalTitle, ModalSubTitle, ModalCloseButton, ModalBody } from './Modal';
import useModalStore from '../../../stores/useModalStore';
import useDadosSakStore from '../../../stores/useDadosSakStore';
import useSocketStore from '../../../stores/useSocketStore';
import useRenderStore from '../../../stores/useRenderStore';

export default function AddContactModal () {
  // const { data } = useModalStore((state) => state.modal);
  const closeModal = useModalStore((state) => state.closeModal);

  const { t } = useTranslation();

  const [bandeira, setBandeira] = useState('55');
  const [phoneNum, setPhoneNum] = useState('');
  const [initialMsg, setInitialMsg] = useState('');

  const ctt = useDadosSakStore((state) => state.contatos);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const setContatoAtivo = useDadosSakStore((state) => state.setContatoAtivoStore);
  const setContatos = useDadosSakStore((state) => state.setContatosStore);
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  const shouldRender = useRenderStore((state) => state.shouldRender);


  function handleNovaConversa(event) {
    event.preventDefault();

    if (!phoneNum && !initialMsg) return null;

    const conversa = phoneNum.replace(/([- ()+]+)/g, '');
    if (!conversa || conversa.length <= 5) {
      window.notifChat('Digite um número válido', 'error');
      return null;
    }

    const numeroContato = window.sakChatIsMeta
      ? `${bandeira}${conversa}` : `${bandeira}${conversa}@c.us`;

    const atualTimestamp = Date.now() / 1000;

    const findExistContact =
      ctt[lojaAtivo] &&
      Object.values(ctt[lojaAtivo]).find((contato) => {
        const countLengthContatoID = conversa?.length || 0;
        let numberContact = conversa;
        if (countLengthContatoID === 11) numberContact = numberContact.slice(3);
        else if (countLengthContatoID === 9)
          numberContact = numberContact.slice(1);

        const findBuscaSem9 = contato['chatid'].includes(numberContact);
        const findBuscaCom9 = contato['chatid'].includes(conversa);

        return findBuscaSem9 || findBuscaCom9;
      });

    if (findExistContact) {
      setContatoAtivo(() => findExistContact);
    } else {
      setContatos((prev) => {
        if (!prev[lojaAtivo]) return { ...prev };
        const novoContato = {
          arquivado: '0',
          atualizacao: atualTimestamp,
          carregandoConversa: false,
          chatid: numeroContato,
          conversa_id: '',
          fechado: '0',
          inclusao: atualTimestamp,
          naolidas: '0',
          nome: conversa,
          photo: '',
          photoUpdate: atualTimestamp,
          ultimamsg: atualTimestamp,
          ultimamsgtexto: initialMsg,
          userchat: userLogado.userchat,
          userserv: lojaAtivo,
          temp: true,
        };
        prev[lojaAtivo][numeroContato] = novoContato;
        setContatoAtivo(() => novoContato);
        return { ...prev };
      }, true);
    }

    sendSocketDados({
      function: 'EnviarNovaMensagem',
      data: [
        {
          conversa: numeroContato,
          texto: initialMsg,
          userserv: lojaAtivo,
          userchat: userLogado.userchat,
        },
      ],
    });

    closeModal();
    shouldRender();
  }

  return (
    <Modal open={true} onClose={closeModal} size="xl" className="tw-overflow-visible">
      <ModalHeader className="tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <ModalTitle>
            { t('Adicionar contato') }
          </ModalTitle>
          {/* <ModalSubTitle>
            { t('') }
          </ModalSubTitle> */}
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <div className="tw-flex tw-gap-5 tw-flex-wrap">
          <div className="tw-flex-1">
            <label className="tw-text-sm">
              { t('Número') } (WhatsApp)
            </label>
            <IntlTelInput
              containerClassName="
                intl-tel-input tw-overflow-visible
                [&_.country-list]:!tw-border-seg-border [&_.country-list]:dark:!tw-border-seg-border-dark
                [&_.country-list]:!tw-bg-bar [&_.country-list]:dark:!tw-bg-bar-dark

                [&_.country-list::-webkit-scrollbar]:tw-w-1
                [&_.country-list::-webkit-scrollbar-thumb]:tw-bg-scrollbar [&_.country-list::-webkit-scrollbar-thumb]:dark:tw-bg-scrollbar-dark
              "
              inputClassName="
                form-control

                tw-text-sm tw-py-2 tw-h-auto
                tw-text-gray-500 dark:tw-text-neutral-300
                focus:tw-text-gray-900 dark:focus:tw-text-white

                tw-bg-transparent tw-rounded-md tw-border-seg-border dark:tw-border-seg-border-dark
                focus:tw-ring-2 focus:tw-ring-seg-border focus:tw-ring-offset-seg-border
                focus:dark:tw-ring-seg-border-dark focus:dark:tw-ring-offset-seg-border-dark
                focus:tw-bg-transparent
              "
              placeholder={''}
              format={true}
              defaultCountry={'br'}
              onSelectFlag={(_, seletedCountryData) => setBandeira(seletedCountryData.dialCode)}
              value={phoneNum}
              onPhoneNumberChange={(_, value) => setPhoneNum(value)}
              preferredCountries={['br', 'mx', 'co', 'cl', 'ar', 'es', 'us']}
            />
          </div>
          <div className="tw-flex-1">
            <label className="tw-text-sm">
              { t('Mensagem') }
            </label>
            <input
              type="text"
              name="new_contact_message"
              className="
                form-control

                tw-text-sm tw-py-2 tw-h-auto
                tw-text-gray-500 dark:tw-text-neutral-300
                focus:tw-text-gray-900 dark:focus:tw-text-white

                tw-bg-transparent tw-rounded-md tw-border-seg-border dark:tw-border-seg-border-dark
                focus:tw-ring-2 focus:tw-ring-seg-border focus:tw-ring-offset-seg-border
                focus:dark:tw-ring-seg-border-dark focus:dark:tw-ring-offset-seg-border-dark
                focus:tw-bg-transparent
              "
              value={initialMsg}
              onChange={(e) => setInitialMsg(e.target.value)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="tw-gap-3">
        <button
          type="button"
          onClick={handleNovaConversa}
          className="
            tw-inline-flex tw-justify-center
            tw-rounded-md tw-bg-indigo-600
            tw-px-3 tw-py-2
            tw-text-sm tw-font-semibold tw-text-white
            tw-shadow-sm
            hover:tw-bg-indigo-500 focus-visible:tw-outline
            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
            focus-visible:tw-outline-indigo-600
            focus:tw-outline-2 focus:tw-outline-offset-2
            focus:tw-outline-indigo-600
            focus:tw-ring-0 focus:tw-outline-none
          "
        >
          { t('Adicionar') }
        </button>
        <button
          type="button"
          onClick={closeModal}
          className="
            tw-inline-flex tw-justify-center
            tw-rounded-md tw-bg-west-bar dark:tw-bg-west-bar-dark
            tw-px-3 tw-py-2
            tw-text-sm tw-font-semibold
            tw-text-gray-500 dark:tw-text-white
            tw-shadow-sm
            hover:tw-brightness-95 dark:hover:tw-brightness-110 focus-visible:tw-outline
            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
            focus-visible:tw-outline-west-bar dark:focus-visible:tw-outline-west-bar-dark

            focus:tw-outline-2 focus:tw-outline-offset-2
            focus:tw-outline-west-bar dark:focus:tw-outline-west-bar-dark
            focus:tw-ring-0 focus:tw-outline-none
          "
        >
          { t('Cancelar') }
        </button>
      </ModalFooter>
    </Modal>
  )
}
