import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@headlessui/react';
import useSWR from 'swr';

import { classnames, sakBaseUrl } from '../../../../utils/usefulFunctions';
import { Textarea } from '../../../../components/FormInput';
import { HiOutlineTrash } from 'react-icons/hi';
import useModalStore from '../../../../stores/useModalStore';

function useQuickReplies () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
  });

  const queryUrl = sakBaseUrl('/Js/GetQuickRepliesExtensaoV2?') + queryParams.toString();

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60_000,
  });

  useEffect(() => {
    if (!Array.isArray(data?.data?.replies)) return;

    window.SAKshortcut = Object.fromEntries(data.data.replies.map(replies => ([ replies.shortcut, replies.texto ])));
  }, [data]);

  return {
    quickReplies: data?.data?.replies || [],
    isLoading,
    error,
    mutate
  };
}

async function deleteQuickReply (uuid) {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
    uuid
  });

  const queryUrl = sakBaseUrl('/Js/DeleteQuickReplyExtensaoV2?') + queryParams.toString();

  // const res = await fetch(queryUrl, { method: 'DELETE' });
  const res = await fetch(queryUrl, { method: 'POST' });
  const data = await res.json();

  if (data.status !== 'success')
    throw new Error('Failed to delete quick reply');

  return data;
}

async function postQuickReplies (replies) {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave
  });

  const queryUrl = sakBaseUrl('/Js/PostQuickReplyExtensaoV2?') + queryParams.toString();

  const res = await fetch(queryUrl, { method: 'POST', body: JSON.stringify(replies) });
  const data = await res.json();

  if (data.status === 'partial')
    void 0; //window.notifChat('-', 'warning');
  else if (data.status !== 'success')
    throw new Error('Failed to delete quick reply');

  return data;
}

function QuickReplyItem ({
  t,
  reply,
  handleShortcutChange,
  handleTextChange,
  handleDelete
}) {
  const [showBody, setShowBody] = useState(false);
  const bodyAreaRef = useRef(null);

  useEffect(() => {
    if (showBody && bodyAreaRef.current)
      bodyAreaRef.current.style.height = `min(20dvh, 256px, ${bodyAreaRef.current.scrollHeight + 2}px)`;
  }, [showBody]);

  return (
    <div
      key={'quick_reply_' + reply.uuid_shortcut}
      className="tw-flex tw-flex-col tw-gap-2"
    >
      <div
        className="tw-relative tw-rounded-md"
      >
        <div
          className="
            tw-pointer-events-none
            tw-absolute tw-inset-y-0 tw-left-0
            tw-flex tw-items-center tw-pl-3
            tw-text-gray-400
          "
        >/</div>

        <div
          className={classnames(`
            tw-cursor-pointer
            tw-absolute tw-inset-y-0 tw-right-0
            tw-flex tw-items-center tw-pr-3
          `, !reply.uuid_shortcut && 'tw-hidden')}
          onClick={() => handleDelete(reply.uuid_shortcut)}
        >
          <HiOutlineTrash className="tw-size-4 tw-text-gray-400" />
        </div>

        <input
          className={classnames(`
            tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pr-10 tw-pl-6
            tw-text-gray-500 dark:tw-text-neutral-300
            tw-ring-1 tw-ring-inset tw-ring-seg-border dark:tw-ring-seg-border-dark
            placeholder:tw-text-gray-400 dark:placeholder:tw-text-neutral-400
            focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-300 dark:focus:tw-ring-neutral-600
            focus:tw-outline-none focus:tw-border-0
            sm:tw-text-sm sm:tw-leading-6
            sm:placeholder:tw-text-sm sm:placeholder:tw-leading-6
            tw-bg-transparent
          `)}
          type="text"
          placeholder={t("Atalho")}
          value={reply.shortcut ?? ''}
          onChange={e => handleShortcutChange(reply.uuid_shortcut, e.target.value)}
          onClick={() => setShowBody(!showBody)}
        />
      </div>

      {
        showBody && (
          <div>
            <Textarea
              ref={bodyAreaRef}
              placeholder={t("Texto completo")}
              value={reply.texto ?? ''}
              onChange={e => handleTextChange(reply.uuid_shortcut, e.target.value)}
            />
          </div>
        )
      }
    </div>
  );
}

export default function QuickRepliesTab () {
  const { t } = useTranslation();

  const { quickReplies, isLoading, error, mutate } = useQuickReplies();
  const [stagingReplies, setStagingReplies] = useState({});
  const [newReply, setNewReply] = useState({});

  const shortcutValidator = (element, shortcut, uuid) => {
    if (!element) return;

    if (!shortcut)
      return element.setCustomValidity(t('Atalho não pode ser vazio'));

    if (shortcut.length > 20)
      return element.setCustomValidity(t('Atalho muito longo'));

    if (quickReplies.some(reply => reply.uuid_shortcut !== uuid && reply.shortcut === shortcut))
      return element.setCustomValidity(t('Atalho já existe'));

    element.setCustomValidity('');
  }

  const handleShortcutChange = (uuid, shortcut) => {
    const stagingQuickReply = stagingReplies[uuid]
      || { ...quickReplies.find(reply => reply.uuid_shortcut === uuid) }
      || { uuid_shortcut: uuid };
    
    stagingQuickReply.shortcut = shortcut.trim().replace(/\s/g, '').toLowerCase();

    setStagingReplies({
      ...stagingReplies,
      [uuid]: stagingQuickReply
    });

    // TEMP
    shortcutValidator(document.getElementById('shortcut_' + uuid), shortcut, uuid);
  };

  const handleTextChange = (uuid, text) => {
    const stagingQuickReply = stagingReplies[uuid]
      || { ...quickReplies.find(reply => reply.uuid_shortcut === uuid) }
      || { uuid_shortcut: uuid };

    stagingQuickReply.texto = text;

    setStagingReplies({
      ...stagingReplies,
      [uuid]: stagingQuickReply
    });
  };

  const handleDiscardChanges = () => {
    setStagingReplies({});
  };

  const handleSaveChanges = async () => {
    const stagingRepliesArray = Object.values(stagingReplies);

    const quickRepliesCopy = [...quickReplies];
    for (const reply of stagingRepliesArray) {
      const replyIndex = quickRepliesCopy.findIndex(r => r.uuid_shortcut === reply.uuid_shortcut);
      if (replyIndex === -1) continue;

      quickRepliesCopy[replyIndex] = reply;
    }

    const mutatedData = { data: { replies: quickRepliesCopy } };
    const mutationOptions = {
      optimisticData: mutatedData,
      populateCache: true
    };

    const dataFunction = async () => {
      await postQuickReplies(stagingRepliesArray);
      return mutatedData;
    };

    await mutate(dataFunction, mutationOptions)
      .then(_ => {
        setStagingReplies({});
      })
      .catch(_err => {
        window.notifChat(t('Erro ao salvar respostas rápidas'), 'danger');
      });
  };

  const handleCreateNewReply = async () => {
    const newReplyCopy = { ...newReply };
    newReplyCopy.shortcut = newReplyCopy.shortcut.trim().replace(/\s/g, '').toLowerCase();

    const quickRepliesCopy = [...quickReplies];
    quickRepliesCopy.push(newReplyCopy);

    const mutatedData = { data: { replies: quickRepliesCopy } };
    const mutationOptions = {
      optimisticData: mutatedData,
      populateCache: true
    };

    const dataFunction = async () => {
      await postQuickReplies([newReplyCopy]);
      return mutatedData;
    };

    await mutate(dataFunction, mutationOptions)
      .then(_ => {
        setNewReply({});
      })
      .catch(_err => {
        window.notifChat(t('Erro ao adicionar resposta rápida'), 'danger');
      });
  };

  const handleDelete = (uuid) => {
    // if (!window.confirm(t('Tem certeza que deseja deletar esta resposta rápida?'))) return;

    const onConfirm = async () => {
      const stagingRepliesCopy = { ...stagingReplies };
      delete stagingRepliesCopy[uuid];
  
      setStagingReplies(stagingRepliesCopy);
  
      const quickRepliesCopy = quickReplies.filter(r => r.uuid_shortcut !== uuid);
  
      const mutatedData = { data: { replies: quickRepliesCopy } };
      const mutationOptions = {
        optimisticData: mutatedData,
        populateCache: true
      };
  
      const dataFunction = async () => {
        await deleteQuickReply(uuid);
        return mutatedData;
      };
  
      await mutate(dataFunction, mutationOptions)
        .catch(_err => {
          window.notifChat(t('Erro ao deletar resposta rápida'), 'danger');
        });
    }

    useModalStore.getState().setModal('confirmation', {
      title: t('Deletar resposta rápida'),
      description: t('Tem certeza que deseja deletar esta resposta rápida?'),
      onConfirm
    });
  };

  if (isLoading) return (
    <div className='d-flex h-100 align-items-center justify-content-center'>
      <div className="spinner-border" role="status">
        <span className="sr-only">{t('Carregando...')}</span>
      </div>
    </div>
  );

  return (
    <div
      className="
        tw-pt-5
        tw-min-h-0
        tw-flex tw-flex-col tw-flex-1
      "
    >
      <div
        className="
          tw-flex tw-flex-col tw-gap-2
          tw-mx-5 tw-mb-5
        "
      >
        <span className="tw-text-base tw-font-semibold tw-text-gray-900 dark:tw-text-white">{ t('Mensagens rápidas') }</span>
        <span className="tw-text-sm tw-font-normal tw-text-gray-600 dark:tw-text-gray-400">
          { t('Substitua seus textos por atalhos de palavras') }
          <a
            className="
              before:tw-content-['_']
              after:tw-content-['↗']
              tw-text-active dark:tw-text-active-dark
            "
            href="https://suporte.sak.com.br/faq/como-adicionar-as-respostas-rapidas-ao-chat"
            target="_blank"
            rel="noreferrer"
          >
            { t('Ajuda') }
          </a>
        </span>
      </div>

      <div
        className="
          tw-flex tw-flex-col tw-gap-2
          tw-min-h-0 tw-overflow-y-auto tw-flex-shrink-0
          tw-px-5 tw-pb-5
          tw-border-b tw-border-seg-border dark:tw-border-seg-border-dark
        "
      >
        <QuickReplyItem
          t={t}
          reply={newReply}
          handleShortcutChange={(_, shortcut) => setNewReply({ ...newReply, shortcut })}
          handleTextChange={(_, texto) => setNewReply({ ...newReply, texto })}
        />

        {
          newReply.shortcut && newReply.texto && (
            <div
              className="mt-2 d-flex justify-content-between"
              style={{ gap: '.5rem' }}
            >
              <Button
                className="
                  tw-flex tw-flex-1 tw-justify-center tw-items-center
                  tw-rounded-md tw-py-2 tw-px-3 tw-font-medium
                  tw-text-white tw-bg-indigo-600
                  hover:tw-bg-indigo-500
                  focus:tw-outline-none
                "
                onClick={handleCreateNewReply}
              >
                { t('Adicionar atalho') }
              </Button>

              <Button
                className="
                  tw-flex tw-justify-center tw-items-center
                  tw-rounded-md tw-py-2 tw-px-3 tw-font-medium
                  tw-text-red-500 tw-border-red-500 tw-border
                  hover:tw-bg-red-500 hover:tw-text-white
                  focus:tw-outline-none

                  disabled:tw-cursor-not-allowed disabled:tw-opacity-50
                "
                onClick={() => setNewReply({})}
              >
                { t('Cancelar') }
              </Button>
            </div>
          )
        }
      </div>

      <div
        className="
          tw-mx-2
          tw-pl-3 tw-pr-2
          tw-min-h-0 tw-max-h-full tw-overflow-y-scroll

          tw-flex tw-flex-col tw-gap-5

          [&::-webkit-scrollbar]:tw-w-1
          [&::-webkit-scrollbar-thumb]:!tw-bg-scrollbar
          [&::-webkit-scrollbar-thumb]:dark:!tw-bg-scrollbar-dark
        "
      >
        {
          error && (
            <div className="tw-mt-5">
              <div className="alert alert-danger mb-0 mt-2" role="alert">
                <i className="fas fa-exclamation-triangle"></i> {t('Ocorreu um erro ao carregar as respostas rápidas.')}
              </div>
            </div>
          )
        }

        {
          quickReplies.length > 0 && (
            <div className="tw-flex tw-flex-col tw-gap-2 tw-mt-5">
              {
                quickReplies.map(reply => (
                  <QuickReplyItem
                    t={t}
                    key={reply.uuid_shortcut}
                    reply={ stagingReplies[reply.uuid_shortcut] || reply}
                    handleShortcutChange={handleShortcutChange}
                    handleTextChange={handleTextChange}
                    handleDelete={handleDelete}
                  />
                ))
              }
            </div>
          )
        }
      </div>

      {
        quickReplies.length > 0 &&(
          <div
            className="tw-px-5 tw-my-5 tw-flex tw-justify-between tw-gap-2"
          >
            <Button
              className="
                tw-flex tw-flex-1 tw-justify-center tw-items-center
                tw-rounded-md tw-py-2 tw-px-3 tw-font-medium
                tw-text-white tw-bg-indigo-600
                hover:tw-bg-indigo-500
                focus:tw-outline-none

                disabled:tw-cursor-not-allowed disabled:tw-opacity-50
              "
              disabled={Object.keys(stagingReplies).length === 0}
              onClick={handleSaveChanges}
            >
              { t('Salvar alterações') }
            </Button>

            <Button
              className="
                tw-flex tw-justify-center tw-items-center
                tw-rounded-md tw-py-2 tw-px-3 tw-font-medium
                tw-text-red-500 tw-border-red-500 tw-border
                hover:tw-bg-red-500 hover:tw-text-white
                focus:tw-outline-none

                disabled:tw-cursor-not-allowed disabled:tw-opacity-50
              "
              disabled={Object.keys(stagingReplies).length === 0}
              onClick={handleDiscardChanges}
            >
              { t('Cancelar') }
            </Button>
          </div>
        )
      }
    </div>
  );
}
