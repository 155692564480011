import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@headlessui/react';

import { HiOutlineUser, HiOutlineAtSymbol, HiOutlineDeviceMobile } from 'react-icons/hi';
import { TbBrandWhatsapp } from 'react-icons/tb';

import { classnames } from '../../../../utils/usefulFunctions';
import { CheckBox, NativeSelect, StaticSearchBar, Textarea } from '../../../../components/FormInput';

function scrapeChatData () {
  const contatoNome = document.querySelector('#contatoNome');
  const contatoWhatsapp = document.querySelector('#contatoWhatsapp');

  return {
    nome: contatoNome?.innerText,
    whatsapp: contatoWhatsapp?.value
  }
}

export function TabSelector ({
  index,
  className = '',
  children,
  ...props
}) {
  if (className) className += ' ';
  else className = '';

  switch (index % 6) {
    case 1:
      className += 'tw-bg-yellow-100 dark:tw-bg-yellow-700/10 tw-text-yellow-800 dark:tw-text-yellow-400 tw-ring-yellow-500 dark:tw-ring-yellow-400';
      break;
    case 2:
      className += 'tw-bg-blue-100 dark:tw-bg-blue-700/10 tw-text-blue-700 dark:tw-text-blue-400 tw-ring-blue-500 dark:tw-ring-blue-400';
      break;
    case 3:
      className += 'tw-bg-red-100 dark:tw-bg-red-700/10 tw-text-red-700 dark:tw-text-red-400 tw-ring-red-500 dark:tw-ring-red-400';
      break;
    case 4:
      className += 'tw-bg-purple-100 dark:tw-bg-purple-700/10 tw-text-purple-700 dark:tw-text-purple-400 tw-ring-purple-500 dark:tw-ring-purple-400';
      break;
    case 5:
      className += 'tw-bg-pink-100 dark:tw-bg-pink-700/10 tw-text-pink-700 dark:tw-text-pink-400 tw-ring-pink-500 dark:tw-ring-pink-400';
      break;
    default:
      className += 'tw-bg-green-100 dark:tw-bg-green-700/10 tw-text-green-700 dark:tw-text-green-400 tw-ring-green-500 dark:tw-ring-green-400';
      break;
    // case 6:
    //   className += 'tw-bg-indigo-100 dark:tw-bg-indigo-700/10 tw-text-indigo-700 dark:tw-text-indigo-400 tw-ring-indigo-500 dark:tw-ring-indigo-400';
    //   break;
  }

  return (
    <span
      {...props}
      className={classnames(className,`
        tw-rounded-full tw-cursor-pointer
        tw-inline-flex tw-items-center
        tw-px-2 tw-py-1
        tw-text-xs tw-font-medium
        //tw-bg-red-100 //dark:tw-bg-red-700/10
        //tw-text-red-700 //dark:tw-text-red-400

        tw-ring-inset [&.active]:tw-ring-1
        //tw-ring-red-500 //dark:tw-ring-red-400

        hover:tw-brightness-[.975] dark:hover:tw-brightness-110
      `)}
    >
      { children }
    </span>
  );
}

export default function ProfileTab ({
  client_info,
  saveClientInfo,
  setClientData,
  leadLists,
  warningMessage
}) {
  const { t } = useTranslation();
  const [clientIndex, setClientIndex] = useState(0);
  const [isMutating, setIsMutating] = useState(false);

  function updateClientData (index, key, value) {
    const newClientData = [...client_info];

    if (!newClientData[index])
      newClientData[index] = {};

    if (typeof key === 'object')
      newClientData[index] = { ...newClientData[index], ...key };
    else
      newClientData[index][key] = value;

    setClientData(newClientData);
  }

  function handleSaveClientInfo (index) {
    if (isMutating) return;

    setIsMutating(true);
    saveClientInfo(index)
      .finally(() => setIsMutating(false));
  }

  useEffect(() => {
    if (clientIndex > 0 && clientIndex > client_info.length - 1) {
      setClientIndex(0);
      return;
    }

    if (!client_info[clientIndex]) {
      const { nome, whatsapp } = scrapeChatData();

      updateClientData(clientIndex, {
        nome,
        whatsapp,
        phone: whatsapp,
        listaId: leadLists[0]?.id
      });
    }
  // eslint-disable-next-line
  }, [client_info, clientIndex]);

  const client = client_info?.[clientIndex] || {};

  return (
    <div className="tw-flex tw-flex-col">
      {
        client_info.length > 1 && (
          <div className="tw-flex tw-gap-2 tw-mb-5 tw-flex-wrap">
            {
              client_info.map((client, index) => (
                <TabSelector
                  key={'client_tab_' + client.id_cliente}
                  index={index}
                  onClick={() => setClientIndex(index)}
                  className={index === clientIndex && 'active'}
                >
                  { client.nome || '?' }
                </TabSelector>
              ))
            }
          </div>
        )
      }

      <div className="tw-flex tw-flex-col tw-gap-5">
        <StaticSearchBar
          placeholder={t('Nome do cliente')}
          value={client.nome ?? ''}
          onChange={e => updateClientData(clientIndex, 'nome', e.target.value)}
          icon={HiOutlineUser}
        />

        <StaticSearchBar
          placeholder={t('E-mail')}
          value={client.email ?? ''}
          onChange={e => updateClientData(clientIndex, 'email', e.target.value)}
          icon={HiOutlineAtSymbol}
        />

        <StaticSearchBar
          placeholder={t('Telefone')}
          value={client.phone ?? ''}
          onChange={e => updateClientData(clientIndex, 'phone', e.target.value)}
          icon={HiOutlineDeviceMobile}
        />

        <StaticSearchBar
          placeholder="WhatsApp"
          value={client.whatsapp ?? ''}
          onChange={e => updateClientData(clientIndex, 'whatsapp', e.target.value)}
          icon={TbBrandWhatsapp}
        />
      </div>

      <div className="tw-mt-5">
        <label
          className="
            tw-mb-2
            tw-text-sm/6 tw-font-medium
            tw-text-gray-900 dark:tw-text-white
          "
          htmlFor="ext_notes_textarea"
        >
          { t('Anotações') }
        </label>

        <Textarea
          id="ext_notes_textarea"
          value={client.anotacoes || ''}
          onChange={e => updateClientData(clientIndex, 'anotacoes', e.target.value)}
          rows={4}
        />
      </div>

      <div className="tw-mt-5 tw-flex tw-gap-2 tw-items-center">
        <CheckBox
          checked={client.isLead > 0}
          onChange={e => updateClientData(clientIndex, 'isLead', e.target.checked)}
          inputProps={{ id: 'ext_is_lead_checkbox' }}
        />
        <label
          className="
            tw-mb-0 tw-select-none
            tw-text-sm/5 tw-font-normal
            tw-text-gray-900 dark:tw-text-white
          "
          htmlFor="ext_is_lead_checkbox"
        >
          { t('Salvar como lead') }
        </label>
      </div>

      {
        client.isLead > 0 && (
          <div className="tw-mt-5">
            <NativeSelect
              value={client.listaId}
              onChange={e => updateClientData(clientIndex, 'listaId', e.target.value)}
              options={leadLists.map(leadList => ({
                value: leadList.id,
                label: leadList.nome
              }))}
            />
          </div>
        )
      }
      

      <div className="tw-mt-5 tw-flex tw-justify-center">
        <Button
          className="
            tw-flex tw-flex-1 tw-justify-center tw-items-center
            tw-rounded-md tw-py-2 tw-px-3 tw-font-medium
            tw-text-white tw-bg-indigo-600
            hover:tw-bg-indigo-500
            focus:tw-outline-none

            disabled:tw-cursor-not-allowed
            disabled:tw-opacity-50
          "
          disabled={isMutating}
          onClick={() => handleSaveClientInfo(clientIndex)}
        >
          {
            client.id_cliente
              ? t('Salvar alterações')
              : t('Adicionar perfil')
          }
        </Button>
      </div>

      {
        warningMessage && (
          <div className="tw-mt-5">
            { warningMessage }
          </div>
        )
      }
    </div>
  );
}
