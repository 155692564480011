import { memo, useMemo } from 'react';

import { getPalette } from '../../../utils/createPalette';
import { classnames } from '../../../utils/usefulFunctions';

function CuteTag ({ color, className, children, ...props }) {
  const palette = useMemo(() => {
    const palette = getPalette(color, 700);
    return Object.fromEntries(palette.map(pair => ([pair.stop, pair.hex])));
  }, [color]);

  let bgColor, textColor, ringColor,
      bgColorDark, textColorDark, ringColorDark;

  // Palheta alternativa
  // bgColorDark = palette[900] + '55';
  // textColorDark = palette[300];
  // ringColorDark = palette[800] + '55';

  bgColor = palette[50];
  textColor = palette[700];
  ringColor = palette[600] + '80';
  bgColorDark = palette[700] + '1A';
  textColorDark = palette[400];
  ringColorDark = palette[400] + '1A';

  return (
    <span
      {...props}
      className={classnames(className, `
        tw-inline-flex tw-items-center tw-rounded-md
        tw-bg-[var(--bg-color)] tw-px-2 tw-py-1 tw-text-xs
        tw-font-medium tw-text-[var(--text-color)] tw-ring-1
        tw-ring-inset tw-ring-[var(--ring-color)]
        dark:tw-bg-[var(--bg-color-dark)] dark:tw-text-[var(--text-color-dark)] dark:tw-ring-[var(--ring-color-dark)]
      `)}
      style={{
        '--bg-color': bgColor,
        '--text-color': textColor,
        '--ring-color': ringColor,
        '--bg-color-dark': bgColorDark,
        '--text-color-dark': textColorDark,
        '--ring-color-dark': ringColorDark,
      }}
    >
      { children }
    </span>
  )
}

export default memo(CuteTag);
