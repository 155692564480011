import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineDocumentText, HiOutlineEye, HiOutlineEyeOff, HiOutlineLockClosed } from 'react-icons/hi';
import { BsFilter } from 'react-icons/bs';
import useSWR from 'swr';

import { Dropdown, DropdownButton, DropdownMenu, DropdownSection, DropdownItem } from '../../../../components/Dropdown';
import { classnames, sakBaseUrl } from '../../../../utils/usefulFunctions';
import Tooltip from '../../../Tooltip';

import useSideExtensionStore from '../../../../stores/useSideExtensionStore';
import { StaticSearchBar } from '../../../../components/FormInput';

const statusDescription = {
  Inativo: 'Oculto para todos',
  Publicado: 'Público',
  Rascunho: 'Rascunho',
  Restrito: 'Oculto para clientes'
}

const statusIcons = {
  // Interno?
  Inativo: HiOutlineLockClosed,
  // Público
  Publicado: HiOutlineEye,
  // Rascunho
  Rascunho: HiOutlineDocumentText,
  // Não listado
  Restrito: HiOutlineEyeOff,
}

function useFAQData () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
  });

  const queryUrl = sakBaseUrl('/Js/GetFAQExtensaoV2?' + queryParams.toString());

  const { data, error, isLoading } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 120_000
  });

  return {
    categories: data?.data?.categories || [],
    config: data?.data?.config || {},
    isLoading,
    error
  };
}

export default function FaqTab () {
  const { t } = useTranslation();

  const { categories, config, isLoading } = useFAQData();
  const [query, setQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  const setDrawer = useSideExtensionStore(state => state.setDrawer);

  function searchFaq (categories, query) {
    try {
      const searchable = categories
        .map(c => c.artigos).flat();
  
      if (!query) return searchable;

      // const matches = window.fuzzysort.go(query, searchable, { key: 'busca', limit: 0, threshold: .2, all: true })
      //   .map(m => m.obj);
  
      const fuse = new window.Fuse(searchable, {
        keys: ['busca'],
        ignoreLocation: true,
        ignoreFieldNorm: true,
        threshold: .15,
      });

      const matches = fuse.search(query).map(m => m.item);

      return matches || [];
    } catch { return []; }
  }

  const filteredCategories = useMemo(() => {
    if (!categories) return [];

    const categoriesToBeFiltered = (selectedCategory && !query)
      ? [selectedCategory] : categories;

    const filteredArticlesIds = searchFaq(categoriesToBeFiltered, query).map(a => a.id_artigo);
    let filteredCategories = categoriesToBeFiltered.map(category => ({
      ...category,
      artigos: category.artigos.filter(article => filteredArticlesIds.includes(article.id_artigo))
    }));

    filteredCategories = filteredCategories.filter(category => category.artigos.length > 0);

    return filteredCategories;
  }, [categories, selectedCategory, query]);


  useEffect(() => {
    if (query && selectedCategory)
      setSelectedCategory(null);
  }, [selectedCategory, query]);

  if (isLoading) return (
    <div className='tw-flex tw-h-full tw-items-center tw-justify-center'>
      <div className="spinner-border" role="status">
        <span className="sr-only">{t('Carregando...')}</span>
      </div>
    </div>
  );

  return (
    <div
      className="
        tw-pt-5
        tw-min-h-0
        tw-flex tw-flex-col tw-flex-1
      "
    >
      <div
        className="
          tw-flex tw-flex-col tw-gap-2
          tw-mx-5 tw-mb-5
        "
      >
        <span className="tw-text-base tw-font-semibold tw-text-gray-900 dark:tw-text-white">{ t('FAQ') }</span>
        <span className="tw-text-sm tw-font-normal tw-text-gray-600 dark:tw-text-gray-400">
          { t('Consulte sua base de conhecimento') }
          <a
            className="
              before:tw-content-['_']
              after:tw-content-['↗']
              tw-text-active dark:tw-text-active-dark
            "
            href="https://suporte.sak.com.br/faq/como-editar-as-perguntas-frequentes-do-faq"
            target="_blank"
            rel="noreferrer"
          >
            { t('Ajuda') }
          </a>
        </span>
      </div>

      <div
        className="
          tw-mx-5
          tw-flex tw-gap-3
        "
      >
        <StaticSearchBar
          value={query}
          onChange={e => setQuery(e.target.value)}
          placeholder={t('Buscar')}
        />

        <Dropdown as="div">
          <Tooltip text="Filtro">
            <DropdownButton
              className="
                tw-p-2 tw-rounded-md
                tw-ring-1 tw-ring-seg-border dark:tw-ring-seg-border-dark
                //tw-bg-west-bar //dark:tw-bg-west-bar-dark
                tw-text-gray-500 dark:tw-text-gray-400
                hover:tw-brightness-95 dark:hover:tw-bg-west-bar-dark
                focus:tw-outline-none
              "
            >
              <BsFilter className="tw-w-5 tw-h-5"/>
            </DropdownButton>
          </Tooltip>

          <DropdownMenu anchor="bottom end">
            <DropdownSection>
              <DropdownItem
                onClick={() => setSelectedCategory(null)}
              >
                { t('Todos') }
              </DropdownItem>

              {
                categories.filter(category => category.artigos.length > 0).map(category => (
                  <DropdownItem
                    key={'faq_filter_' + category.id_categoria}
                    onClick={() => setSelectedCategory(category)}
                    className={classnames({ active: selectedCategory?.id_categoria === category.id_categoria })}
                  >
                    { category.nome }
                  </DropdownItem>
                ))
              }
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>
      </div>

      <div
        className="
          tw-mx-2 tw-mt-5
          tw-pl-3 tw-pr-2
          tw-min-h-0 tw-h-full tw-overflow-y-scroll

          tw-flex tw-flex-col tw-gap-5

          [&::-webkit-scrollbar]:tw-w-1
        [&::-webkit-scrollbar-thumb]:!tw-bg-scrollbar
        [&::-webkit-scrollbar-thumb]:dark:!tw-bg-scrollbar-dark
        "
      >
        {
          filteredCategories.map(category => (
            <div
              key={'faq_category_' + category.id_categoria}
            >
              <span
                className="
                  tw-text-base/5 tw-font-medium
                  tw-text-gray-900 dark:tw-text-white
                "
              >
                { category.nome }
              </span>
              <div className="tw-mt-3" data-category={ category.id_categoria }>
                {
                  category.artigos.map(article => {
                    const StatusIcon = statusIcons[article.status];
                    return (
                      <div
                        key={'faq_article_' + article.id_artigo}
                        onClick={() => setDrawer('faq', { article, config })}

                        className="
                          tw-cursor-pointer
                          tw-rounded-md
                          tw-px-3 tw-py-2.5
                          tw-text-sm tw-font-normal
                          tw-text-gray-600 dark:tw-text-neutral-200

                          tw-flex tw-items-center tw-gap-2
                          tw-text-left

                          hover:tw-text-gray-900 dark:hover:tw-text-white
                          hover:tw-bg-west-bar dark:hover:tw-bg-west-bar-dark
                        "
                      >
                        <span>
                          <Tooltip text={t(article.status + ': ' + statusDescription[article.status])} position="left" >
                            <StatusIcon
                              className="
                                tw-size-4
                                tw-text-gray-500 dark:tw-text-gray-400
                              "
                            />
                          </Tooltip>
                        </span>

                        <span className="tw-text-ellipsis">
                          { article.nome }
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}
