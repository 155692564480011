import './lang/front/i18n';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ErrorChat } from './components/errors';
import ModalManager from './components/ModalManager';
import SocketProvider from './hooks/socket-novo';
import ThemeProvider from './hooks/useTheme'
import ValidateAbas from './utils/ValidateAbas';

import Home from './Home';

window.notifChat = (text, type) => {
  const acceptedTypes = ['loading', 'success', 'info', 'warning', 'error'];
  if (acceptedTypes.includes(type) && toast[type]) toast[type](text);
  else toast(text, { draggable: true, progress: undefined });
};

function App() {
  const tokenURL = window.location.href.split('/')[3].split('#')[0];
  const init = localStorage.inicializacao && JSON.parse(localStorage.inicializacao);
  const initDebug = localStorage['debug'];

  if (tokenURL) {
    if (init && tokenURL !== init.token) localStorage.clear();
    localStorage.setItem('inicializacao', JSON.stringify({ token: tokenURL }));
    window.location.replace('/');
  }

  return (
    <ErrorChat codeError={1}>
      <ValidateAbas />
      <ThemeProvider />
      <SocketProvider token={init && init['token']} debug={initDebug} />
      <ToastContainer />
      <ModalManager />

      <Home />
    </ErrorChat>
  );
}

export default App;
