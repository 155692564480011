import React, {
  // createContext,
  // useContext,
  useEffect,
  // useState,
  useRef,
} from 'react';

import useWebSocket from 'react-use-websocket';

import { logError } from '../components/errors/logError';

import {
  // mergeDeep,
  // asyncForEach,
  checkIsPhoneNumber9AndNot9Exist,
} from '../utils/formatDados';
import useDadosSakStore from '../stores/useDadosSakStore';
import useSocketStore from '../stores/useSocketStore';
import useRenderStore from '../stores/useRenderStore';
import usePreferencesStore from '../stores/usePreferencesStore';

let ultimoContatoTimeout;

export default function SocketComponent({ token, debug = '' }) {
  // const [loadStatus, setLoadStatus] = useState(false);
  // const [socketAtualStatus, setSocketAtualStatus] = useState('Desconectado');

  // const [timeSocket, setTimeSocket] = useState(250);
  // const [delayMessage, setDelayMessage] = useState();

  const lastAjaxPhpCall = useRef(0);

  const loadStatus = useSocketStore((state) => state.loadStatus);
  const setLoadStatus = useSocketStore((state) => state.setLoadStatusStore);
  const socketAtualStatus = useSocketStore((state) => state.socketAtualStatus);
  const setSocketAtualStatus = useSocketStore(
    (state) => state.setSocketAtualStatusStore
  );
  const timeSocket = useSocketStore((state) => state.timeSocket);
  const setTimeSocket = useSocketStore((state) => state.setTimeSocketStore);

  const setContatos = useDadosSakStore((state) => state.setContatosStore);
  const contatos = useDadosSakStore((state) => state.contatos);
  const setOnError = useDadosSakStore((state) => state.setOnErrorStore);
  const onError = useDadosSakStore((state) => state.onError);
  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const setContatoAtivo = useDadosSakStore(
    (state) => state.setContatoAtivoStore
  );
  const setUltimoContato = useDadosSakStore(
    (state) => state.setUltimoContatoStore
  );
  const mensagens = useDadosSakStore((state) => state.mensagens);
  const setMensagens = useDadosSakStore((state) => state.setMensagensStore);
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const setUserLogado = useDadosSakStore((state) => state.setUserLogadoStore);
  const setUserChat = useDadosSakStore((state) => state.setUserChatStore);
  const lojas = useDadosSakStore((state) => state.lojas);
  const setLojas = useDadosSakStore((state) => state.setLojasStore);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const setLojaAtivo = useDadosSakStore((state) => state.setLojaAtivoStore);
  const setStatusApp = useDadosSakStore((state) => state.setStatusAppStore);
  const marcadores = useDadosSakStore((state) => state.marcadores);
  const setMarcadores = useDadosSakStore((state) => state.setMarcadoresStore);
  const setLastMsg = useDadosSakStore((state) => state.setLastMsgStore);
  const statusSocket = useDadosSakStore((state) => state.statusSocket);
  const setStatusSocket = useDadosSakStore(
    (state) => state.setStatusSocketStore
  );

  const triggerRender = useRenderStore((state) => state.triggerRender);
  const forceRender = useRenderStore((state) => state.forceRender);

  const dadosInfos = {
    inicializacao: (props, ajax) => socketInicializacao(props, ajax),
    mensagem: (props, ajax) => socketMensagens(props, ajax),
    mensagens: (props, ajax) => socketMensagens(props, ajax),
    contatos: (props) => socketContatos(props),
    marcador: (props) => socketMarcador(props),
    removerUserserv: (props) => socketRemoverUserServ(props),
    lojas: (props) => socketLojas(props),
    userchat: (props) => socketUserChat(props),
    erro: (props) => socketErrors(props),
    abrircontato: (props) => socketAbrirContato(props),
  };

  useEffect(() => {
    if (onError && loadStatus) {
      const findError = Object.values(onError).findIndex(
        (erro) => erro.id === 'conexao_load'
      );
      if (findError !== -1) {
        const errors = [...onError];
        errors.splice(findError, 1);
        setOnError(() => errors);
      }
      setLoadStatus(() => false);
    }
  }, [loadStatus, onError, setOnError]);

  function error(
    id,
    titulo,
    mensagem,
    tipo = 'danger',
    ativo = '0',
    userserv = 0
  ) {
    setOnError(
      (result) => [
        ...result,
        {
          id: String(id),
          titulo: String(titulo),
          mensagem: String(mensagem),
          tipo: String(tipo),
          ativo: String(ativo),
          userserv: String(userserv),
        },
      ],
      true
    );
  }

  const socketMethods = {
    chat: `wss://chat.sak.com.br/ws/?token=${token}`,
    ws: `ws://websocket.sak.com.br:8080?token=${token}`, // websocket testes
    local: `ws://localhost:8080/?token=${token}`, // ws teste
  };

  const { sendJsonMessage, readyState } = useWebSocket(
    debug
      ? socketMethods[debug]
        ? socketMethods[debug]
        : `${debug}?token=${token}`
      : `wss://websocket.sak.com.br/ws/?token=${token}`,
    {
      share: true,
      onOpen: () => {
        console.log('Whatsapp conectado!');
        window.socketGlobal = {
          conexao: 'whatsappConectado',
        };
        const findErros = Object.values(onError).filter(
          (erro) => erro.id === 'conexao_socket'
        );
        if (findErros) {
          const errors = [...onError];
          findErros.forEach((item) => errors.splice(item));
          setOnError(() => errors);
        }
        setTimeSocket(() => 250);
      },
      onClose: () => {
        window.socketGlobal = {
          conexao: 'conexaoOffline',
        };
        error(
          'conexao_socket',
          'Desconectado!',
          'Verifique sua conexão com a internet',
          'danger',
          1
        );
        setTimeSocket((atual) => atual + 250);
        console.log('atualtimeSocket', timeSocket);
      },
      onError: () => {
        window.socketGlobal = {
          conexao: 'ErroConexao',
        };
        error(
          'conexao_socket',
          'Desconectado!',
          'Verifique sua conexão com a internet',
          'danger',
          1
        );
      },

      onMessage: (e) => {
        const dados = JSON.parse(e.data);
        if (dados['function'])
          dadosInfos[dados['function']] &&
            dadosInfos[dados['function']](dados['data']);
        else {
          const inicializacao = JSON.parse(localStorage.inicializacao);
          inicializacao['token'] = 'invalido';
          localStorage.inicializacao = JSON.stringify(inicializacao);
          setStatusApp(() => 0);
        }
      },

      shouldReconnect: () => true,

      reconnectInterval: Math.min(10000, timeSocket),
    },
    statusSocket
  );

  const sendSocketDados = (dados) => {
    if (socketAtualStatus === 'Conectado') {
      sendJsonMessage(dados);
      return true;
    } else {
      window.notifChat('Falha na conexão com a internet.', 'error');
      return false;
    }
  };
  const executeSendSocket = useSocketStore((state) => state.setSendSocketDados);
  useEffect(() => {
    executeSendSocket(sendSocketDados);
  }, [socketAtualStatus]);

  useEffect(() => {
    if (readyState === 3) setSocketAtualStatus(() => 'Desconectado');
    if (readyState === 1) setSocketAtualStatus(() => 'Conectado');
  }, [readyState]);

  async function socketInicializacao(dados, ajax = false) {
    logError('Inicializacao', dados);

    const oldUserChat = userLogado && userLogado['userchat'];
    if (!dados['userchat'] && oldUserChat) dados['userchat'] = oldUserChat;
    if (dados['userchat']) {
      dados['userchat'] = String(dados['userchat']);
      setUserLogado(() => dados);
    }

    const ajaxDebug = debug === 'ajax' ? 'ajax_debug' : 'ajax';
    const urlAjax = `https://chat.sak.com.br/src/${ajaxDebug}.php`;

    const listaUrlsDefault = [{ url: '', userserv: null }];

    if (dados['loadDados'] && token) {
      if (typeof dados['loadDados'] === 'boolean') {
        dados['loadDados'] = listaUrlsDefault;
      }

      error(
        'conexao_load',
        'Carregando',
        'Sincronizando contatos e mensagens',
        'info',
        1
      );

      const dadosSettings = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          token,
        }),
      };

      await Promise.allSettled(dados['loadDados'].map(async dado => {
        let otimizationOnlyLoadNaolidas = usePreferencesStore.getState().fetchUnreadContactsOnly;
        otimizationOnlyLoadNaolidas =
          otimizationOnlyLoadNaolidas === true ? 1 : 0;

        const urlParam = () => {
          if (dado['url'] === '') {
            return (dado[
              'url'
            ] = `?ultima=${lastAjaxPhpCall.current}&naolidas=${otimizationOnlyLoadNaolidas}`);
          }
          return dado['url'];
        };
        try {
          const response = await fetch(
            `${urlAjax}${urlParam()}`,
            dadosSettings
          );
          const atualTimeStamp = new Date(Date.now()).getTime() / 1000;
          lastAjaxPhpCall.current = atualTimeStamp;
          const result = await response.json();
          if (!result || (result && !Array.isArray(result))) return null;

          result.forEach(
            (info) =>
              info['function'] &&
              dadosInfos[info['function']] &&
              dadosInfos[info['function']](info['data'], true)
          );
        } catch (err) {
          console.log({ err });
        }
      }));

      // await asyncForEach(dados['loadDados'], async (dado) => {
      //   let otimizationOnlyLoadNaolidas = JSON.parse(
      //     localStorage.getItem('otimizationLoadOnlyContatosComNaolidasCheckbox')
      //   );
      //   otimizationOnlyLoadNaolidas =
      //     otimizationOnlyLoadNaolidas === true ? 1 : 0;

      //   const urlParam = () => {
      //     if (dado['url'] === '') {
      //       return (dado[
      //         'url'
      //       ] = `?ultima=${lastAjaxPhpCall.current}&naolidas=${otimizationOnlyLoadNaolidas}`);
      //     }
      //     return dado['url'];
      //   };
      //   try {
      //     const response = await fetch(
      //       `${urlAjax}${urlParam()}`,
      //       dadosSettings
      //     );
      //     const atualTimeStamp = new Date(Date.now()).getTime() / 1000;
      //     lastAjaxPhpCall.current = atualTimeStamp;
      //     const result = await response.json();
      //     if (!result || (result && !Array.isArray(result))) return null;

      //     result.forEach(
      //       (info) =>
      //         info['function'] &&
      //         dadosInfos[info['function']] &&
      //         dadosInfos[info['function']](info['data'], true)
      //     );
      //   } catch (err) {
      //     console.log({ err });
      //   }

      //   // if(!ajax && Array.isArray(dados['loadDados'])) {
      //   //   const countLoadDados = dados['loadDados']?.length || 0;
      //   //   const percentValue = ~~(100 / countLoadDados);
      //   //   console.log(countLoadDados, dados['loadDados'], dados['loadDados'].length)
      //   //   updateLoading(percentValue > 99 ? 99 : globalLoading['percent'] + percentValue, 'Dados')
      //   // }
      // });

      setLoadStatus(() => true);
      // setLoadAjax(true);
    }

    if (dados['sessao'] === 'existente') {
      setStatusApp(() => 2);
      setStatusSocket(() => false);
    } else if (dados['sessao'] === 'reload') window.location.reload();
    else if (dados['token'] === 'invalido') setStatusApp(() => 0);
    else setStatusApp(() => 1);
  }

  function socketRemoverUserServ(dados) {
    logError('RemoverLoja', dados);
    // Buscar loja nos dados
    const findLojaStorage =
      lojas &&
      Object.values(lojas).find((loja) => loja.userserv === dados.userserv);

    if (findLojaStorage !== -1) {
      // Limpar Lojas
      let lojasAtivas = lojas;
      delete lojasAtivas[findLojaStorage.userserv];
      setLojas(() => lojasAtivas);

      // Buscar Loja
      const buscaLojas = Object.values(lojas).map((loja) => loja.userserv);

      // Atualizar Loja para uma existente
      if (buscaLojas && buscaLojas[0]) {
        setLojaAtivo(() => buscaLojas[0]);
      } else {
        const inicializacao = JSON.parse(localStorage.inicializacao);
        inicializacao['token'] = 'invalido';
        localStorage.inicializacao = JSON.stringify(inicializacao);
        setStatusApp(() => 0);
        return null;
      }

      // Limpar Mensagens
      let mensagensTotais = mensagens;
      delete mensagensTotais[findLojaStorage.userserv];
      setMensagens(() => mensagensTotais);

      // Limpar Contatos
      let contatosTotais = contatos;
      delete contatosTotais[findLojaStorage.userserv];
      setContatos(() => contatosTotais);
    }
  }

  function socketLojas(dados) {
    logError('Lojas', dados);
    if (!lojaAtivo) {
      const firstLoja = dados[Object.keys(dados)[0]].userserv;
      setLojaAtivo(() => firstLoja);
      setLojas(() => dados);
    } else {
      setLojas(() => ({ ...lojas, ...dados }));
    }
  }

  function socketUserChat(dados) {
    logError('UserChats', dados);
    const listaUserChats = {};

    Object.values(dados).forEach((dado) => {
      if (!dado['userchat'] || !dado['userserv']) return null;

      dado['userchat'] = String(dado['userchat']);
      dado['userserv'] = String(dado['userserv']);
      dado['excluido'] = String(dado['excluido']);

      listaUserChats[dado['userchat']] = dado;
    });

    setUserChat(() => listaUserChats);
  }

  function validaSocketMensagensInContatos(dados) {
    dados = Object.entries(dados);
    const contatosSemMensagem = {};
    const defaultMessageIfNoMessage =
      'Não foi possível recuperar esta mensagem. Clique em carregar mensagens antigas!';

    dados.forEach(([loja, contatosLoja]) => {
      const arrContatos = Object.keys(contatosLoja);
      if (!contatosSemMensagem[loja]) contatosSemMensagem[loja] = {};
      if (!contatos[loja]) return;

      Object.values(contatos[loja]).forEach((contato) => {
        if (!contato.chatid) return;
        const { chatid } = contato || {};
        if (arrContatos.includes(chatid)) return;
        if (!contato['ultimamsg']) return;
        if (String(contato['ultimamsg']) === '0') return;

        const fakeIdMessage = crypto.randomUUID();

        if (!contatosSemMensagem[loja][chatid]) {
          contatosSemMensagem[loja][chatid] = {};
        }

        if (!contatosSemMensagem[loja][chatid][fakeIdMessage]) {
          contatosSemMensagem[loja][chatid][fakeIdMessage] = {};
        }

        contato['naolidas'] = String(contato['naolidas']);

        const existText = contato['ultimamsgtexto'] ? true : false;

        if (!contato['ultimamsgtexto'])
          contato['ultimamsgtexto'] = defaultMessageIfNoMessage;

        const dados = {
          Nfrom: '',
          conversa: chatid,
          data_whats: contato['ultimamsg'],
          id: fakeIdMessage,
          idFull: fakeIdMessage,
          inclusao: contato['ultimamsg'],
          lida: '1',
          me: contato['naolidas'] !== '0' ? '0' : '1',
          mime: '',
          privado: contato['naolidas'] !== '0' && existText ? '0' : '2',
          respostaDe: '',
          status: contato['naolidas'] !== '0' ? '2' : '3',
          texto: contato['ultimamsgtexto'],
          thumb: '',
          tipo: 'chat',
          userchat: contato['userchat'],
          userserv: contato['userserv'],
        };

        contatosSemMensagem[loja][chatid][fakeIdMessage] = Object.assign(
          contatosSemMensagem[loja][chatid][fakeIdMessage],
          dados
        );
      });
    });

    return contatosSemMensagem;
  }

  function socketMensagens(dados, isAjax = false) {
    logError('Mensagens', dados);
    let mensagensAtuais = mensagens;

    if (!Array.isArray(Object.values(dados))) return null;

    for (let index in dados) {
      if (!index || (index && !dados[index])) continue;

      if (!dados[index]?.['anexo']?.length) delete dados?.[index]?.['anexo'];
      dados[index].me = String(dados[index].me);
      if (dados[index].me === '1') dados[index].lida = '1';
      const convAtual = dados[index].conversa;
      const msgAtual = dados[index].id;
      const loja = dados[index].userserv;

      if (!msgAtual || !loja) continue;

      if (!mensagensAtuais[loja]) mensagensAtuais[loja] = {};
      if (!mensagensAtuais[loja][convAtual])
        mensagensAtuais[loja][convAtual] = {};

      const mensagensIntanciadas = mensagensAtuais[loja][convAtual][msgAtual];
      const dadosMensagem = dados[index];

      if (mensagensIntanciadas) {
        mensagensAtuais[loja][convAtual][msgAtual] = {
          ...mensagensIntanciadas,
          ...dadosMensagem,
        };
      } else {
        mensagensAtuais[loja][convAtual][msgAtual] = dadosMensagem;
      }
    }

    /*
      Os dados iniciais já estão vindo com pelo menos 1 mensagem,
        então não é necessário desperdiçar tempo verificando se há algum contato sem mensagem
    */

    const allMessages = { ...mensagensAtuais };

    // let allMessages = mensagens;

    // if (isAjax) {
    //   const contatosSemMensagem =
    //     validaSocketMensagensInContatos(mensagensAtuais);

    //   allMessages = mergeDeep(mensagensAtuais, contatosSemMensagem);
    // } else {
    //   allMessages = { ...mensagensAtuais };
    // }

    setMensagens(() => allMessages);

    const lastMessages = {};
    for (const index in dados) {
      if (!dados[index]) continue;
      const id = dados[index]?.conversa;
      if (!id) continue;
      lastMessages[id] = dados[index];
    }
    setLastMsg(() => lastMessages);
  }

  function socketAbrirContato(dado) {
    logError('Abriu o contato', dado);

    // Se o comando tiver um destinatário (userchat é um número != 0) e não for o usuário logado, descartar
    if (Number(dado['userchat']) && String(dado['userchat']) !== String(userLogado['userchat'])) return;
    if (!dado['chatid'] || !dado['userserv']) return;
    dado['userserv'] = String(dado['userserv']);
    dado['chatid'] = String(dado['chatid']);

    const findContato =
      contatos?.[lojaAtivo] &&
      Object.values(contatos[lojaAtivo]).find(
        (contato) =>
          String(contato['chatid']) === dado['chatid'] &&
          String(contato['userserv']) === dado['userserv']
      );

    if (findContato) setContatoAtivo(() => ({ ...dado, ...findContato }));
    else setContatoAtivo(() => dado);
  }

  function socketContatos(dados) {
    logError('Contatos', dados);

    if (!Array.isArray(Object.values(dados))) return;

    clearTimeout(ultimoContatoTimeout);
    ultimoContatoTimeout = setTimeout(() => {
      setUltimoContato(() => new Date());
    }, 1000);

    let contatosAtuais = contatos;
    let findExistTempContact = null;

    // Valida se já tem uma loja ativa e é um contato temporário
    if (lojaAtivo && contatos[lojaAtivo]) {
      findExistTempContact = Object.values(contatos[lojaAtivo]).find(
        (contact) => contact['temp']
      );
    }

    for (let index in dados) {
      if (!dados[index] || (dados[index] && !dados[index].chatid)) continue;
      const id_contato = dados[index].chatid;
      dados[index].carregandoConversa = false;
      const dadosContato = dados[index];

      const loja = Number(dados[index].userserv);
      if (!contatosAtuais[loja]) contatosAtuais[loja] = {};

      const contatosInstanciado = contatosAtuais[loja][id_contato];

      // Muda o número criado temporáriamente
      if (findExistTempContact) {
        const findContactTempNumber = findExistTempContact['chatid'] || 0;
        if (
          findContactTempNumber &&
          checkIsPhoneNumber9AndNot9Exist(id_contato, findContactTempNumber) >=
            0.75
        ) {
          delete contatosAtuais[loja][findContactTempNumber];
          setContatoAtivo(() => dadosContato);
        }
      }

      if (contatosInstanciado)
        contatosAtuais[loja][id_contato] = {
          ...contatosInstanciado,
          ...dadosContato,
        };
      else contatosAtuais[loja][id_contato] = dadosContato;
    }

    if (!contatos) setContatos(() => ({ ...contatosAtuais }));
    else setContatos(() => ({ ...contatos }));

    if (contatoAtivo && contatosAtuais) {
      const existContato =
        contatosAtuais && contatosAtuais[lojaAtivo]
          ? Object.values(contatosAtuais[lojaAtivo])
          : [];
      if (
        !existContato ||
        (existContato && Object.values(existContato).length === 0)
      )
        return null;

      const filterContatoAberto =
        existContato &&
        contatoAtivo &&
        contatoAtivo['chatid'] &&
        existContato.find(
          (contatoAberto) =>
            String(contatoAberto['chatid']) === String(contatoAtivo['chatid'])
        );

      if (filterContatoAberto) setContatoAtivo(() => filterContatoAberto);
    }
    const handleRerender = () => {
      if (triggerRender) setTimeout(() => forceRender(), 1000);
    };
    handleRerender();
  }

  function socketMarcador(dados) {
    logError('Marcadores', dados);
    let marcadoresAtuais = marcadores;

    for (let index in dados) {
      const idMarcador = dados[index].id;
      if (!idMarcador) continue;
      const lojaMarcador = dados[index].userserv;
      if (!marcadoresAtuais[lojaMarcador]) {
        marcadoresAtuais[lojaMarcador] = {};
      }
      marcadoresAtuais[lojaMarcador][idMarcador] = dados[index];
    }

    if (!marcadores) setMarcadores(() => marcadoresAtuais);
    else setMarcadores(() => ({ ...marcadores, marcadoresAtuais }));
  }

  function socketErrors(dados) {
    logError('Errors', dados);
    let { id, titulo, mensagem, tipo, ativo, userserv, userchat } = dados || {};

    ativo = String(ativo);
    userserv = String(userserv);
    userchat = String(userchat);

    if (id === 'alerta_whatsapp' && lojaAtivo && userserv === String(lojaAtivo)) {
      // Notificar apenas se
      //  o erro for destinado a todos os usuários (userchat não é um número != 0)
      //  o erro se destina ao usuário logado
      if (!Number(userchat) || userchat === String(userLogado['userchat']))
        window.notifChat(titulo, tipo);

      return null;
    }

    const filterErroLoja =
      onError &&
      onError.findIndex(
        (erro) => erro['id'] === id && erro['userserv'] === userserv
      );

    if (filterErroLoja !== -1) {
      const newArr = [...onError];
      newArr[filterErroLoja] = {
        id,
        titulo,
        mensagem,
        tipo,
        ativo,
        userserv,
      };
      setOnError(() => newArr);
    } else {
      error(id, titulo, mensagem, tipo, ativo, userserv);
    }
  }

  return <></>;
}
