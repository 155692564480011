import React from 'react';

import Tip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { useTranslation } from 'react-i18next';

function Tooltip({ overlay, text, position = 'top', children }) {
  const { t } = useTranslation();
  
  if (text)
    return (
      <Tip placement={position} overlay={<span>{t(text)}</span>}>
        {children}
      </Tip>
    )

  if (overlay)
    return (
      <Tip placement={position} overlay={overlay}>
        {children}
      </Tip>
    )

  return children;
}

export default Tooltip;
