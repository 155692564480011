import { useTranslation } from 'react-i18next';
import { HiX } from 'react-icons/hi';
import useSideExtensionStore from '../../../../stores/useSideExtensionStore';

export default function BaseDrawer () {
  const { t } = useTranslation();

  // const { data } = useSideExtensionStore(state => state.drawer);
  const closeDrawer = useSideExtensionStore(state => state.closeDrawer);

  return (
    <div
      className="
        tw-absolute tw-z-30
        tw-top-0 tw-left-0 tw-right-0 tw-bottom-0
        tw-w-full tw-h-full

        tw-bg-bar dark:tw-bg-bar-dark
        tw-flex tw-flex-col tw-items-center
      "
    >
      <div
        className="
          tw-w-full
          tw-py-6 tw-px-5
          tw-border-b tw-border-seg-border dark:tw-border-seg-border-dark
          tw-flex tw-justify-between tw-items-center
        "
      >
        <span
          className="
            tw-text-lg/5 tw-font-semibold
            tw-text-gray-900 dark:tw-text-white
          "
        >
          { t('Exemplo') }
        </span>

        <span
          className="tw-cursor-pointer"
          onClick={closeDrawer}
        >
          <HiX className="tw-w-6 tw-h-6 tw-text-gray-900 dark:tw-text-white" />
        </span>
      </div>
      
      <div className="tw-mt-5">
        Oi
      </div>
    </div>
  );
}