import { useEffect, useRef, useState } from 'react';
import { classnames } from '../../../utils/usefulFunctions';
import Tooltip from '../../Tooltip';

export default function TagContainer ({ children, className, ...props }) {
  const [overflowingBy, setOverflowingBy] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const isOverflowing = container.scrollWidth > container.clientWidth;
    if (!isOverflowing) return;

    const containerRect = container.getBoundingClientRect();

    let lastSomewhatVisibleIndex = 0;
    for (let i = 1; i < container.children.length; i++) {
      const child = container.children[i];
      const childRect = child.getBoundingClientRect();

      if (childRect.left >= containerRect.right) {
        lastSomewhatVisibleIndex = i - 1;
        break;
      }
    }

    setOverflowingBy(container.children.length - lastSomewhatVisibleIndex - 1);
  }, [children, overflowingBy]);

  return (
    <div
      className='tw-flex tw-gap-1'
    >
      <div
        {...props}
        ref={containerRef}
        className={classnames(className, `
          tw-flex tw-flex-nowrap tw-gap-1
          tw-items-center tw-justify-start
          [&>*]:tw-flex-shrink-0 hide-scrollbar tw-overflow-x-auto
        `)}
      >
        {children}
      </div>

      {
        overflowingBy > 0 && (
          <Tooltip
            overlay={(
              <div
                className="tw-flex tw-flex-col tw-gap-1"
              >
                {
                  Array.from(containerRef.current.children)
                    /*.slice(-overflowingBy)*/.map(child => (
                      <span>
                        - { child.innerText }
                      </span>
                    ))
                }
              </div>
            )}
          >
            <span
              className="
                tw-inline-flex tw-items-center tw-rounded-md
                tw-px-2 tw-py-1 tw-text-xs
                tw-bg-west-bar dark:tw-bg-west-bar-dark
                tw-text-gray-900 dark:tw-text-white
                tw-font-medium
              "
            >
              +{overflowingBy}
            </span>
          </Tooltip>
        )
      }
    </div>
  )
}
