import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalFooter, ModalHeader, ModalTitle, ModalSubTitle, ModalCloseButton, ModalBody } from './Modal';
import useModalStore from '../../../stores/useModalStore';
import useDadosSakStore from '../../../stores/useDadosSakStore';
import useSocketStore from '../../../stores/useSocketStore';

export default function JoinGroupModal () {
  const closeModal = useModalStore((state) => state.closeModal);

  const { t } = useTranslation();

  const [invite, setInvite] = useState('');
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);

  function entrarGrupo() {
    sendSocketDados({
      function: 'entrarGrupo',
      data: [
        {
          userserv: lojaAtivo,
          invite
        },
      ],
    });

    closeModal();
  }

  return (
    <Modal open={true} onClose={closeModal} size="xl" className="tw-overflow-visible">
      <ModalHeader className="tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <ModalTitle>
            { t('Juntar-se a um grupo') }
          </ModalTitle>
          {/* <ModalSubTitle>
            { t('') }
          </ModalSubTitle> */}
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <div className="tw-flex tw-gap-5 tw-flex-wrap">
          <div className="tw-flex-1">
            <label className="tw-text-sm">
              { t('Link ou código de convite') }
            </label>
            <input
              type="text"
              className="
                form-control

                tw-text-sm tw-py-2 tw-h-auto
                tw-text-gray-500 dark:tw-text-neutral-300
                focus:tw-text-gray-900 dark:focus:tw-text-white

                tw-bg-transparent tw-rounded-md tw-border-seg-border dark:tw-border-seg-border-dark
                focus:tw-ring-2 focus:tw-ring-seg-border focus:tw-ring-offset-seg-border
                focus:dark:tw-ring-seg-border-dark focus:dark:tw-ring-offset-seg-border-dark
                focus:tw-bg-transparent
                placeholder:tw-opacity-50 dark:placeholder:tw-opacity-20
              "
              value={invite}
              onChange={(e) => setInvite(e.target.value)}
              placeholder="https://chat.whatsapp.com/f0afe482"
              minLength="8"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="tw-gap-3">
        <button
          type="button"
          onClick={entrarGrupo}
          className="
            tw-inline-flex tw-justify-center
            tw-rounded-md tw-bg-indigo-600
            tw-px-3 tw-py-2
            tw-text-sm tw-font-semibold tw-text-white
            tw-shadow-sm
            hover:tw-bg-indigo-500 focus-visible:tw-outline
            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
            focus-visible:tw-outline-indigo-600
            focus:tw-outline-2 focus:tw-outline-offset-2
            focus:tw-outline-indigo-600
            focus:tw-ring-0 focus:tw-outline-none
          "
        >
          { t('Juntar-se') }
        </button>
        <button
          type="button"
          onClick={closeModal}
          className="
            tw-inline-flex tw-justify-center
            tw-rounded-md tw-bg-west-bar dark:tw-bg-west-bar-dark
            tw-px-3 tw-py-2
            tw-text-sm tw-font-semibold
            tw-text-gray-500 dark:tw-text-white
            tw-shadow-sm
            hover:tw-brightness-95 dark:hover:tw-brightness-110 focus-visible:tw-outline
            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
            focus-visible:tw-outline-west-bar dark:focus-visible:tw-outline-west-bar-dark

            focus:tw-outline-2 focus:tw-outline-offset-2
            focus:tw-outline-west-bar dark:focus:tw-outline-west-bar-dark
            focus:tw-ring-0 focus:tw-outline-none
          "
        >
          { t('Cancelar') }
        </button>
      </ModalFooter>
    </Modal>
  )
}
