import { ReactComponent as WaClock } from '../../assets/wa_clock.svg';
import { ReactComponent as SingleCheck } from '../../assets/single_check.svg';
import { ReactComponent as DoubleCheck } from '../../assets/double_check.svg';
import { classnames } from '../../utils/usefulFunctions';

export default function MessageStatus ({ status, className, ...props }) {
  status = parseInt(status) || 0;

  const Icon = status <= 0
    ? WaClock
    : status === 1
      ? SingleCheck
      : DoubleCheck;

  return (
    <Icon
      className={
        classnames(className,
          status <= 0
            ? 'tw-size-[.875rem]'
            : 'tw-size-[1.0625rem]',
          status <= 2
            ? 'tw-text-msg-status dark:tw-text-msg-status-dark'
            : 'tw-text-msg-status-read'
        )
      }
      {...props}
    />
  );
}
