import { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineRefresh } from 'react-icons/hi';
import { BsPalette2 } from 'react-icons/bs';

import { Modal, ModalHeader, ModalTitle, ModalSubTitle, ModalCloseButton, ModalBody } from './Modal';
import { Dropdown, DropdownButton, DropdownMenu } from '../../Dropdown';

import useModalStore from '../../../stores/useModalStore';
import useDadosSakStore from '../../../stores/useDadosSakStore';
import useSocketStore from '../../../stores/useSocketStore';
import CuteTag from '../../../contents/Contato/components/CuteTag';
import Tooltip from '../../../contents/Tooltip';
import { randomHex, calculateContrastRatio, getPalette } from '../../../utils/createPalette';

const PREDEFINED_COLORS = [
  '#b60205', '#d93f0b', /*'#fbca04',*/ '#0e8a16',
  '#006b75', '#1d76db', '#0052cc', '#5319e7',
  // '#e99695', '#f9d0c4', '#fef2c0', '#c2e0c6',
  // '#bfdadc', '#c5def5', '#bfd4f2', '#d4c5f9'
];

function randomContrastfulColor(minContrast = 4.5) {
  let textHex, bgHex, contrast;  

  do {
    textHex = randomHex();
    bgHex = getPalette(textHex, 700).find(c => c.stop === 50).hex;
    contrast = calculateContrastRatio(textHex, bgHex);
  } while (contrast < minContrast);

  return textHex;
}

export default function ManageLabelsModal () {
  const closeModal = useModalStore((state) => state.closeModal);

  const { t } = useTranslation();

  const [labelColor, setLabelColor] = useState(randomContrastfulColor());
  const [labelName, setLabelName] = useState('');
  const [errMessage, setErrMessage] = useState('');

  const labelNameInputRef = useRef(null);
  const labelColorInputRef = useRef(null);

  const marcador = useDadosSakStore((state) => state.marcadores);
  const setMarcadores = useDadosSakStore((state) => state.setMarcadoresStore);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  const userLogado = useDadosSakStore((state) => state.userLogado);

  const marcadores = useMemo(() => {
    return marcador && marcador[lojaAtivo] && Object.values(marcador[lojaAtivo]);
  }, [marcador, lojaAtivo]);

  const listaTodosMarcadores = useMemo(() => {
    return (
      marcadores?.map((item) => (
        item && (
          <CuteTag
            className="tw-justify-between tw-gap-2"
            key={'gerenciar_marcador_' + item.id}
            color={item.cor || '49c18f'}
          >
            {item.titulo}
            <i
              onClick={() => handleRemoveTag(item.id)}
              className="tw-cursor-pointer fe-x"
            />
          </CuteTag>
        )
      ))
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marcadores]);

  useEffect(() => {
    const _labelName = labelName.trim().toLowerCase();
    if (marcadores?.some(marcador => marcador.titulo.toLowerCase() === _labelName)) {
      setErrMessage(t('Você já possui um marcador com esse nome'));
      return;
    }

    setErrMessage('');
  }, [labelName, marcadores, t]);

  function handleRemoveTag(id) {
    sendSocketDados({
      function: 'removerMarcador',
      data: [
        {
          id,
          userserv: lojaAtivo,
        },
      ],
    });

    const findIdMarcador = Object.values(marcador[lojaAtivo]).find(
      (item) => item.id === id
    );

    if (findIdMarcador) {
      const ArrayMarcadores = marcador;
      delete ArrayMarcadores[lojaAtivo][findIdMarcador.id];
      setMarcadores(() => ArrayMarcadores);
    }
  }

  function handleSubmitMarcador(event) {
    event.preventDefault();

    if (!labelName.length)
      return window.notifChat(t('Por favor, dê um nome ao marcador'), 'info');

    if (!labelColor.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/))
      return window.notifChat(t('Por favor, insira uma cor válida'), 'info');

    sendSocketDados({
      function: 'enviarMarcador',
      data: [
        {
          titulo: labelName,
          cor: labelColor,
          userserv: lojaAtivo,
          userchat: userLogado.userchat,
        },
      ],
    });

    setLabelName('');
  }

  return (
    <Modal open={true} onClose={closeModal} size="xl" className="tw-overflow-visible">
      <ModalHeader className="tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <ModalTitle>
            { t('Gerenciar marcadores') }
          </ModalTitle>
          <ModalSubTitle>
            { t('Crie marcadores e organize seus contatos') }
          </ModalSubTitle>
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <div className="tw-flex tw-flex-col tw-gap-8">
          <div>
            <span className="tw-font-semibold tw-text-gray-900 dark:tw-text-white">
              { t('Criar marcador') }
            </span>

            <div className="tw-mt-4">
              <CuteTag color={labelColor}>
                { labelName || 'Preview' }
              </CuteTag>
            </div>

            <div className="tw-mt-4 tw-flex tw-gap-3 tw-items-end tw-flex-wrap">
              <div className="tw-flex tw-flex-col tw-gap-1.5 tw-shrink tw-flex-1 tw-basis-[10rem]">
                <span className="tw-text-sm">
                  { t('Nome') }
                </span>
                <input
                  ref={labelNameInputRef}
                  type="text"
                  className="
                    form-control tw-text-sm tw-py-2 tw-h-auto
                    tw-bg-transparent tw-rounded-md tw-border-seg-border dark:tw-border-seg-border-dark
                    focus:tw-ring-2 focus:tw-ring-seg-border focus:tw-ring-offset-seg-border
                    focus:dark:tw-ring-seg-border-dark focus:dark:tw-ring-offset-seg-border-dark
                    focus:tw-bg-transparent

                    tw-text-gray-500 dark:tw-text-neutral-300
                    focus:tw-text-gray-900 dark:focus:tw-text-white
                  "
                  value={labelName}
                  onChange={(e) => setLabelName(e.target.value)}
                  minLength={1}
                  required
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-1.5 tw-flex-1 tw-basis-[10rem]">
                <span className="tw-text-sm">
                  { t('Cor') }
                </span>
                <div className="tw-flex tw-gap-2">
                  <Tooltip text="Cor aleatória" position="top">
                    <CuteTag
                      color={labelColor}
                      className="
                        tw-cursor-pointer tw-select-none tw-py-2 tw-leading-5
                        hover:tw-brightness-95
                      "
                      onClick={() => setLabelColor(randomContrastfulColor())}
                    >
                      <HiOutlineRefresh className="tw-size-4 tw-m-0.5 //tw-scale-x-[-1] //tw-rotate-12" />
                    </CuteTag>
                  </Tooltip>

                  <input
                    ref={labelColorInputRef}
                    type="text"
                    className="
                      form-control tw-text-sm tw-py-2 tw-h-auto //!tw-max-w-[50%]
                      tw-bg-transparent tw-rounded-md tw-border-seg-border dark:tw-border-seg-border-dark
                      focus:tw-ring-2 focus:tw-ring-seg-border focus:tw-ring-offset-seg-border
                      focus:dark:tw-ring-seg-border-dark focus:dark:tw-ring-offset-seg-border-dark
                      focus:tw-bg-transparent

                      tw-text-gray-500 dark:tw-text-neutral-300
                      focus:tw-text-gray-900 dark:focus:tw-text-white

                      invalid:tw-border-red-500
                      invalid:tw-ring-red-500
                      invalid:focus:tw-ring-red-500/20
                      invalid:tw-ring-offset-red-500
                      invalid:focus:tw-ring-offset-red-500
                    "
                    value={labelColor}
                    onChange={(e) => setLabelColor(e.target.value)}
                    pattern="#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})"
                    maxLength="7"
                    required
                  />

                  <span className="tw-flex tw-items-center">
                    <Dropdown as="div">
                      <DropdownButton className="tw-cursor-pointer tw-select-none" as="span">
                        <BsPalette2
                          className="
                            tw-size-5
                            tw-text-gray-500 dark:tw-text-gray-400
                            hover:tw-brightness-95 dark:hover:tw-brightness-110
                            hover:tw-scale-105
                          "
                        />
                      </DropdownButton>
                      <DropdownMenu anchor="bottom end">
                        <div className="tw-flex tw-justify-center tw-items-center tw-flex-wrap tw-gap-y-2 tw-px-2 tw-py-3">
                          {
                            PREDEFINED_COLORS.map((color) => (
                              <span
                                key={color}
                                className="
                                  tw-cursor-pointer
                                  tw-flex tw-items-center tw-justify-center tw-basis-[12.5%]
                                  hover:tw-scale-110
                                "
                                onClick={() => setLabelColor(color)}
                              >
                                <span
                                  className="
                                    tw-size-4 tw-rounded-full
                                    tw-bg-[var(--color)]
                                    [&:after]:tw-content-['']
                                  "
                                  style={{ '--color': color }}
                                />
                              </span>
                            ))
                          }
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </span>
                </div>
              </div>        

              <div className="tw-flex tw-justify-end tw-pl-6 tw-flex-1">
                <button
                  type="button"
                  onClick={handleSubmitMarcador}
                  className="
                    tw-inline-flex tw-justify-center
                    tw-rounded-md tw-bg-indigo-600
                    tw-px-3 tw-py-2
                    tw-text-sm tw-font-semibold tw-text-white
                    tw-shadow-sm
                    hover:tw-bg-indigo-500 focus-visible:tw-outline
                    focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
                    focus-visible:tw-outline-indigo-600
                    focus:tw-outline-2 focus:tw-outline-offset-2
                    focus:tw-outline-indigo-600
                    focus:tw-ring-0 focus:tw-outline-none

                    disabled:tw-cursor-not-allowed disabled:tw-opacity-50
                  "
                  disabled={!labelNameInputRef.current?.validity.valid || !labelColorInputRef.current?.validity.valid}
                >
                  { t('Criar') }
                </button>
              </div>
            </div>

            <div className="tw-mt-2 -tw-mb-2 tw-text-red-500 tw-text-xs empty:tw-hidden">
              { errMessage }
            </div>
          </div>
          <div className="tw-text-left tw-w-full">
            <span className="tw-font-semibold tw-text-gray-900 dark:tw-text-white">
              { t('Seus marcadores') }
            </span>
            <div className="tw-mt-4 tw-flex tw-flex-wrap tw-gap-2">
              {
                listaTodosMarcadores?.length
                  ? listaTodosMarcadores
                  : (
                    <p className="small">
                      { t('Você ainda não criou nenhum marcador') }
                    </p>
                  )
              }
            </div>
          </div>      
        </div>
      </ModalBody>
    </Modal>
  )
}
