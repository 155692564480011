import useSideExtensionStore from "../../../stores/useSideExtensionStore";

import BaseDrawer from "./drawers/BaseDrawer";
import FAQDrawer from "./drawers/FAQDrawer";

export default function ModalManager () {
  const drawer = useSideExtensionStore((state) => state.drawer);
  if (!drawer) return null;

  switch (drawer.name) {
    case 'base':
      return <BaseDrawer />;
    case 'faq':
      return <FAQDrawer />;
    default:
      return null;
  }
}
