import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalHeader, ModalTitle, ModalSubTitle, ModalCloseButton, ModalBody } from './Modal';
import useModalStore from '../../../stores/useModalStore';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdOutlinePhonelinkErase } from 'react-icons/md';

export default function ScreenshotModal () {
  const { data } = useModalStore((state) => state.modal);
  const closeModal = useModalStore((state) => state.closeModal);

  const { t } = useTranslation();

  const [ ssData, setSsData ] = useState(null);
  const ssContainerRef = useRef(null);

  useEffect(() => {
    const id = data.loja?.user;
    if (!id || !ssContainerRef.current) return;

    ssContainerRef.current.innerHTML = '';
    setSsData({
      isLoading: true,
      icon: (<AiOutlineLoading3Quarters className="tw-size-12 tw-animate-spin" />),
      message: 'Carregando...',
    });

    const handleErr = () => {
      setSsData({
        isLoading: true,
        icon: (<MdOutlinePhonelinkErase className="tw-size-12" />),
        message: 'Whatsapp desconectado',
        link: 'https://app.sak.com.br/whatsapps',
      });
    };

    const abortController = new AbortController();

    const reqParams = new URLSearchParams({
      wa_id: id,
      tokenchat: window.sakChatToken,
      chave_empreender: window.sakChatChave,
    });

    const url = `${window.SakChatUrl}/Js/GetScreenshotChatV2?` + reqParams.toString();

    fetch(url, { signal: abortController.signal })
      .then(async res => {
        const data = await res.json().catch(_ => ({}));
        if (data.status !== 'success' || !res.ok)
          return handleErr();

        ssContainerRef.current.innerHTML = data.data;
        setSsData({ isLoading: false });
      })
      .catch(_err => handleErr());

    return () => abortController.abort();
  }, [data.loja, ssContainerRef]);

  return (
    <Modal open={true} onClose={closeModal} size="xl">
      <ModalHeader className="tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <ModalTitle>
            { t('Print do Whatsapp') }
          </ModalTitle>
          <ModalSubTitle>
            { data.loja?.nome || '' }
          </ModalSubTitle>
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        {
          ssData?.isLoading && (
            <>
              {
                ssData.icon && (
                  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-4">
                    { ssData.icon }
                  </div>
                )
              }
              {
                ssData.message && (
                  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-4">
                    <strong style={{ fontWeight: '500' }}>
                      { t(ssData.message) }
                    </strong>
                    {
                      ssData.link && (
                        <a
                          href={ssData.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          { t('Clique aqui') }
                        </a>
                      )
                    }
                  </div>
                )
              }
            </>
          )
        }
        <div className="[&>img]:tw-rounded-lg" ref={ssContainerRef}></div>
      </ModalBody>
    </Modal>
  )
}
