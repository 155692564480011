import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@headlessui/react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { TbMessageQuestion } from 'react-icons/tb';
import { FaAngleRight } from 'react-icons/fa';
import useSWR, { useSWRConfig, unstable_serialize } from 'swr';

import { Flyout, FlyoutButton, FlyoutPanel } from '../../../../components/Flyout';
import { sakBaseUrl, classnames } from '../../../../utils/usefulFunctions';
import useDadosSakStore from '../../../../stores/useDadosSakStore';
import Tooltip from '../../../Tooltip';

function fetchMessages () {
  const { lojaAtivo, contatoAtivo, mensagens } = useDadosSakStore.getState();
  let messages = mensagens?.[lojaAtivo]?.[contatoAtivo?.chatid];

  if (!messages) return [];

  messages = Object.values(messages).sort((a, b) => a.data_whats - b.data_whats);
  messages = messages.filter(msg => msg.privado <= 0);
  messages = messages.filter(msg => msg.tipo === 'chat' || msg.tipo === 'reaction');

  if (messages.length > 15) {
    // Limitar a 3 dias ou à data da 15ª mensagem

    let minimumTimestamp = Math.floor(Date.now() / 1000 - 86_400 * 3);
    minimumTimestamp = Math.min(minimumTimestamp, messages[messages.length - 15].data_whats - 43_200);

    messages = messages.filter(msg => msg.data_whats > minimumTimestamp);
  }

  // HARD LIMIT
  messages = messages.slice(-50);
  messages = messages.map(msg => ({ me: msg.me > 0, content: msg.texto }));

  return messages;
}

function useHasAIProvider () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
  });

  const queryUrl = sakBaseUrl('/Js/TemProvedorIA?') + queryParams.toString();

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 7200_000,
  });

  return {
    hasProvider: data?.data?.has_provider ?? false,
    experimentalFeatures: data?.data?.experimental_features ?? false,
    isLoading,
    error,
    mutate
  };
}

function useChatSubject (chatid, messages) {
  const key = chatid ? ['chat_subject', window.sakChatChave, chatid] : null;

  const { data, error, isValidating, isLoading, mutate } = useSWR(key, async _key => {
    const messagesCopy = typeof messages === 'function' ? messages() : messages;

    if (!messagesCopy?.length) return { status: 'error', message: 'No messages provided' };

    const queryParams = new URLSearchParams({
      tokenchat: window.sakChatToken,
      chave_empreender: window.sakChatChave,
      messages: JSON.stringify(messagesCopy)
    });

    const queryUrl = sakBaseUrl('/Js/AssuntoConversa?') + queryParams.toString();

    const res = await fetch(queryUrl);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    dedupingInterval: 1800_000,
  });

  let err = error;
  if (!err && data?.status === 'error') {
    err = new Error(data.message);
    err.code = data.code;
  }

  return {
    chatSubject: data?.data?.subject ?? false,
    isValidating,
    isLoading,
    error: err,
    mutate
  };
}

function useAssistantResponse (subject) {
  const key = subject ? ['assistant_response', window.sakChatChave, subject] : null;

  const { data, isValidating, error, mutate } = useSWR(key, async (_key) => {
    const queryParams = new URLSearchParams({
      tokenchat: window.sakChatToken,
      chave_empreender: window.sakChatChave,
      subject
    });
  
    const queryUrl = sakBaseUrl('/Js/ObterAjudaFAQ?' + queryParams.toString());

    const res = await fetch(queryUrl);
    const data = await res.json();

    if (data.status === 'error')
      data.code = res.status;

    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    dedupingInterval: 1800_000,
  });

  let err = error;
  if (!err && data?.status === 'error') {
    err = new Error(data.message);
    err.code = data.code;
  }

  return {
    assistantResponse: data?.data?.response || null,
    error: err,
    isValidating,
    mutate
  };
}

function FAQAssistant() {
  const { t } = useTranslation();

  const { cache } = useSWRConfig();

  const [ isOpen, setIsOpen ] = useState(false);
  const [ shouldSearchFAQ, setShouldSearchFAQ ] = useState(false);

  const contatoAtivo = useDadosSakStore(state => state.contatoAtivo);

  const { hasProvider, experimentalFeatures } = useHasAIProvider();
  const { chatSubject, error, isValidating, mutate } = useChatSubject(hasProvider && isOpen && contatoAtivo?.chatid, fetchMessages);
  
  const shouldFetch = useMemo(() => {
    // Is open
    if (!isOpen) return false;

    // Chat subject is not validating
    if (isValidating) return false;

    // Chat subject is set
    if (!chatSubject) return true;

    // Response is already cached
    const _isCached = cache.has?.(unstable_serialize(['assistant_response', window.sakChatChave, chatSubject]));
    if (_isCached) return true;

    // User wants to fetch FAQ
    if (shouldSearchFAQ) return true;

    return false;
  }, [isOpen, isValidating, chatSubject, cache, shouldSearchFAQ]);

  const {
    assistantResponse,
    error: responseError,
    isValidating: isValidatingResponse,
    mutate: mutateResponse
  } = useAssistantResponse(shouldFetch && chatSubject);
  
  const observerRef = useRef();
  const flyoutRef = useCallback(node => {
    if (observerRef.current)
      observerRef.current.disconnect();

    if (node) {
      observerRef.current = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          if (mutation.attributeName === 'data-open')
            setIsOpen(mutation.target.hasAttribute('data-open'));
        }
      });

      observerRef.current.observe(node, {
        attributes: true,
        attributeFilter: ['data-open']
      });
    }
  }, []);

  const refetchSubject = () => {
    if (isValidating) return;
    mutate();
  }

  const searchFAQ = () => {
    if (isValidatingResponse || assistantResponse)
      return;

    setShouldSearchFAQ(true);
  }

  const refetchResponse = () => {
    if (isValidatingResponse) return;
    mutateResponse();
  }

  useEffect(() => {
    if (!isOpen)
      setShouldSearchFAQ(false);
  }, [isOpen]);

  if (!experimentalFeatures) return null;

  return (
    <Flyout
      as="div"
      ref={flyoutRef}
    >
      <FlyoutButton
        as="span"
        className="tw-flex tw-items-center tw-cursor-pointer tw-rounded-md"
      >
        <Tooltip text="Assistente de resposta" position="top">
          <TbMessageQuestion
            className={classnames(`
              tw-w-5 tw-h-5
              [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark
            `, {  active: isOpen })}
          />
        </Tooltip>
      </FlyoutButton>
      <FlyoutPanel
        anchor="top"
        className="[--anchor-gap:1rem]"
      >
        <div
          className="
            tw-w-screen tw-max-w-md
            tw-flex-auto tw-overflow-hidden
            tw-rounded-lg tw-shadow-lg
            tw-text-sm/6

            tw-bg-chat-area dark:tw-bg-chat-area-dark
            tw-ring-1 tw-ring-opacity-5
            tw-ring-black dark:tw-ring-west-bar-dark

            focus:tw-outline-none
            data-[closed]:tw-scale-95 data-[closed]:tw-transform

            [&::-webkit-scrollbar]:tw-w-1
            [&::-webkit-scrollbar-thumb]:!tw-bg-scrollbar
            [&::-webkit-scrollbar-thumb]:dark:!tw-bg-scrollbar-dark
          "
        >
          <div className="tw-py-4 tw-px-6 tw-flex tw-flex-col tw-gap-5">
            <div
            
              className="
                tw-text-sm/6 tw-font-medium
                tw-text-gray-900 dark:tw-text-white
              "
            >
              { t('Resumo da conversa') } 
            </div>

            {
              error && (
                <div
                  className="
                    tw-py-3 tw-px-3 tw-rounded-md
                    tw-text-sm/6 tw-font-normal
                    tw-text-red-700 tw-bg-red-200
                  "
                >
                  { error.message }
                </div>
              )
            }

            <div
              className="
                tw-py-3 tw-px-3 tw-rounded-md
                tw-text-sm/6 tw-font-normal
                tw-text-gray-900 dark:tw-text-white
                tw-bg-selected-chat dark:tw-bg-selected-chat-dark
                empty:tw-hidden
              "
            >
              {
                (
                  isValidating && (
                    <span className="tw-flex tw-w-full tw-justify-center">
                      <AiOutlineLoading3Quarters className="tw-w-8 tw-h-8 tw-animate-spin" />
                    </span>
                  )
                ) || chatSubject
              }
            </div>

            {
              !isValidating && chatSubject && (
                <div
                  className="tw-flex tw-justify-end tw-gap-3"
                >
                  <Button
                    type="button"
                    onClick={refetchSubject}
                    disabled={isValidating}
                    className="
                      tw-rounded-md tw-py-2 tw-px-3
                      tw-text-sm tw-font-semibold
                      tw-bg-west-bar dark:tw-bg-west-bar-dark
                      tw-text-gray-500 dark:tw-text-white

                      hover:tw-brightness-95 dark:hover:tw-brightness-110
                      focus:tw-outline-none
                    "
                  >
                    { t('Gerar novamente') }
                  </Button>

                  <Button
                    className="
                      tw-rounded-md tw-py-2 tw-px-3
                      tw-text-sm tw-font-semibold
                      tw-bg-indigo-600 tw-text-white
                      hover:tw-bg-indigo-700
                      focus:tw-outline-none

                      disabled:tw-opacity-50
                    "
                    onClick={searchFAQ}
                    disabled={shouldFetch}
                  >
                    { t('Consultar FAQ') }
                  </Button>
                </div>
              )
            }

            {
              responseError && (
                <div
                  className="
                    tw-py-3 tw-px-3 tw-rounded-md
                    tw-text-sm/6 tw-font-normal
                    tw-text-red-700 tw-bg-red-200
                  "
                >
                  { responseError.message }
                </div>
              )
            }

            <div
              className="
                tw-py-3 tw-px-3 tw-rounded-md
                tw-text-sm/6 tw-font-normal
                tw-text-gray-900 dark:tw-text-white
                tw-bg-selected-chat dark:tw-bg-selected-chat-dark
                empty:tw-hidden
              "
            >
              {
                (
                  isValidatingResponse && (
                    <span className="tw-flex tw-w-full tw-justify-center">
                      <AiOutlineLoading3Quarters className="tw-w-8 tw-h-8 tw-animate-spin" />
                    </span>
                  )
                ) || (
                  assistantResponse && (
                    <>
                      <span>
                        { assistantResponse.resposta }
                      </span>
                      <div
                        className="
                          tw-flex tw-flex-col
                          tw-mt-4 tw-pt-4
                          tw-border-t
                          tw-border-gray-500 dark:tw-border-gray-400
                        "
                      >
                        <span className="tw-font-semibold tw-mb-1">
                          { t('Artigos relacionados') }
                        </span>
                        {
                          assistantResponse.artigos.map(artigo => (
                            <a
                              key={ 'artigo_' + artigo.id_artigo }
                              href={ artigo.link }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="
                                hover:tw-text-indigo-600 dark:hover:tw-text-indigo-400
                                hover:tw-underline"
                            >
                              <span
                                className="
                                  //tw-rounded-md
                                  tw-py-1
                                  tw-flex tw-justify-between
                                "
                              >
                                <span>
                                  { artigo.nomeartigo }
                                </span>
                                <span>
                                  <FaAngleRight className="tw-size-6 tw-text-inherit" />
                                </span>
                              </span>
                            </a>
                          ))
                        }
                      </div>
                    </>
                  )
                )
              }
            </div>

            {
              assistantResponse && (
                <div
                  className="tw-flex tw-justify-end tw-gap-3"
                >
                  <Button
                    className="
                      tw-rounded-md tw-py-2 tw-px-3
                      tw-text-sm tw-font-semibold
                      tw-bg-west-bar dark:tw-bg-west-bar-dark
                      tw-text-gray-500 dark:tw-text-white
                      hover:tw-brightness-95 dark:hover:tw-bg-west-bar-dark
                      focus:tw-outline-none
                    "
                    onClick={() => refetchResponse()}
                  >
                    {t('Gerar novamente')}
                  </Button>
                </div>
              )
            }
          </div>
        </div>
      </FlyoutPanel>
    </Flyout>
  )
}

export default memo(FAQAssistant);
