import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalFooter, ModalHeader, ModalTitle, ModalSubTitle, ModalCloseButton, ModalBody } from './Modal';
import useModalStore from '../../../stores/useModalStore';
import useDadosSakStore from '../../../stores/useDadosSakStore';

// import Tooltip from '../../../contents/Tooltip';

const ONE_DAY_MS = 1000 * 3600 * 24;

export default function FilterContactsModal () {
  const { data } = useModalStore((state) => state.modal);
  const closeModal = useModalStore((state) => state.closeModal);

  const { t } = useTranslation();

  const _contatos = useDadosSakStore((state) => state.contatos);
  const _marcadores = useDadosSakStore((state) => state.marcadores);

  const lojas = useDadosSakStore((state) => state.lojas);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const userChat = useDadosSakStore((state) => state.userChat);

  const contatos = _contatos[lojaAtivo];
  const marcadores = _marcadores[lojaAtivo];
  const loja = lojas[lojaAtivo];

  const [listDates] = useState({
    'Ultimas 24 Horas': ~~((Date.now() - ONE_DAY_MS) / 1000),
    'Ultimos 2 Dias':   ~~((Date.now() - ONE_DAY_MS * 2) / 1000),
    'Ultimos 3 Dias':   ~~((Date.now() - ONE_DAY_MS * 3) / 1000),
    'Ultimos 7 Dias':   ~~((Date.now() - ONE_DAY_MS * 7) / 1000),
    'Ultimos 15 Dias':  ~~((Date.now() - ONE_DAY_MS * 15) / 1000),
    'Ultimo Mês':       ~~((Date.now() - ONE_DAY_MS * 30) / 1000),
    'Ultimo Semestre':  ~~((Date.now() - ONE_DAY_MS * 180) / 1000),
    'Mostrar Tudo': 0,
  });

  const { prevState, onFilter } = data;

  const [selectedUser, setSelectedUser] = useState(prevState?.selectedUser || '');
  const [selectedLabel, setSelectedLabel] = useState(prevState?.selectedLabel || '');
  const [selectedTimespan, setSelectedTimespan] =
    useState(listDates[window.localStorage?.getItem('cFilter_timespan')] ?? listDates['Ultimos 3 Dias']);

  const updateDefaultFilterDate = useCallback((value, key) => {
    setSelectedTimespan(value);
    window.localStorage?.setItem('cFilter_timespan', key);
  }, []);

  const handleSave = () => {
    if (!onFilter) return;

    const filterFunction = (contato) => {
      if (selectedTimespan && contato.ultimamsg < selectedTimespan)
        return false;

      if (selectedUser && contato.userchat !== selectedUser)
        return false;

      if (selectedLabel) {
        if (selectedLabel === '_NONE' && contato.marcadores?.length)
          return false;

        if (selectedLabel !== '_NONE' && !contato.marcadores?.includes(selectedLabel))
          return false;
      }

      return true;
    }

    closeModal();
    onFilter(filterFunction, { selectedUser, selectedLabel, selectedTimespan });
  }

  const handleReset = () => {
    setSelectedUser('');
    setSelectedLabel('');
    setSelectedTimespan(listDates['Ultimos 3 Dias']);
    window.localStorage?.removeItem('cFilter_timespan');
  }

  const listUserOptions = useCallback(() => {
    if (!userChat || !loja?.userchat || !Object.keys(loja.userchat).length)
      return (<option value="">{ t('Você não possui atendentes') }</option>);

    let filteredContacts = contatos && Object.values(contatos);
    if (!filteredContacts)
      return (<option value="">{ t('Você não possui atendentes') }</option>);

    if (selectedTimespan)
      filteredContacts = filteredContacts.filter(contato => contato.ultimamsg > selectedTimespan);

    if (selectedLabel)
      filteredContacts = selectedLabel === '_NONE'
        ? filteredContacts.filter(contato => !contato.marcadores?.length)
        : filteredContacts.filter(contato => contato.marcadores?.includes(selectedLabel));

    const optionsAtendentes = Object.values(loja.userchat)
      .filter(id => id && userChat[id] && Number(userChat[id].excluido) === 0)
      .map(id => {
        const totalConversasAtendente =
          filteredContacts.filter(contato => contato.userchat && String(contato.userchat) === String(userChat[id].userchat)).length;

        return (
          <option key={id} value={id}>
            { userChat[id].nome } ({ totalConversasAtendente })
          </option>
        );
      })

    return (
      <>
        <option value="">{ t('Todos') }</option>
        { optionsAtendentes }
      </>
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contatos, selectedLabel, userChat, loja, selectedTimespan]);


  const listLabelOptions = useCallback(() => {
    if (!marcadores)
      return <option value="">{t('Você não possui marcadores')}</option>;

    let filteredContacts = contatos && Object.values(contatos);
    if (!filteredContacts)
      return (<option value="">{ t('Você não possui marcadores') }</option>);

    if (selectedTimespan)
      filteredContacts = filteredContacts.filter(contato => contato.ultimamsg > selectedTimespan);
    if (selectedUser )
      filteredContacts = filteredContacts.filter(contato => contato.userchat && String(contato.userchat) === selectedUser);

    const totalConversas = filteredContacts.length;

    const optionsMarcadores = Object.values(marcadores)
      .map(marcador => {
        const totalConversasMarcador =
          filteredContacts.filter(conversa => conversa.marcadores && conversa.marcadores.includes(marcador.id)).length

        return (
          <option key={marcador.id} value={marcador.id}>
            {marcador.titulo} ({totalConversasMarcador})
          </option>
        );
      });

    return (
      <>
        <option value="">{ t('Todos') } ({ totalConversas })</option>
        { optionsMarcadores }
        <option value="_NONE">{ t('Sem marcadores') }</option>
      </>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contatos, marcadores, selectedTimespan, selectedUser]);

  return (
    <Modal open={true} onClose={closeModal} size="xl" className="tw-overflow-visible">
      <ModalHeader className="tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <ModalTitle>
            { t('Filtros') }
          </ModalTitle>
          {/* <ModalSubTitle>
            { t('') }
          </ModalSubTitle> */}
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <div className="tw-flex tw-flex-col tw-gap-8 tw-flex-wrap">
          <div>
            <label
              className="tw-text-sm tw-flex tw-items-center"
            >
              {t('Filtrar por marcador')}
              {/* <Tooltip text="Veja o número de contatos com marcadores ou sem marcadores.">
                <i className="fe fe-help-circle ml-3"></i>
              </Tooltip> */}
            </label>
            <select
              className="
                form-control

                tw-text-sm tw-py-2 tw-h-auto
                tw-text-gray-500 dark:tw-text-neutral-300
                focus:tw-text-gray-900 dark:focus:tw-text-white

                tw-bg-transparent tw-rounded-md tw-border-seg-border dark:tw-border-seg-border-dark
                focus:tw-ring-2 focus:tw-ring-seg-border focus:tw-ring-offset-seg-border
                focus:dark:tw-ring-seg-border-dark focus:dark:tw-ring-offset-seg-border-dark
                focus:tw-bg-bar dark:focus:tw-bg-bar-dark
              "
              value={selectedLabel}
              onChange={e => setSelectedLabel(String(e.target.value))}
            >
              { listLabelOptions() }
            </select>
          </div>
          <div>
            <label
              className="tw-text-sm tw-flex tw-items-center"
            >
              {t('Filtrar por atendente')}
              {/* <Tooltip text="Veja o número de contatos que cada atendente possui!">
                <i className="fe fe-help-circle ml-3"></i>
              </Tooltip> */}
            </label>
            <select
              name="atendentes"
              className="
                form-control

                tw-text-sm tw-py-2 tw-h-auto
                tw-text-gray-500 dark:tw-text-neutral-300
                focus:tw-text-gray-900 dark:focus:tw-text-white

                tw-bg-transparent tw-rounded-md tw-border-seg-border dark:tw-border-seg-border-dark
                focus:tw-ring-2 focus:tw-ring-seg-border focus:tw-ring-offset-seg-border
                focus:dark:tw-ring-seg-border-dark focus:dark:tw-ring-offset-seg-border-dark
                focus:tw-bg-bar dark:focus:tw-bg-bar-dark
              "
              value={selectedUser}
              onChange={e => setSelectedUser(String(e.target.value))}
            >
              { listUserOptions() }
            </select>
          </div>
          <div>
            <label
              className="tw-text-sm tw-flex tw-items-center"
            >
              {t('Filtrar por data')}
              {/* <Tooltip text="Mostre apenas os contatos façam sentido para você!">
                <i className="fe fe-help-circle ml-3"></i>
              </Tooltip> */}
            </label>
            <select
              name="dataMensagens"
              className="
                form-control

                tw-text-sm tw-py-2 tw-h-auto
                tw-text-gray-500 dark:tw-text-neutral-300
                focus:tw-text-gray-900 dark:focus:tw-text-white

                tw-bg-transparent tw-rounded-md tw-border-seg-border dark:tw-border-seg-border-dark
                focus:tw-ring-2 focus:tw-ring-seg-border focus:tw-ring-offset-seg-border
                focus:dark:tw-ring-seg-border-dark focus:dark:tw-ring-offset-seg-border-dark
                focus:tw-bg-bar dark:focus:tw-bg-bar-dark
              "
              value={selectedTimespan}
              onChange={e => updateDefaultFilterDate(String(e.target.value), e.target.selectedOptions[0].innerText)}
            >
              {listDates &&
                Object.entries(listDates)?.map(([text, value], index) => (
                  <option key={index} data-value={text} value={value}>
                    {t(text)}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="tw-gap-3">
        <button
          type="button"
          onClick={handleSave}
          className="
            tw-inline-flex tw-justify-center
            tw-rounded-md tw-bg-indigo-600
            tw-px-3 tw-py-2
            tw-text-sm tw-font-semibold tw-text-white
            tw-shadow-sm
            hover:tw-bg-indigo-500 focus-visible:tw-outline
            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
            focus-visible:tw-outline-indigo-600
            focus:tw-outline-2 focus:tw-outline-offset-2
            focus:tw-outline-indigo-600
            focus:tw-ring-0 focus:tw-outline-none
          "
        >
          { t('Filtrar contatos') }
        </button>
        <button
          type="button"
          onClick={closeModal}
          className="
            tw-inline-flex tw-justify-center
            tw-rounded-md tw-bg-west-bar dark:tw-bg-west-bar-dark
            tw-px-3 tw-py-2
            tw-text-sm tw-font-semibold
            tw-text-gray-500 dark:tw-text-white
            tw-shadow-sm
            hover:tw-brightness-95 dark:hover:tw-brightness-110 focus-visible:tw-outline
            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
            focus-visible:tw-outline-west-bar dark:focus-visible:tw-outline-west-bar-dark

            focus:tw-outline-2 focus:tw-outline-offset-2
            focus:tw-outline-west-bar dark:focus:tw-outline-west-bar-dark
            focus:tw-ring-0 focus:tw-outline-none
          "
        >
          { t('Cancelar') }
        </button>

        <div className="tw-flex-1">
          <button
            type="button"
            onClick={handleReset}
            className="
              tw-inline-flex tw-justify-center
              tw-rounded-md tw-bg-west-bar dark:tw-bg-west-bar-dark
              tw-px-3 tw-py-2
              tw-text-sm tw-font-semibold
              tw-text-gray-500 dark:tw-text-white
              tw-shadow-sm
              hover:tw-brightness-95 dark:hover:tw-brightness-110 focus-visible:tw-outline
              focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
              focus-visible:tw-outline-west-bar dark:focus-visible:tw-outline-west-bar-dark

              focus:tw-outline-2 focus:tw-outline-offset-2
              focus:tw-outline-west-bar dark:focus:tw-outline-west-bar-dark
              focus:tw-ring-0 focus:tw-outline-none
            "
          >
            { t('Restaurar filtros') }
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
