import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

// import { StyledThing } from './styles';
import { classnames } from '../../utils/usefulFunctions';
import Tooltip from '../Tooltip';

import DrawerManager from './DrawerManager';

import HomeTab from './tabs/home/HomeTab';
import QuickRepliesTab from './tabs/replies/QuickRepliesTab';
import FaqTab from './tabs/faq/FaqTab';
import useSideExtensionStore from '../../stores/useSideExtensionStore';

export function toggleSidebar (selector) {
  useSideExtensionStore.getState().toggleIsSideExtensionOpen();
}

function SideExtension() {
  const { t } = useTranslation();

  const [topSelectedTab, setTopSelectedTab] = useState('home');
  const isSideExtensionOpen = useSideExtensionStore(state => state.isSideExtensionOpen);
  const isDrawerSet = useSideExtensionStore(state => !!state.drawer);

  return (
    <div
      className="
        //tw-overflow-x-hidden //tw-overflow-y-auto
        tw-min-w-[310px] tw-max-w-[324px]
        tw-h-dvh tw-w-full tw-max-h-dvh

        tw-bg-bar dark:tw-bg-bar-dark
        tw-border-l tw-border-seg-border dark:tw-border-seg-border-dark
        [overflow-wrap:anywhere]

        tw-transition-transform tw-ease-in-out tw-duration-300

        tw-z-10 tw-right-0 tw-top-0
        tw-translate-x-0

        //max-1200:tw-min-w-fit
        max-1200:tw-fixed
        max-1200:tw-max-w-[50%]
        max-1200:tw-w-[50%]
        max-1200:data-[open=false]:tw-invisible
        max-1200:data-[open=false]:tw-translate-x-full
      "

      data-open={isSideExtensionOpen || isDrawerSet}
    >
      {/* WRAPPER */}
      <div
        className="
          tw-relative
          tw-flex tw-flex-col tw-h-full
          //tw-justify-between
        "
      >
        {/* DRAWER */}
        <DrawerManager />

        {/* HEADER */}
        <div
          className="
            tw-flex tw-justify-between
            //!-tw-mx-2.5 //!-tw-mt-2.5 !tw-px-5 !tw-pt-3.5
            tw-border-b tw-border-seg-border dark:tw-border-seg-border-dark
          "
          role="tablist"
        >
          <div
            className="
              tw-flex tw-items-center tw-justify-between tw-flex-wrap
              tw-flex-grow tw-h-[3.625rem]
              max-1200:tw-gap-3
            "
          >
            <span className="min-[1200px]:tw-hidden tw-flex-grow">
              <Tooltip text={t('Voltar')} position="bottom">
                <button
                  className="main-header-link bg-transparent"
                  onClick={() => useSideExtensionStore.getState().setIsSideExtensionOpen(false)}
                >
                  <i className="fal fa-arrow-left"></i>
                </button>
              </Tooltip>
            </span>

            <span
              className={classnames(`
                !tw-py-[19px]
                tw-text-sm tw-font-medium tw-cursor-pointer
                tw-text-gray-500 dark:tw-text-neutral-300
                [&.active]:tw-font-semibold
                [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark

                [&.active]:tw-relative
                [&.active:after]:tw-content-['']
                [&.active:after]:tw-absolute [&.active:after]:tw-bottom-0 [&.active:after]:tw-left-0
                [&.active:after]:tw-w-full [&.active:after]:tw-h-0.5
              [&.active:after]:tw-bg-active [&.active:after]:dark:tw-bg-active-dark
              `, { active: topSelectedTab === 'home' })}
              onClick={() => setTopSelectedTab('home')}
              role="tab"
            >
              { t('Perfil e Pedidos') }
            </span>
            <span
              className={classnames(`
                !tw-py-[19px]
                tw-text-sm tw-font-medium tw-cursor-pointer
                tw-text-gray-500 dark:tw-text-neutral-300
                [&.active]:tw-font-semibold
                [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark

                [&.active]:tw-relative
                [&.active:after]:tw-content-['']
                [&.active:after]:tw-absolute [&.active:after]:tw-bottom-0 [&.active:after]:tw-left-0
                [&.active:after]:tw-w-full [&.active:after]:tw-h-0.5
              [&.active:after]:tw-bg-active [&.active:after]:dark:tw-bg-active-dark
              `, { active: topSelectedTab === 'quickMessages' })}
              onClick={() => setTopSelectedTab('quickMessages')}
              role="tab"
            >
              { t('Mens. Rápidas') }
            </span>
            <span
              className={classnames(`
                !tw-py-[19px]
                tw-text-sm tw-font-medium tw-cursor-pointer
                tw-text-gray-500 dark:tw-text-neutral-300
                [&.active]:tw-font-semibold
                [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark

                [&.active]:tw-relative
                [&.active:after]:tw-content-['']
                [&.active:after]:tw-absolute [&.active:after]:tw-bottom-0 [&.active:after]:tw-left-0
                [&.active:after]:tw-w-full [&.active:after]:tw-h-0.5
              [&.active:after]:tw-bg-active [&.active:after]:dark:tw-bg-active-dark
              `, { active: topSelectedTab === 'faq' })}
              onClick={() => setTopSelectedTab('faq')}
              role="tab"
            >
              { t('FAQ') }
            </span>
          </div>
        </div>

        {/* CONTENT */}
        {
          topSelectedTab === 'home' && (
            <HomeTab/>
          )
        }

        {
          topSelectedTab === 'quickMessages' && (
            <QuickRepliesTab/>
          )
        }

        {
          topSelectedTab === 'faq' && (
            <FaqTab/>
          )
        }
      </div>
    </div>
  );
}

export default memo(SideExtension);
