import { useTranslation } from 'react-i18next';

import { Modal, ModalFooter, ModalHeader, ModalTitle, ModalSubTitle, ModalCloseButton, ModalBody } from './Modal';
import useModalStore from '../../../stores/useModalStore';

export default function ConfirmationModal () {
  const { data } = useModalStore((state) => state.modal);
  const closeModal = useModalStore((state) => state.closeModal);

  const { t } = useTranslation();

  const {
    title, description,
    cancelBtnText, confirmBtnText,
    onCancel, onConfirm
  } = data;

  function handleClose () {
    closeModal();
    if (onCancel) onCancel();
  }

  function handleConfirm () {
    closeModal();
    if (onConfirm) onConfirm();
  }

  return (
    <Modal open={true} onClose={closeModal} size="xl" className="tw-overflow-visible">
      <ModalHeader className="tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <ModalTitle>
            { t(title || 'Tem certeza?') }
          </ModalTitle>
          {/* <ModalSubTitle>
            { t('') }
          </ModalSubTitle> */}
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        { description && t(description) }
      </ModalBody>
      <ModalFooter className="tw-gap-3">
        <button
          type="button"
          onClick={handleConfirm}
          className="
            tw-inline-flex tw-justify-center
            tw-rounded-md tw-bg-indigo-600
            tw-px-3 tw-py-2
            tw-text-sm tw-font-semibold tw-text-white
            tw-shadow-sm
            hover:tw-bg-indigo-500 focus-visible:tw-outline
            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
            focus-visible:tw-outline-indigo-600
            focus:tw-outline-2 focus:tw-outline-offset-2
            focus:tw-outline-indigo-600
            focus:tw-ring-0 focus:tw-outline-none
          "
        >
          { t(confirmBtnText || 'Confirmar') }
        </button>
        <button
          type="button"
          onClick={handleClose}
          className="
            tw-inline-flex tw-justify-center
            tw-rounded-md tw-bg-west-bar dark:tw-bg-west-bar-dark
            tw-px-3 tw-py-2
            tw-text-sm tw-font-semibold
            tw-text-gray-500 dark:tw-text-white
            tw-shadow-sm
            hover:tw-brightness-95 dark:hover:tw-brightness-110 focus-visible:tw-outline
            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
            focus-visible:tw-outline-west-bar dark:focus-visible:tw-outline-west-bar-dark

            focus:tw-outline-2 focus:tw-outline-offset-2
            focus:tw-outline-west-bar dark:focus:tw-outline-west-bar-dark
            focus:tw-ring-0 focus:tw-outline-none
          "
        >
          { t(cancelBtnText || 'Cancelar') }
        </button>
      </ModalFooter>
    </Modal>
  )
}
