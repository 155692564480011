import React, { useEffect, useState } from 'react';
import { FiPlus } from "react-icons/fi";

import { Container, ContainerLoja, Icon } from './styles';

import { restringirNome } from '../../utils/formatDados';
import useDadosSakStore from '../../stores/useDadosSakStore';
import { ErrorChat } from '../../components/errors';
import useMarcarLidoStore from '../../stores/useMarcarLidoStore';
import Tooltip from '../Tooltip';

const ORIGINAL_TITLE = document.title;

function ListaLojas() {
  const [lojaNotific, setLojaNotif] = useState({});

  const contatos = useDadosSakStore((state) => state.contatos);
  const setContatoAtivo = useDadosSakStore(
    (state) => state.setContatoAtivoStore
  );
  const lojas = useDadosSakStore((state) => state.lojas);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const setLojaAtivo = useDadosSakStore((state) => state.setLojaAtivoStore);
  const setShowMarcarLidoBtn = useMarcarLidoStore(
    (state) => state.setShowMarcarLidoBtn
  );
  const setCheckboxMarcadosComoLidoQuantity = useMarcarLidoStore(
    (state) => state.setCheckboxMarcadosComoLidoQuantity
  );

  /**
   * Limpa os checkboxes de marcar como lio
   * Esconde o botão de marcar como lido
   * Reseta a quantidade de selecionados
   */
  function clearMarcarLidoCheckboxesAndBox() {
    document
      .querySelectorAll('[name=checkbox_marcar_lido]')
      .forEach((el) => (el.checked = false));
    setShowMarcarLidoBtn(false);
    setCheckboxMarcadosComoLidoQuantity(0);
  }

  /**
   * Troca de loja
   * @param {*} userserv
   */
  function trocarLoja(userserv) {
    clearMarcarLidoCheckboxesAndBox();
    setContatoAtivo(() => {});
    setLojaAtivo(() => userserv);
  }

  useEffect(() => {
    const tenDaysAgo = Math.floor(Date.now() / 1e3 - 3600 * 24 * 10);

    const lojaNotif = {};
    for (let index in lojas) {
      const userserv = lojas[index].userserv;
      lojaNotif[userserv] = 0;
      for (let i in contatos[userserv]) {
        if (!contatos[userserv][i].chatid) continue;
        if (contatos[userserv][i].ultimamsg <= tenDaysAgo) continue;
        if (contatos[userserv][i].naolidas > 0) lojaNotif[userserv]++;
      }
    }

    setLojaNotif(lojaNotif);

    document.title = lojaNotif[lojaAtivo] > 0
      ? `(${lojaNotif[lojaAtivo]}) ${ORIGINAL_TITLE}` : ORIGINAL_TITLE;
  }, [contatos, lojaAtivo, lojas]);

  return (
    <ErrorChat codeError={37}>
      <Container className="container--lojas">
        {Object.values(lojas).length ? (
          Object.values(lojas).map((loja) => {
            return (
              <Tooltip key={ 'loja_' + loja.userserv } text={ loja.nome } position='right'>
                <ContainerLoja key={loja.userserv}>
                  <Icon
                    select={lojaAtivo === loja.userserv && 'selected'}
                    color={loja.cor}
                    notif={lojaNotific?.[loja.userserv]}
                    img={loja.img}
                    onClick={() => trocarLoja(loja.userserv)}
                  >
                    <span>
                      {restringirNome(loja.nome, 2)}
                    </span>
                  </Icon>
                </ContainerLoja>
              </Tooltip>
            );
          })
        ) : (
          <ContainerLoja>
            <Icon select={'selected'} color={'#49c18f'}>
              ?
            </Icon>
          </ContainerLoja>
        )}

        <a
          className="
            tw-size-10 tw-rounded-full tw-bg-gray-400 tw-text-white hover:tw-text-white
            tw-flex tw-items-center tw-justify-center
            hover:tw-scale-[1.15] tw-transition-all tw-duration-200 tw-cursor-pointer
          "
          href="https://app.sak.com.br/whatsapps"
          target="_blank"
          rel="noreferrer"
        >
          <FiPlus className="tw-size-[1.125rem]" />
        </a>
      </Container>
    </ErrorChat>
  );
}

export default ListaLojas;
