import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSend } from 'react-icons/fi';
import { Button } from '@headlessui/react';

import { Modal, ModalHeader, ModalTitle, ModalSubTitle, ModalCloseButton, ModalBody } from './Modal';
import useModalStore from '../../../stores/useModalStore';
import useSocketStore from '../../../stores/useSocketStore';
import MessageStatus from '../../../components/MessageStatus';
import { TextArea } from '../../../contents/CaixaMensagem/styles';
import Tooltip from '../../../contents/Tooltip';
import { formatTimeMensagens } from '../../../utils/formatDados';
import { classnames } from '../../../utils/usefulFunctions';

export default function EditMessageModal () {
  const { data } = useModalStore((state) => state.modal);
  const closeModal = useModalStore((state) => state.closeModal);

  const { t } = useTranslation();

  const mensagem = data.message;

  const inputTextRef = useRef(null);
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);

  const [canEdit, setCanEdit] = useState(false);

  function editarMensagem() {
    if (!canEdit) return;

    const { idFull, userserv, userchat } = mensagem || {};

    const texto = inputTextRef.current.value;

    if (
      !sendSocketDados({
        function: 'editarMensagem',
        data: [{ idFull, userserv, userchat, texto }],
      })
    );

    closeModal();
  }

  function filtroMensagem(texto) {
    const regexLinkLily = /https:\/\/aval\.ie\/\w{8,}/i;
    const regexpEncerrarAtendimento = /\*#Robô:\* Atendimento encerrado./gi;

    if (texto.match(regexpEncerrarAtendimento))
      return '*#Robô:* Atendimento encerrado';

    if (texto.includes('https://aval.ie/') && !regexLinkLily.test(texto))
      return 'Atendimento finalizado!';

    return texto;
  }

  const handleTextSend = (event) => {
    const keyCode = event.which || event.keyCode;

    if (keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      editarMensagem();
    }
  };

  const handleResize = (event) => {
    const inputEl = inputTextRef.current;

    const sizeLength = 7; // quantidade px de cada letra
    const textLength = inputEl?.value?.length || 0; // quantidade de carater
    const baseWidth = 141; // é o tamanho mínimo do bloco de texto
    const lengthWidth = baseWidth + textLength * sizeLength; // tamanho do container com caracteres
    const containerWidth = inputEl.offsetWidth;
    const calcLines = ~~(lengthWidth / containerWidth);

    const numeroDeLinhas = (inputEl.value?.match(/\n/g) || [])?.length;
    const defaultHeight = 30 + (calcLines + numeroDeLinhas) * 18 + 12 + 2;
    inputEl.style.height = `${defaultHeight}px`;
  };

  useEffect(() => {
    if (!mensagem) return;

    const novoTexto = filtroMensagem(mensagem.texto);
    inputTextRef.current.value = novoTexto.replace(/\\n/, '\n');

    handleResize();

    // Se o texto foi modificado, não pode editar
    // Tem uma exceção para a mensagem de atendimento finalizado
    if (mensagem.texto !== novoTexto || novoTexto === 'Atendimento finalizado!') {
      setCanEdit(false);
      return;
    }

    // Se a mensagem houver sido enviada há mais de 15 minutos, não pode editar
    const elapsed = ~~(Date.now() / 1000) - mensagem.data_whats;
    setCanEdit(elapsed < 900);
  }, [mensagem]);


  return (
    <Modal open={true} onClose={closeModal} size="xl" className="tw-overflow-visible">
      <ModalHeader className="tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <ModalTitle>
            { t('Editar mensagem') }
          </ModalTitle>
          {/* <ModalSubTitle>
            { t('') }
          </ModalSubTitle> */}
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <div className="message-body tw-mb-12 tw-mt-4">
          <div className="message-row">
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ position: 'relative' }}
            >
              <div
                className="
                  message-image-content tw-order-1 tw-rounded-xl
                  tw-relative tw-flex-shrink
                  tw-text-gray-900 dark:tw-text-white
                  [&_a]:tw-text-indigo-600 [&_a]:dark:tw-text-indigo-400 message-tail
                  tw-bg-msg-bubble-me dark:tw-bg-msg-bubble-me-dark me
                "
              >
                <div className="tw-flex tw-relative tw-flex-wrap tw-justify-end">
                  {filtroMensagem(mensagem.texto)}
                  <div
                    className="
                      metadata
                      tw-text-black/50 dark:!tw-text-inherit
                      !tw-text-[.6875rem] !tw-leading-[.8125rem]
                    "
                  >
                    {formatTimeMensagens(mensagem.data_whats)}
                    <MessageStatus className="tw-ml-0.5" status={mensagem.status} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div className="tw-border tw-border-seg-border dark:tw-border-seg-border-dark tw-rounded-md">
          <div className="tw-flex tw-flex-col tw-w-full tw-items-center">
            <div className='tw-w-full'>
              <TextArea
                className='tw-w-full !tw-max-h-[min(60dvh,500px)]'
                ref={inputTextRef}
                id="chat-id-1-input"
                onKeyPress={handleTextSend}
                // onChange={handleTextChange}
                placeholder={t('Escreva uma mensagem')}
                data-emoji-input
                data-autosize="true"
                rows="2"
              ></TextArea>
            </div>

            <div className="
              tw-flex tw-items-center tw-w-full tw-justify-between
              tw-border-t tw-border-seg-border dark:tw-border-seg-border-dark
              tw-px-3 tw-py-2 [&>div]:tw-h-9
            ">
              <div></div>
              <div className='tw-flex tw-items-center'>
                <Tooltip text="Enviar mensagem">
                  <Button
                    onClick={() => canEdit && editarMensagem()}
                    className={
                      classnames(`
                        tw-p-2 tw-rounded-md
                        tw-bg-west-bar dark:tw-bg-west-bar-dark
                        hover:tw-brightness-95 dark:hover:tw-brightness-125
                        focus:tw-outline-none
                      `, { 'disabled cursor-none opacity-20': !canEdit })
                    }
                  >
                    <FiSend className="tw-w-5 tw-h-5 tw-text-gray-900 dark:tw-text-white"/>
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>

        </div>
      </ModalBody>
    </Modal>
  )
}
