import { useRef, forwardRef } from 'react';
import { CiSearch } from 'react-icons/ci';
import { HiChevronDown } from 'react-icons/hi';
import { HiXMark } from 'react-icons/hi2';

import { classnames } from '../../utils/usefulFunctions';

// function FormInput ({
//   value, onChange,
//   placeholder,
//   type = 'text',

//   icon: Icon = CiSearch,
//   rightHanded = false,
//   className,

//   inputClassName,
//   inputProps,
// }, ref) {

//   return (
//     <div
//       className={classnames(className, `
//         tw-relative tw-rounded-md tw-shadow-sm
//       `)}
//     >
//       <div
//         className={classnames(`
//           tw-pointer-events-none tw-absolute tw-inset-y-0  tw-flex tw-items-center tw-pl-3
//         `, rightHanded ? 'tw-right-0' : 'tw-left-0')}
//       >
//         <Icon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
//       </div>

//       <input
//         {...inputProps}
//         ref={ref}
//         className={classnames(inputClassName, `
//           tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5
//           tw-text-gray-500 dark:tw-text-neutral-300
//           tw-ring-1 tw-ring-inset tw-ring-seg-border dark:tw-ring-seg-border-dark
//           placeholder:tw-text-gray-400 dark:placeholder:tw-text-neutral-400
//           focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-300 dark:focus:tw-ring-neutral-600
//           focus:tw-outline-none focus:tw-border-0
//           sm:tw-text-sm sm:tw-leading-6
//           sm:placeholder:tw-text-sm sm:placeholder:tw-leading-6
//           tw-bg-transparent
//         `, rightHanded ? 'tw-pr-10' : 'tw-pl-10')}
//         type={type}
//         placeholder={placeholder}
//         onChange={onChange}
//         value={value}
//       />
//     </div>
//   );
// }

// export default forwardRef(FormInput);

export function StaticSearchBar ({
  value, onChange,
  placeholder,

  icon: Icon = CiSearch,
  clearIcon: ClearIcon = HiXMark,
  className,

  inputClassName,
  inputProps,
}) {
  const inputRef = useRef(null);

  return (
    <div
      className={classnames(className, `tw-relative tw-rounded-md //tw-shadow-sm`)}
    >
      <div
        className="
          tw-pointer-events-none
          tw-absolute tw-inset-y-0 tw-left-0
          tw-flex tw-items-center tw-pl-3
        "
      >
        <Icon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
      </div>

      <div
        className={classnames(`
          tw-cursor-pointer
          tw-absolute tw-inset-y-0 tw-right-0
          tw-flex tw-items-center tw-pr-3
        `, !value && 'tw-hidden')}
        onClick={() => {
          onChange({ target: { value: '' } });
          inputRef.current.focus();
        }}
      >
        <ClearIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
      </div>

      <input
        {...inputProps}
        ref={inputRef}
        className={classnames(inputClassName, `
          tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-10
          tw-text-gray-500 dark:tw-text-neutral-300
          tw-ring-1 tw-ring-inset tw-ring-seg-border dark:tw-ring-seg-border-dark
          placeholder:tw-text-gray-400 dark:placeholder:tw-text-neutral-400
          focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-300 dark:focus:tw-ring-neutral-600
          focus:tw-outline-none focus:tw-border-0
          sm:tw-text-sm sm:tw-leading-6
          sm:placeholder:tw-text-sm sm:placeholder:tw-leading-6
          tw-bg-transparent
        `)}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export function CheckBox ({
  checked,
  onChange,

  className,
  inputProps,
}) {

  return (
    <div
      className={classnames(className, 'tw-group tw-grid tw-size-4 tw-grid-cols-1')}
    >
      <input
        {...inputProps}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        className="
          tw-col-start-1 tw-row-start-1
          tw-appearance-none tw-rounded
          tw-border
          tw-border-gray-300 dark:tw-border-neutral-600
          tw-bg-transparent

          //tw-border-seg-border //dark:tw-border-seg-border-dark
          //tw-bg-white 

          checked:tw-border-indigo-600 checked:tw-bg-indigo-600
          indeterminate:tw-border-indigo-600 indeterminate:tw-bg-indigo-600
          focus-visible:tw-outline focus-visible:tw-outline-2
          focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600
          disabled:tw-border-gray-300 disabled:tw-bg-gray-100
          disabled:checked:tw-bg-gray-100 forced-colors:tw-appearance-auto"
      />
      <svg
        fill="none"
        viewBox="0 0 14 14"
        className="
          tw-pointer-events-none
          tw-col-start-1 tw-row-start-1
          tw-size-3.5 tw-self-center tw-justify-self-center
          tw-stroke-white group-has-[:disabled]:tw-stroke-gray-950/25"
      >
        <path
          d="M3 8L6 11L11 3.5"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="tw-opacity-0 group-has-[:checked]:tw-opacity-100"
        />
        <path
          d="M3 7H11"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="tw-opacity-0 group-has-[:indeterminate]:tw-opacity-100"
        />
      </svg>
    </div>
  )
}

export function NativeSelect ({
  value,
  onChange,
  className,
  inputProps,
  options,
  children
}) {

  return (
    <div className={classnames(className, 'tw-grid tw-grid-cols-1')}>
      <select
        {...inputProps}
        value={value}
        onChange={onChange}
        className="
          tw-col-start-1 tw-row-start-1
          tw-w-full tw-appearance-none tw-rounded-md
          //tw-bg-white tw-bg-transparent
          tw-py-1.5 tw-pl-3 tw-pr-8
          tw-text-base //tw-text-gray-900
          tw-text-gray-500 dark:tw-text-neutral-300
          //tw-outline //tw-outline-1 //-tw-outline-offset-1
          //tw-outline-gray-300
          //focus:tw-outline //focus:tw-outline-2
          //focus:-tw-outline-offset-2 //focus:tw-outline-indigo-600

          focus:tw-outline-none
          tw-ring-1 tw-ring-inset tw-ring-seg-border dark:tw-ring-seg-border-dark
          focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-300 dark:focus:tw-ring-neutral-600

          sm:tw-text-sm/6
          
          [&>option]:tw-bg-bar [&>option]:dark:tw-bg-bar-dark
        "  
      >
        {
          options?.map(option => (
            <option key={option.value} value={option.value}>
              { option.label }
            </option>
          ))
        }

        { children }
      </select>
      <HiChevronDown
        aria-hidden="true"
        className="
          tw-pointer-events-none
          tw-col-start-1 tw-row-start-1
          tw-mr-2 tw-size-5 tw-self-center tw-justify-self-end
          //tw-text-gray-500 tw-text-gray-400 sm:tw-size-4
        "
      />
    </div>
  )
}

function _Textarea ({
  value,
  onChange,
  className,
  ...props
}, ref) {

  return (
    <textarea
      {...props}
      ref={ref}
      className={classnames(className, `
        tw-block tw-w-full
        tw-rounded-md
        //tw-bg-white
        tw-bg-transparent
        tw-px-3 tw-py-1.5
        tw-text-base
        //tw-text-gray-900
        tw-text-gray-500 dark:tw-text-neutral-300
        //tw-outline outline-1 //-tw-outline-offset-1
        //tw-outline-gray-300
        placeholder:tw-text-gray-400
        //focus:tw-outline //focus:tw-outline-2
        //focus:-tw-outline-offset-2 //focus:tw-outline-indigo-600
        sm:tw-text-sm/6

        focus:tw-outline-none
        tw-ring-1 tw-ring-inset tw-ring-seg-border dark:tw-ring-seg-border-dark
        focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-300 dark:focus:tw-ring-neutral-600

        [&::-webkit-scrollbar]:tw-w-1
        [&::-webkit-scrollbar-thumb]:!tw-bg-scrollbar
        [&::-webkit-scrollbar-thumb]:dark:!tw-bg-scrollbar-dark
      `)}
      value={value}
      onChange={onChange}
    />
  )
}

export const Textarea = forwardRef(_Textarea);
