import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, CloseButton } from '@headlessui/react'
import { HiXMark } from 'react-icons/hi2';

import { classnames } from '../../../utils/usefulFunctions'

export function Modal ({ size = 'lg', className, children, ...props }) {
  return (
    <Dialog
      {...props}
      className="tw-relative tw-z-10"
    >
      <DialogBackdrop
        transition
        className="
          tw-fixed tw-inset-0
          //tw-bg-[#323232] //tw-bg-opacity-25
          tw-bg-black tw-bg-opacity-60
          tw-transition-opacity
          data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300
          data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in"
      />

      <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
        <div className="tw-flex tw-min-h-full tw-items-center //tw-items-end tw-justify-center tw-p-4 tw-text-center //sm:tw-items-center sm:tw-p-0">
          <DialogPanel
            transition
            className={
              classnames(className, `
                tw-relative tw-transform
                tw-overflow-hidden tw-rounded-lg
                tw-bg-bar dark:tw-bg-bar-dark
                //tw-px-4 //tw-pb-4 //tw-pt-5
                tw-text-left tw-shadow-xl tw-transition-all
                data-[closed]:tw-translate-y-4 data-[closed]:tw-opacity-0
                data-[enter]:tw-duration-300 data-[leave]:tw-duration-200
                data-[enter]:tw-ease-out data-[leave]:tw-ease-in
                sm:tw-my-8 sm:tw-w-full //sm:tw-p-6
                data-[closed]:sm:tw-translate-y-0 data-[closed]:sm:tw-scale-95
              `, {
                'sm:tw-max-w-xs': size === 'xs',
                'sm:tw-max-w-sm': size === 'sm',
                'sm:tw-max-w-md': size === 'md',
                'sm:tw-max-w-lg': size === 'lg',
                'sm:tw-max-w-xl': size === 'xl',
                'sm:tw-max-w-2xl': size === '2xl',
                'sm:tw-max-w-3xl': size === '3xl',
                'sm:tw-max-w-4xl': size === '4xl',
                'sm:tw-max-w-5xl': size === '5xl'
              })
            }
          >
            { children }
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export function ModalHeader ({ className, children, ...props }) {
  return (
    <div
      {...props}
      className={classnames(className, `
        tw-px-8 tw-py-5 tw-flex tw-items-center
        tw-border-b tw-border-seg-border dark:tw-border-seg-border-dark
      `)}
    >
      { children }
    </div>
  );
}

export function ModalTitle ({ className, children, ...props }) {
  return (
    <DialogTitle
      {...props}
      className={
        classnames(className, `
          tw-text-gray-900 dark:tw-text-white
            tw-text-base tw-font-semibold
        `)
      }
    >
      { children }
    </DialogTitle>
  )
}

export function ModalSubTitle ({ className, children, ...props }) {
  return (
    <span
      {...props}
      className={
        classnames(className, `
          tw-text-gray-500 dark:tw-text-gray-400
          tw-text-sm tw-font-normal tw-mt-1
        `)
      }
    >
      { children }
    </span>
  );
}

export function ModalCloseButton ({ className, children, ...props }) {
  return (
    <CloseButton as="span">
      <HiXMark className="tw-size-6 tw-cursor-pointer tw-text-gray-900 dark:tw-text-white" />
    </CloseButton>
  );
}

export function ModalBody ({ className, children, ...props }) {
  return (
    <div
      {...props}
      className={
        classnames(className, `
          tw-px-8 tw-py-8
          tw-text-gray-500 dark:tw-text-gray-400
        `)
      }
    >
      { children }
    </div>
  );
}

export function ModalFooter ({ className, children, ...props }) {
  return (
    <div
      {...props}
      className={classnames(className, `
        tw-px-8 tw-py-5 tw-flex //sm:tw-flex sm:tw-flex-row-reverse //sm:tw-px-6
        tw-border-t tw-border-seg-border dark:tw-border-seg-border-dark
      `)}
    >
      { children }
    </div>
  );
}
