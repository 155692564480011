import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { HiOutlinePlus } from 'react-icons/hi';
import { HiOutlineChatBubbleBottomCenter } from 'react-icons/hi2';
import { TbBrandWhatsapp } from 'react-icons/tb';

import { useMensagensExtensao, enviarMensagemExtensao } from './OrdersTab';
import { sakBaseUrl } from '../../../../utils/usefulFunctions';

export default function TemplatesTab () {
  const { t } = useTranslation();

  const [previewMsg, setPreviewMsg] = useState(null);
  const messagesContainerRef = useRef(null);

  const { data: messages_data, isLoading: messages_isLoading } = useMensagensExtensao();

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-flex-col">
        <div
          className="
            tw-flex tw-justify-between tw-items-center tw-gap-2
            tw-p-2 tw-rounded-md
            tw-bg-west-bar dark:tw-bg-west-bar-dark
          "
        >
          <span
            className="
              tw-flex tw-gap-2 tw-items-center
              tw-text-gray-900 dark:tw-text-white
            ">
            <HiOutlineChatBubbleBottomCenter className="tw-size-4 tw-text-gray-400" />
            { t('Mensagens rápidas') }
          </span>
        </div>

        <div
          ref={messagesContainerRef}
          className="
            tw-mt-3 tw-px-2
            tw-flex tw-items-center tw-flex-wrap tw-gap-2
          "
        >
          {
            messages_isLoading
              ? (
                <span>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">{t('Carregando...')}</span>
                  </div>
                </span>)
              : (
                <>
                  {
                    messages_data?.mensagens?.map(msg => (
                      <span
                        key={'msg_' + msg.id}
                        className="tw-cursor-pointer"
                        onMouseOver={() => setPreviewMsg({ body: msg.texto, title: msg.nome })}
                        onMouseOut={() => setPreviewMsg(null)}
                        onClick={() => enviarMensagemExtensao(msg.id, 0)}
                      >
                        <TbBrandWhatsapp className="tw-size-8 tw-text-gray-400" />
                      </span>
                    ))
                  }
                  <a
                    className="
                      tw-flex tw-items-center tw-justify-center
                      tw-opacity-75
                      tw-border-2 tw-rounded-full tw-p-1.5
                      tw-border-dashed tw-border-gray-400
                    "
                    href={sakBaseUrl("/mensagens#tab-extensao")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HiOutlinePlus className="tw-size-4 tw-text-gray-400" />
                  </a>
                </>
              )
          }
        </div>
      </div>

      {
        previewMsg && (
          <div
            className="
              tw-fixed tw-bottom-0 tw-right-0 tw-w-full
              tw-bg-white
              tw-border-[1rem] tw-border-b-0 tw-border-white tw-rounded-t-[2.5rem]
              tw-overflow-y-auto tw-overflow-x-hidden
              tw-shadow-[0px_1px_4px_rgb(185,_210,_238)]
            "
            style={{
              maxHeight: window.innerHeight - messagesContainerRef.current?.getBoundingClientRect().bottom,
              background: `url('https://app.sak.com.br/image/whats_back.png')`
            }}
          >
            <div
              className="
                tw-bg-[#075e54] tw-h-12 tw-p-2
                tw-flex tw-items-center tw-justify-center tw-gap-4
              "
            >
              <span
                className="
                  tw-w-12 tw-h-2 tw-bg-white tw-rounded-full
                  tw-inline-flex tw-items-center tw-justify-center
                "
              />
              <span
                className="
                  tw-size-3 tw-bg-white tw-rounded-full
                  tw-inline-flex tw-items-center tw-justify-center
                "
              />
            </div>
            <div
              className="
                tw-bg-[#128c7e] tw-min-h-[3.75rem] tw-p-3
                tw-flex tw-items-center tw-justify-start tw-flex-shrink-0

              "
            >
              <span
                className="
                  tw-text-white tw-font-semibold tw-text-sm/5
                  tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap
                "
              >
                { previewMsg.title }
              </span>
            </div>
            <div
              className="
                tw-mt-5 tw-ml-12 tw-mr-4 tw-mb-5 tw-p-2
                tw-bg-[#dcf8c7] tw-rounded-md
                tw-overflow-y-auto tw-overflow-x-hidden
                tw-text-sm/5 tw-font-normal tw-text-black
              "
            >
              { previewMsg.body }
            </div>
          </div>
        )
      }
    </div>
  );
}