import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { HiChevronUp, HiChevronDown, HiOutlineTag, HiOutlineCube, HiOutlineRefresh, HiOutlinePlus, HiOutlineClipboard, HiOutlineClipboardCheck, HiOutlineTruck } from 'react-icons/hi';
import { HiOutlineChatBubbleBottomCenter } from 'react-icons/hi2';
import { TbBrandWhatsapp } from 'react-icons/tb';

import { classnames, sakBaseUrl, copyToClipBoard, timestampToString } from '../../../../utils/usefulFunctions';
import Tooltip from '../../../Tooltip';
import { TabSelector } from './ProfileTab';

const arrayStatus =  {
  'pending': 'Pendente',
  'paid': 'Pago',
  'abandoned': 'Abandonado',
  'order_cancelled': 'Cancelado',
  'order_refunded': 'Reembolso',
  'lead_convertido': 'Lead Convertido',
  'leads': 'Lead',
};

const gatewayImage = {
  'mercado_pago': 'image/gateways/mercadopago.svg',
  'mercado_pago_boleto': 'image/gateways/mercadopago.svg',
  'mercado_pago_cc': 'image/gateways/mercadopago.svg',
  'mercadopago': 'image/gateways/mercadopago.svg',
  'mercado_pago_transparente': 'image/gateways/mercadopago.svg',
  'pag_seguro': 'image/gateways/uol.svg',
  'pagseguro': 'image/gateways/uol.svg',
  'checkout_moip': 'image/gateways/moip.svg',
  'pagbrasil': 'image/gateways/pagbrasil.svg',
  'ebanx': 'image/gateways/ebanx.svg',
  'cartão_de_crédito_e_boleto_via': 'image/gateways/ebanx.svg',
  'cartão_de_crédito_e_boleto_via_ebanx': 'image/gateways/ebanx.svg',
  'paypal': 'image/gateways/paypal.svg',
  'guru': 'image/gateways/guru.svg',
  'paghiper': 'image/gateways/paghiper_m.jpg',
  'cielo': 'image/gateways/cielo_m.jpg',
  'desconhecido': 'image/gateways/questionmark.svg',
  'xekoutme': 'image/gateways/xekoutme.svg',
  'cloudfox': 'image/gateways/cloudfox.svg',
  'hotmart': 'image/gateways/hotmart.svg',
  'yampi': 'image/gateways/yampi.svg',
  'appmax': 'image/gateways/appmax.svg',
  'appmax_cc': 'image/gateways/appmax.svg',
  'appmax_boleto': 'image/gateways/appmax.svg',
  'uol': 'image/gateways/uol.svg',
  'kiwi': 'image/gateways/kiwi.svg',
};

const paymentMethodImage = {
  'boleto': 'image/gateways/boleto.svg',
  'pix': 'image/gateways/pix.svg',
  'credit_card': 'image/gateways/credicard.svg',
  'desconhecido': 'image/gateways/desconhecido.svg',
  'paypal': 'image/gateways/paypal.svg'
};

const rastreioNetAttribs = {
  'trackTransportadora': 'Código Transportadora',
  'trackPadrao': 'Código Rastreio',
  'transportadora': 'Transportadora',
  'dataPostagem': 'Data Postagem',
  'dataEntrega': 'Data Entrega',
  'numEmailEnviados': 'E-mails Enviados',
  'ultimoStatus': 'Último Status',
  // 'badge': 'Status'
}

function CopyButton ({
  text,
  className,
  onClick,
  children,
  timeout = 2000,
  ...props
}) {
  const [checked, setChecked] = useState(false);

  const handleCopy = () => {
    copyToClipBoard(text || children || '');
    setChecked(true);

    if (typeof onClick === 'function')
      onClick();
  }

  useEffect(() => {
    if (checked) {
      const timeoutHandle = setTimeout(() => setChecked(false), timeout || 2000);
      return () => clearTimeout(timeoutHandle);
    }
  }, [checked, timeout]);

  const Icon = checked
    ? HiOutlineClipboardCheck : HiOutlineClipboard;

  return (
    <Icon className={classnames(className, 'tw-cursor-pointer tw-inline')} onClick={handleCopy} {...props} />
  )
}

function OrderStatusBadge ({
  status_pagamento,
  className = ''
}) {
  const { t } = useTranslation();

  if (className) className += ' ';
  else className = '';

  switch (status_pagamento) {
    case 'pending':
      className = 'tw-bg-yellow-100 tw-text-yellow-800 dark:tw-bg-yellow-700/60 dark:tw-text-yellow-100';
      break;
    case 'abandoned':
      className = 'tw-bg-gray-100 tw-text-gray-600 dark:tw-bg-gray-700/80 dark:tw-text-gray-100';
      break;
    case 'order_cancelled':
      className = 'tw-bg-red-100 tw-text-red-700 dark:tw-bg-red-700/60 dark:tw-text-red-100';
      break;
    case 'paid':
    case 'order_refunded':
    case 'lead_convertido':
      className = 'tw-bg-green-100 tw-text-green-700 dark:tw-bg-green-700/60 dark:tw-text-green-100';
      break;
    case 'leads':
    default:
      className = 'tw-bg-blue-100 tw-text-blue-700 dark:tw-bg-blue-700/60 dark:tw-text-blue-100';
      break;
  }

  return (
    <span
      className={classnames(className, 'tw-rounded-full tw-px-1.5 tw-py-[2.5px] tw-text-xs tw-font-medium')}
    >
      { t(arrayStatus[status_pagamento]) }
    </span>
  );
}

function useExtraInfoPedidoExtensao (order) {
  let queryUrl = null;

  if (order && order.plataforma && order.orderid) {
    const queryParams = new URLSearchParams({
      tokenchat: window.sakChatToken,
      chave_empreender: window.sakChatChave,
      order_id: order.orderid,
      provider: order.plataforma
    });

    queryUrl = sakBaseUrl('/Js/GetExtraInfoPedidoExtensaoV2?') + queryParams.toString();
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 600_000
  });

  let extraInfo = data?.data || {};
  if (extraInfo?.line_items?.length && order?.line_items?.length) {
    extraInfo.line_items = extraInfo.line_items.map(eItem => {
      const oProduct = order.line_items.find(oItem => {
        return oItem.product_id === eItem.product_id || oItem.name === eItem.name;
      });

      if (oProduct)
        eItem.image_urls = oProduct.image_urls;

      return eItem;
    });
  }

  return {
    data: extraInfo,
    isLoading,
    isValidating,
    error,
    mutate
  };
}

export function useMensagensExtensao () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave
  });

  if (window.sakChatIsMeta)
    queryParams.append('official_only', 1);

  const queryUrl = sakBaseUrl('/Js/GetMensagensExtensaoV2?') + queryParams.toString();

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60_000
  });

  return {
    data: data?.data || {},
    isLoading,
    error,
    mutate
  };
}

export function enviarMensagemExtensao (msgId, orderId) {
  const formParams = new FormData();

  formParams.append('tokenchat', window.sakChatToken);
  formParams.append('chave_empreender', window.sakChatChave);
  formParams.append('wapp_id', window.sakChatLoja);
  formParams.append('chat_id', window.sakChatContato);
  formParams.append('msg_id', msgId);
  formParams.append('order_id', orderId);

  fetch(sakBaseUrl('/Js/EnviarMensagemExtensaoV2'), {
    method: 'POST',
    body: formParams
  })
    .then(res => res.json())
    .then(data => {
      if (data.status !== 'success')
        return window.notifChat((
          <>
            <strong>Erro ao enviar mensagem</strong>
            <br/>
            <small>{ data.message }</small>
          </>
        ), 'error');

      if (data.data.action === 'send')
        return window.notifChat('Enviando mensagem', '');

      const messageInputEl = document.getElementById('chat-id-1-input');
      if (!messageInputEl) return;

      const valueSetter = Object.getOwnPropertyDescriptor(messageInputEl.constructor.prototype, 'value').set;
      valueSetter.call(messageInputEl, data.data.message);
      messageInputEl.dispatchEvent(new Event('input', { bubbles: true }));
      messageInputEl.focus();
    })
    .catch(_err => {
      window.notifChat((
        <>
          <strong>Erro ao enviar mensagem</strong>
          <br/>
          <small>{ _err }</small>
        </>
      ), 'error');
    });
}

export default function OrdersTab ({
  orders,
  rastreioNet
}) {
  const { t } = useTranslation();

  const [orderIndex, serOrderIndex] = useState(0);
  const [previewMsg, setPreviewMsg] = useState(null);
  const [collapseMessages, setCollapseMessages] = useState(!!window.sakChatIsMeta);
  const messagesContainerRef = useRef(null);

  const { data: messages_data, isLoading: messages_isLoading } = useMensagensExtensao();

  useEffect(() => {
    if (orderIndex > 0 && orderIndex > orders.length - 1)
      serOrderIndex(0);
  }, [orders, orderIndex]);

  const order = orders?.[orderIndex];
  
  const {
    data: extraInfo_data,
    isValidating: extraInfo_isValidating,
    mutate: extraInfo_mutate
  } = useExtraInfoPedidoExtensao(order);

  if (!order) {
    return (
      <div>
        <h6>{ t('Nenhum pedido encontrado') }</h6>
      </div>
    );
  }

  const trackingInfo = rastreioNet?.[order?.orderid]?.[order?.tracking];
  const line_items = extraInfo_data.line_items || order.line_items;
  const currency = extraInfo_data.currency || order.currency;
  const total_price = extraInfo_data.total_price || order.total_price;
  const total_tax = extraInfo_data.total_tax || order.total_tax;
  const total_discount = extraInfo_data.total_discount || order.total_discount;

  return (
    <div className="tw-flex tw-flex-col">
      {
        orders?.length > 1 && (
          <div className="tw-flex tw-gap-2 tw-mb-5 tw-flex-wrap">
            {
              orders.map((order, index) => (
                <TabSelector
                  key={'order_tab_' + (order.id ?? index)}
                  index={index}
                  onClick={() => serOrderIndex(index)}
                  className={index === orderIndex && 'active'}
                >
                  { order.order_name || '?' }
                </TabSelector>
              ))
            }
          </div>
        )
      }

      <div className="tw-flex tw-flex-col">
        <div
          className="
            tw-flex tw-justify-between tw-items-center tw-gap-2
            tw-p-2 tw-rounded-md
            tw-bg-west-bar dark:tw-bg-west-bar-dark
          "
        >
          <span
            className="
              tw-flex tw-gap-2 tw-items-center
              tw-text-gray-900 dark:tw-text-white
            ">
            <HiOutlineChatBubbleBottomCenter className="tw-size-4 tw-text-gray-400" />
            { t('Mensagens rápidas') }
          </span>


          <span
            className="tw-text-gray-400 tw-cursor-pointer"
            onClick={() => setCollapseMessages(!collapseMessages)}
          >
            {
              collapseMessages
                ? (<HiChevronDown className="tw-size-5" />)
                : (<HiChevronUp className="tw-size-5" />)
            }
          </span>
        </div>

        <div
          ref={messagesContainerRef}
          className="
            tw-mt-3 tw-px-2
            tw-flex tw-items-center tw-flex-wrap tw-gap-2
          "
        >
          {
            !collapseMessages && (
              messages_isLoading
                ? (
                  <span>
                    <div className="spinner-border" role="status">
                      <span className="sr-only">{t('Carregando...')}</span>
                    </div>
                  </span>)
                : (
                  <>
                    {
                      messages_data?.mensagens?.map(msg => (
                        <span
                          key={'msg_' + msg.id}
                          className="tw-cursor-pointer"
                          onMouseOver={() => setPreviewMsg({ body: msg.texto, title: msg.nome })}
                          onMouseOut={() => setPreviewMsg(null)}
                          onClick={() => enviarMensagemExtensao(msg.id, order.id)}
                        >
                          <TbBrandWhatsapp className="tw-size-8 tw-text-gray-400" />
                        </span>
                      ))
                    }
                    <a
                      className="
                        tw-flex tw-items-center tw-justify-center
                        tw-opacity-75
                        tw-border-2 tw-rounded-full tw-p-1.5
                        tw-border-dashed tw-border-gray-400
                      "
                      href={sakBaseUrl("/mensagens#tab-extensao")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HiOutlinePlus className="tw-size-4 tw-text-gray-400" />
                    </a>
                  </>
                )
            )
          }
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-mt-3">
        <div
          className="
            tw-flex tw-justify-between tw-items-center tw-gap-2
            tw-p-2 tw-rounded-md
            tw-bg-west-bar dark:tw-bg-west-bar-dark
          "
        >
          <span
            className="
              tw-flex tw-gap-2 tw-items-center
              tw-text-gray-900 dark:tw-text-white
            ">
            <HiOutlineTag className="tw-size-4 tw-text-gray-400" />
            { t('Detalhes do pedido') }
          </span>
        </div>

        <div
          className="
            tw-mt-2 tw-pl-2 tw-flex tw-flex-col tw-gap-2
            tw-text-sm/6
            [overflow-wrap:anywhere]
          "
        >
          <div className="tw-flex tw-gap-1 tw-items-center">
            <span>            
              <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium">{ t('Pedido') }</span>
              <span className="tw-text-gray-600 dark:tw-text-neutral-300 tw-capitalize tw-ml-1">
                {
                  order.link_pedido
                    ? (
                      <a
                        href={order.link_pedido}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        { order.plataforma }: { order.order_name }
                      </a>
                    )
                    : (
                      <>{ order.plataforma }: { order.order_name }</>
                    )
                }
              </span>
            </span>
            <Tooltip text={t('Copiar')}>
              <CopyButton className="tw-size-4 tw-flex-shrink-0" text={order.order_name} />
            </Tooltip>
          </div>

          <div className="tw-flex tw-gap-1 tw-items-center">
            <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium tw-flex-shrink-0">{ t('Nome') }</span>
            <span className="tw-text-gray-600 dark:tw-text-neutral-300 tw-capitalize">
              { order.customer?.name }
            </span>
            {
              order.customer?.name && (
                <Tooltip text={t('Copiar')}>
                  <CopyButton className="tw-size-4 tw-flex-shrink-0" text={order.customer.name} />
                </Tooltip>
              )
            }
          </div>

          <div className="tw-flex tw-gap-1 tw-items-center">
            <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium tw-flex-shrink-0">{ t('Data') }</span>
            <span className="tw-text-gray-600 dark:tw-text-neutral-300 tw-flex-shrink">
              { timestampToString(order.created_at) }
            </span>
            <Tooltip text={t('Copiar')}>
              <CopyButton className="tw-size-4 tw-flex-shrink-0" text={timestampToString(order.created_at)} />
            </Tooltip>
          </div>

          <div className="tw-flex tw-gap-1 tw-items-center">
            <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium tw-flex-shrink-0">{ t('Atualização') }</span>
            <span className="tw-text-gray-600 dark:tw-text-neutral-300 tw-flex-shrink">
              { timestampToString(order.updated_at) }
            </span>
            <Tooltip text={t('Copiar')}>
              <CopyButton className="tw-size-4 tw-flex-shrink-0" text={timestampToString(order.updated_at)} />
            </Tooltip>
          </div>

          <div className="tw-flex tw-gap-1 tw-items-center">
            <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium tw-flex-shrink-0">{ t('Telefone') }</span>
            <span className="tw-text-gray-600 dark:tw-text-neutral-300 tw-flex-shrink">
              { order.customer?.phone }
            </span>
            {
              order.customer?.phone && (
                <Tooltip text={t('Copiar')}>
                  <CopyButton className="tw-size-4 tw-flex-shrink-0" text={order.customer.phone} />
                </Tooltip>
              )
            }
          </div>

          <div className="tw-flex tw-gap-1 tw-items-center">
            <span>
              <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium tw-text-nowrap">{ t('E-mail') }</span>
              <span className="tw-text-gray-600 dark:tw-text-neutral-300 tw-flex-shrink tw-ml-1">
                { order.customer?.email }
              </span>
            </span>
            {
              order.customer?.email && (
                <Tooltip text={t('Copiar')}>
                  <CopyButton className="tw-size-4 tw-flex-shrink-0" text={order.customer.email} />
                </Tooltip>
              )
            }
          </div>

          <div className="tw-flex tw-gap-1 tw-items-center">
            <span>
              <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium">{ t('Endereço') }</span>
              <span className="tw-text-gray-600 dark:tw-text-neutral-300 tw-flex-shrink tw-ml-1">
                { order.customer?.formatted_address }
              </span>
            </span>

            {
              order.customer?.formatted_address && (
                <Tooltip text={t('Copiar')}>
                  <CopyButton className="tw-size-4 tw-flex-shrink-0" text={order.customer.formatted_address} />
                </Tooltip>
              )
            }
          </div>

          <div className="tw-flex tw-gap-1 tw-items-center tw-flex-wrap">
            <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium tw-flex-shrink-0">{ t('Status de pagamento') }</span>
            <span className="tw-text-gray-600 dark:tw-text-neutral-300 tw-flex-shrink tw-flex tw-flex-wrap tw-gap-1 tw-items-center">
              <OrderStatusBadge status_pagamento={order.status_pagamento} />

              {
                order.checkout_url && order.status_pagamento === 'abandoned' && (
                  <a href={order.checkout_url} target="_blank" rel='noreferrer'>
                    <img src={sakBaseUrl('image/gateways/cart.svg')} width='38' alt='cart'/>
                  </a>
                )
              }

              {
                order.gateway && (
                  <span className='link-payments mr-1' title={order.gateway}>
                    <img
                      width='38'
                      className='logo-payments'
                      src={sakBaseUrl(gatewayImage[order.gateway] || gatewayImage['desconhecido'])}
                      alt={ t(order.gateway) }
                    />
                  </span>
                )
              }

              {
                order.forma_pagamento && (
                  order.forma_pagamento === 'boleto'
                    ? (
                      <>
                        <a className='link-payments mr-1 d-flex align-items-center' href={order.link_boleto} target='_blank' rel='noreferrer'>
                          <img
                            width='38'
                            className='logo-payments'
                            src={sakBaseUrl(paymentMethodImage[order.forma_pagamento] || paymentMethodImage['desconhecido'])}
                            alt={ t(order.forma_pagamento) }
                          />
                        </a>

                        <Tooltip text={t('Copiar')}>
                          <CopyButton className="tw-size-4 tw-flex-shrink-0" text={order.link_boleto} />
                        </Tooltip>
                      </>
                    )
                    : (
                      <span className='link-payments mr-1'>
                        <Tooltip text={ t(order.forma_pagamento) }>
                          <img
                            width='38'
                            className='logo-payments'
                            src={sakBaseUrl(paymentMethodImage[order.forma_pagamento] || paymentMethodImage['desconhecido'])}
                            alt={ t(order.forma_pagamento) }
                          />
                        </Tooltip>
                      </span>
                    )
                )
              }
            </span>
          </div>
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-mt-3">
        <div
          className="
            tw-flex tw-justify-between tw-items-center tw-gap-2
            tw-p-2 tw-rounded-md
            tw-bg-west-bar dark:tw-bg-west-bar-dark
          "
        >
          <span
            className="
              tw-flex tw-gap-2 tw-items-center
              tw-text-gray-900 dark:tw-text-white
            ">
            <HiOutlineCube className="tw-size-4 tw-text-gray-400" />
            { t('Produtos') }
          </span>

          <span className=''>
            <Tooltip text={t('Recarregar')}>
              <HiOutlineRefresh
                className={classnames('tw-size-5 tw-cursor-pointer tw-text-gray-400 -tw-rotate-180', { 'tw-animate-spin': extraInfo_isValidating })}
                onClick={() => !extraInfo_isValidating && extraInfo_mutate()}
              />
            </Tooltip>
          </span>
        </div>

        <div
          className="
            tw-flex tw-flex-col tw-gap-2 tw-mt-2 tw-px-2
          "
        >
          {
            line_items?.length > 0
              ? line_items?.map((item, pIndex) => (
                <div
                  key={ 'product_' + (item.product_id ?? pIndex) }
                  className="tw-flex tw-gap-2"
                >
                  <div className="tw-flex tw-gap-2">
                    {
                      item.image_urls?.map((img, iIndex) => {
                        return (
                          <img
                            className="tw-size-7 tw-rounded-md"
                            key={ 'product_' + (item.product_id ?? pIndex) + '_' + iIndex }
                            src={img}
                            onError={(e) => e.target.src = sakBaseUrl('/image/sem-imagem.svg')}
                            alt="produto_img"
                          />
                        )
                      })
                    }
                  </div>

                  <div className="tw-flex tw-items-center">
                    <span className="tw-text-sm/6 tw-text-gray-900 dark:tw-text-white tw-font-medium">
                      { item.name || item.title }
                    </span>

                    <span className="tw-text-gray-600 dark:tw-text-neutral-300 tw-ml-1 tw-text-xs">
                      {
                        item.variant_title && (
                          <span>
                            { t('Variante') }: { item.variant_title }
                          </span>
                        )
                      }

                      {
                        (item.price > 0 || item.quantity > 0) && (
                          <span>
                            { item.quantity > 0 && (item.quantity + ' un ') }
                            { item.price > 0 && (currency + ' ' + item.price) }
                          </span>
                        )
                      }

                      {
                        total_discount > 0 && (
                          <span>
                            { t('Desconto') }: { currency } { total_discount }
                          </span>
                        )
                      }
                    </span>
                  </div>
                </div>
              ))
              : (
                <span className="tw-text-sm tw-text-gray-600 dark:tw-text-neutral-300">{ t('Nenhum produto associado a este pedido') }</span>
              )
          }
        </div>

        <div
          className="
            tw-px-2 tw-mt-2
            tw-flex tw-flex-wrap tw-gap-2 
            [&>*]:tw-flex-grow
          "
        >
          <span className="tw-text-gray-600 dark:tw-text-neutral-300">
            <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium tw-mr-1">{ t('Frete') }</span>
            {
              total_tax >= 0
                ? `${currency} ${total_tax}`
                : t('Indisponível')
            }
          </span>
          <span className="tw-text-gray-600 dark:tw-text-neutral-300">
            <span className="tw-text-gray-900 dark:tw-text-white tw-font-medium tw-mr-1">{ t('Total') }</span>
            { currency } { total_price }
          </span>
        </div>

        <div className="tw-flex tw-flex-col tw-mt-2">
          <div className="tw-mx-2 tw-text-gray-900 dark:tw-text-white tw-text-sm/6">
            {
              order.tracking
                ? (
                  <a
                    className="tw-flex tw-items-center tw-text-indigo-600 dark:tw-text-indigo-400"
                    href={'https://rastreio.net/' + order.tracking.split(',')[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HiOutlineTruck className="tw-size-4 tw-mr-1" />
                    Rastreio.net
                  </a>
                )
                : (
                  <span
                    className="tw-flex tw-items-center"
                  >
                    <HiOutlineTruck className="tw-size-4 tw-mr-1" />
                    Rastreio.net
                  </span>
                )
            }
          </div>
    
          <div className="tw-mt-2 tw-px-2">
            {
              order.status_pagamento === 'paid' && trackingInfo
                ? (
                  <div className="tw-flex tw-flex-col">
                    {
                      Object.entries(trackingInfo).map(([aName, aValue]) => {
                        if (!rastreioNetAttribs[aName]) return null;

                        return (
                          <div
                            className="tw-text-gray-600 dark:tw-text-neutral-300 tw-text-sm"
                          >
                            <span className="tw-font-medium tw-text-gray-900 dark:tw-text-white tw-mr-1">
                              { t(rastreioNetAttribs[aName]) }:
                            </span>

                            { aValue }
                          </div>
                        )
                      })
                    }
                  </div>
                )
                : (
                  order?.tracking
                    ? (
                      <div className="tw-text-gray-600 dark:tw-text-neutral-300 tw-text-sm">
                        <span className="tw-font-medium tw-text-gray-900 dark:tw-text-white tw-mr-1">
                          { t('Código') }:
                        </span>

                        { order.tracking.split(',').map(tracking => tracking.trim()).join(', ') }
                      </div>
                    )
                    : (
                      <span className="tw-text-sm tw-text-gray-600 dark:tw-text-neutral-300">{ t('Sem informações de rastreio associadas a este pedido') }</span>
                    )
                )
            }
          </div>
        </div>
      </div>

      {
        previewMsg && (
          <div
            className="
              tw-fixed tw-bottom-0 tw-right-0 tw-w-full
              tw-bg-white
              tw-border-[1rem] tw-border-b-0 tw-border-white tw-rounded-t-[2.5rem]
              tw-overflow-y-auto tw-overflow-x-hidden
              tw-shadow-[0px_1px_4px_rgb(185,_210,_238)]
            "
            style={{
              maxHeight: window.innerHeight - messagesContainerRef.current?.getBoundingClientRect().bottom,
              background: `url('https://app.sak.com.br/image/whats_back.png')`
            }}
          >
            <div
              className="
                tw-bg-[#075e54] tw-h-12 tw-p-2
                tw-flex tw-items-center tw-justify-center tw-gap-4
              "
            >
              <span
                className="
                  tw-w-12 tw-h-2 tw-bg-white tw-rounded-full
                  tw-inline-flex tw-items-center tw-justify-center
                "
              />
              <span
                className="
                  tw-size-3 tw-bg-white tw-rounded-full
                  tw-inline-flex tw-items-center tw-justify-center
                "
              />
            </div>
            <div
              className="
                tw-bg-[#128c7e] tw-min-h-[3.75rem] tw-p-3
                tw-flex tw-items-center tw-justify-start tw-flex-shrink-0

              "
            >
              <span
                className="
                  tw-text-white tw-font-semibold tw-text-sm/5
                  tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap
                "
              >
                { previewMsg.title }
              </span>
            </div>
            <div
              className="
                tw-mt-5 tw-ml-12 tw-mr-4 tw-mb-5 tw-p-2
                tw-bg-[#dcf8c7] tw-rounded-md
                tw-overflow-y-auto tw-overflow-x-hidden
                tw-text-sm/5 tw-font-normal tw-text-black
              "
            >
              { previewMsg.body }
            </div>
          </div>
        )
      }
    </div>
  );
}
