import React, {
  useState,
  useEffect
} from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from '@headlessui/react';

import useSWR from 'swr';

import { formatPhoneNumber } from '../../../../utils/formatDados';
import { classnames, sakBaseUrl } from '../../../../utils/usefulFunctions';	
import useDadosSakStore from '../../../../stores/useDadosSakStore';

import ProfileThingie from './ProfileTab';
import OrdersThingie from './OrdersTab';
import TemplatesTab from './TemplatesTab';
import { StaticSearchBar } from '../../../../components/FormInput';

function useDadosExtensao (query, search_by_phone = 0) {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
    search_by_phone,
    query
  });

  const queryUrl = query ? sakBaseUrl('/Js/GetDadosExtensaoV2?' + queryParams.toString()) : null;

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60_000
  });

  return {
    data: data?.data || {},
    isLoading,
    error,
    mutate
  };
}

export default function HomeTab() {
  const { t } = useTranslation();

  const [actualQuery, setActualQuery] = useState(null);
  const [query, setQuery] = useState('');

  const [searchByPhone, setSearchByPhone] = useState(1);

  const [clientData, setClientData] = useState([]);
  const [leadLists, setLeadLists] = useState([]);
  const [orders, setOrders] = useState([]);
  const [rastreioNet, setRastreioNet] = useState([]);

  const [warningMessage, setWarningMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState('profile');

  const { data, isLoading, error, mutate } = useDadosExtensao(actualQuery, searchByPhone);

  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const userLogado = useDadosSakStore((state) => state.userLogado);

  function handleQuerySubmit (event) {
    event.preventDefault();
    setSearchByPhone(0);
    setActualQuery(query || null);
  }

  async function saveClientInfo (index) {
    if (!clientData[index]) {
      setWarningMessage('Erro ao salvar dados');
      return;
    }

    clientData[index].buscaInicial = actualQuery || query || '';

    const mutatedData = { data: { ...data, client_info: clientData } };
    const mutationOptions = {
      optimisticData: mutatedData,
      populateCache: true
    };

    const dataFunction = async () => {
      const queryUrl = sakBaseUrl('/Js/PostClienteExtensaoV2');

      const formData = new FormData();
      formData.append('tokenchat', window.sakChatToken);
      formData.append('chave_empreender', window.sakChatChave);
      formData.append('data', JSON.stringify(clientData[index]));
      formData.append('chat_id', contatoAtivo.chatid);

      const res = await fetch(queryUrl, { method: 'POST', body: formData });
      const data = await res.json();

      if (data.status !== 'success')
        throw new Error(data.message || 'Erro ao salvar dados');

      mutatedData.data.client_info[index] = data.data[0];

      return mutatedData;
    };

    await mutate(dataFunction, mutationOptions)
      .catch(err => {
        setWarningMessage(err.message || 'Erro ao salvar dados');
      });
  }

  function handleTabChange (event, tab) {
    event.preventDefault();
    setSelectedTab(tab);
  }

  useEffect(() => {
    let formatted = '+' + contatoAtivo.chatid.split('@')[0].toString();
    formatted = formatPhoneNumber(formatted);

    setQuery(formatted);
    setSearchByPhone(1);
    setActualQuery(formatted || null);

  // eslint-disable-next-line
  }, [contatoAtivo?.chatid, userLogado]);

  useEffect(() => {
    if (isLoading) return;

    setClientData(data?.client_info || []);
    setLeadLists(data?.lead_lists || []);
    setOrders(data?.orders || []);
    setRastreioNet(data?.rastreio_net || []);

  }, [data, isLoading]);

  useEffect(() => {
    if (warningMessage) {
      setTimeout(() => {
        setWarningMessage('');
      }, 5000);
    }
  }, [warningMessage]);

  function TabContent () {
    if (selectedTab === 'profile')
      return (
        <ProfileThingie
          client_info={clientData}
          saveClientInfo={saveClientInfo}
          setClientData={setClientData}
          leadLists={leadLists}
          warningMessage={warningMessage}
        />
      );

    if (selectedTab === 'templates')
      return (
        <div>
          <TemplatesTab />
        </div>
      );

    return (
      <OrdersThingie
        orders={orders}
        rastreioNet={rastreioNet}
      />
    );
  }

  if (isLoading) return (
    <div className='d-flex h-100 align-items-center justify-content-center'>
      <div className="spinner-border" role="status">
        <span className="sr-only">{t('Carregando...')}</span>
      </div>
    </div>
  );

  if (error) return (
    <div className='d-flex h-100 align-items-center justify-content-center'>
      <div className="alert alert-danger" role="alert">
        {t('Erro ao carregar dados.')}
      </div>
    </div>
  );

  return (
    <div
      className="
        tw-pt-5
        tw-min-h-0
        tw-flex tw-flex-col tw-flex-1
      "
    >
      {/* <div
        className="
          tw-flex tw-flex-col tw-gap-2
          tw-mx-5 tw-mb-5
        "
      >
        <span className="tw-text-base tw-font-semibold tw-text-gray-900 dark:tw-text-white">{ t('') }</span>
        <span className="tw-text-sm tw-font-normal tw-text-gray-600 dark:tw-text-gray-400">
          { t('') }
          <a
            className="
              before:tw-content-['_']
              after:tw-content-['↗']
              tw-text-active dark:tw-text-active-dark
            "
            href="https://suporte.sak.com.br/faq/"
            target="_blank"
            rel="noreferrer"
          >
            { t('Ajuda') }
          </a>
        </span>
      </div> */}

      <div className="tw-mx-5">
        <form onSubmit={handleQuerySubmit} >
          <StaticSearchBar
            value={query}
            onChange={e => setQuery(e.target.value)}
            placeholder={t('Buscar pedido por nº, nome, tel ou email')}
          />
        </form>
      </div>

      {
        data?.highlighted_product && (
          <div
            className="
              tw-mx-5 tw-mt-5
              tw-rounded-md
              tw-text-sm
              tw-px-3 tw-py-2
              //tw-text-[#49c18f]
              tw-text-green-700 dark:tw-text-green-400
              tw-bg-[rgba(73,_193,_143,_.15)]
            "
          >
            {data.highlighted_product.name} (R${ data.highlighted_product.price })
          </div>
        )
      }

      <div className="tw-mx-5 tw-mt-5 tw-flex tw-gap-4">
        <Button
          className={classnames(`
            tw-rounded-md tw-py-2 tw-px-3 tw-font-semibold
            [&.active]:tw-bg-west-bar [&.active]:dark:tw-bg-west-bar-dark
            [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark
            hover:tw-bg-west-bar/60 dark:hover:tw-bg-west-bar-dark/60
            hover:tw-text-active/90 dark:hover:tw-text-active-dark/90
            focus:tw-outline-none
          `, { active: selectedTab === 'profile' })}
          onClick={e => handleTabChange(e, 'profile')}
        >
          { t('Perfil') }
        </Button>

        <Button
          className={classnames(`
            tw-rounded-md tw-py-2 tw-px-3 tw-font-semibold
            [&.active]:tw-bg-west-bar [&.active]:dark:tw-bg-west-bar-dark
            [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark
            hover:tw-bg-west-bar/60 dark:hover:tw-bg-west-bar-dark/60
            hover:tw-text-active/90 dark:hover:tw-text-active-dark/90
            focus:tw-outline-none
          `, { active: selectedTab === 'orders' })}
          onClick={e => handleTabChange(e, 'orders')}
        >
          { t('Pedidos') }
        </Button>

        {
          window.sakChatIsMeta && (
            <Button
              className={classnames(`
                tw-rounded-md tw-py-2 tw-px-3 tw-font-semibold
                [&.active]:tw-bg-west-bar [&.active]:dark:tw-bg-west-bar-dark
                [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark
                hover:tw-bg-west-bar/60 dark:hover:tw-bg-west-bar-dark/60
                hover:tw-text-active/90 dark:hover:tw-text-active-dark/90
                focus:tw-outline-none
              `, { active: selectedTab === 'templates' })}
              onClick={e => handleTabChange(e, 'templates')}
            >
              { t('Templates') }
            </Button>
          )
        }
      </div>

      <div
        className="
          tw-mx-2 tw-mt-5
          tw-pl-3 tw-pr-2
          tw-min-h-0 tw-h-full tw-overflow-y-scroll

          tw-flex tw-flex-col tw-gap-5

          [&::-webkit-scrollbar]:tw-w-1
        [&::-webkit-scrollbar-thumb]:!tw-bg-scrollbar
        [&::-webkit-scrollbar-thumb]:dark:!tw-bg-scrollbar-dark
        "
      >
        { TabContent() }
      </div>
    </div>
  );
}
