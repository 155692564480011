import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaUserCircle } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { TbTag } from "react-icons/tb";
import { MdScreenshotMonitor } from "react-icons/md";
import { HiOutlineGlobeAlt, HiOutlineLogout, HiOutlineMoon, HiOutlineUser } from "react-icons/hi";
import { HiOutlineWrench  } from 'react-icons/hi2';

import { BackgroundChat } from './styles/GlobalStyles';

import LoadApp from './LoadApp';

import Avatar from './components/avatar';
// import CountrySelect from './components/CountrySelect';
import { ErrorChat } from './components/errors';
import { Dropdown, DropdownButton, DropdownMenu, DropdownSection, DropdownItem, DropdownIcon } from './components/Dropdown';

import RespostaProvider from './hooks/responderMsgContext';
import RespostasRapidas from './hooks/rapidaMsgContext';

import Tooltip from './contents/Tooltip';
import ListaLojas from './contents/ListaLojas';
import Aside from './contents/Aside';
import CaixaMensagem from './contents/CaixaMensagem';

import useDadosSakStore from './stores/useDadosSakStore';
import useRenderStore from './stores/useRenderStore';
import useThemeStore from './stores/useThemeStore';
import useModalStore from './stores/useModalStore';

import { restringirNome } from './utils/formatDados';
import { prefetchQuickReplies } from './utils/prefetch';
import LanguageSelector from './components/lang/LanguageSelector';

function getGreetings () {
  const hours = new Date().getHours();

  if (hours >= 6 && hours < 12) return 'Bom dia';
  else if (hours >= 12 && hours < 18) return 'Boa tarde';
  else return 'Boa noite';
}

function copiarCodigo(texto) {
  if (!texto) return;
  texto = String(texto);
  if (typeof texto !== 'string') return;
  navigator.clipboard
    .writeText(texto)
    .then(() => window.notifChat('Código copiado com sucesso!', 'success'))
    .catch((_) =>
      window.notifChat('Não foi possível copiar o código!', 'warning')
    );
}

export default function Home() {
  const [tab, setTab] = useState('Todos');
  const [search, setSearch] = useState('');

  const contatos = useDadosSakStore((state) => state.contatos);
  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const mensagens = useDadosSakStore((state) => state.mensagens);
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const userChat = useDadosSakStore((state) => state.userChat);
  const lojas = useDadosSakStore((state) => state.lojas);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const marcadores = useDadosSakStore((state) => state.marcadores);

  const { t } = useTranslation();

  const lastRender = useRenderStore((state) => state.lastRender);

  const theme = useThemeStore((state) => state.theme);

  const themeDict = {
    light: t('Tema claro'),
    dark: t('Tema escuro'),
    system: t('Tema do sistema'),
  };

  useEffect(() => {
    if (
      lojas &&
      Object.values(lojas).length &&
      lojaAtivo &&
      Object.values(lojaAtivo).length
    ) {
      window.sakChatToken =
        localStorage.inicializacao &&
        JSON.parse(localStorage.inicializacao).token;
      window.sakChatChave = lojas[lojaAtivo].chave_empreender;

      prefetchQuickReplies();
    }
  }, [lojaAtivo, lojas]);

  useEffect(() => {
    window.sakChatContato = contatoAtivo?.chatid;
    window.sakChatLoja = lojas?.[lojaAtivo]?.user;
    window.sakChatIsMeta = lojas?.[lojaAtivo]?.multidevice < 0;
  }, [lojas, lojaAtivo, contatoAtivo]);

  useEffect(() => {
    // const allChats = document.querySelectorAll('#chat-tab');
    // if (!allChats || !contatoAtivo || (contatoAtivo && !contatoAtivo.chatid))
    //   return;
    // Object.values(allChats).forEach((chat) =>
    //   chat.dataset['ref'].includes(contatoAtivo.chatid)
    //     ? chat.classList.add('border-selected')
    //     : chat.classList.remove('border-selected')
    // );

    if (!contatoAtivo?.chatid) return;

    document.querySelector('.chat-tab.chat-selected')?.classList.remove('chat-selected');
    document.querySelector(`.chat-tab[data-chatid="${contatoAtivo.chatid}"]`)?.classList.add('chat-selected');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, search, contatoAtivo]);

  return (
    <>
      <ErrorChat codeError={8}>
        <LoadApp>
          <div className="layout">
            <div
              className="
                sidebar
                min-[1200px]:tw-max-w-[440px]
                min-[1200px]:tw-min-w-[400px]
                tw-bg-bar dark:tw-bg-bar-dark
                min-[1200px]:tw-border-r tw-border-seg-border dark:tw-border-seg-border-dark"
            >
              <div className="tw-flex tw-flex-row tw-h-dvh">
                <div className="tw-flex tw-flex-col tw-py-3 tw-gap-3 tw-bg-west-bar dark:tw-bg-west-bar-dark [&>*]:tw-px-3">
                  <a href="# " className="d-block">
                    <img
                      src={`${process.env.PUBLIC_URL}assets/logo.png`}
                      className="mx-auto fill-pink tw-w-10"
                      data-inject-svg=""
                      alt=""
                    />
                  </a>

                  <div
                    className="tw-flex-grow tw-pt-2 -tw-mt-2 hide-scrollbar tab-scroll"
                    // style={{ overflowY: 'overlay !important' }}
                  >
                    <ErrorChat codeError={4}>
                      <ListaLojas />
                    </ErrorChat>
                  </div>

                  <div className="tw-self-center tw-pb-1 tw-flex tw-flex-col-reverse tw-gap-2">
                    <Dropdown as="div">
                      <DropdownButton
                        as="div"
                        className="
                          w-auto tw-p-1 tw-cursor-pointer
                          tw-text-side-icons dark:tw-text-side-icons-dark
                          hover:tw-scale-105 hover:tw-brightness-95 hover:dark:tw-brightness-110
                        "
                      >
                        <Tooltip text="Perfil" position="right">
                          <FaUserCircle className="tw-w-6 tw-h-6" />
                        </Tooltip>
                      </DropdownButton>

                      <DropdownMenu
                        anchor="top start"
                        className="[--anchor-gap:1rem]"
                      >
                        <DropdownSection>
                          <DropdownItem className="tw-gap-3">
                            {
                              userLogado.userchat &&
                              userChat[userLogado.userchat] &&
                              (<Avatar data={userChat[userLogado.userchat]} />)
                            }
                            <div
                              className="
                                tw-flex tw-flex-col tw-justify-center tw-leading-5
                                tw-text-gray-900 dark:tw-text-white
                              "
                            >
                              <span className="tw-font-semibold">
                                {
                                  userLogado.userchat &&
                                  userChat[userLogado.userchat] &&
                                  restringirNome(
                                    userChat[userLogado.userchat].nome,
                                    15
                                  )
                                }
                              </span>

                              <span>
                                {
                                  userLogado.userchat &&
                                  userChat[userLogado.userchat] &&
                                  userChat[userLogado.userchat].email
                                }
                              </span>
                            </div>
                          </DropdownItem>
                        </DropdownSection>
                        <DropdownSection>
                          <DropdownItem
                            onClick={() =>
                              copiarCodigo(
                                lojaAtivo !== 0 &&
                                lojas[lojaAtivo] &&
                                lojas[lojaAtivo].chave_empreender
                              )
                            }
                          >
                            <span
                              className="tw-flex tw-flex-col tw-items-start"
                            >
                              <strong className="//tw-text-indigo-600 tw-text-active dark:tw-text-active-dark">
                                {t('Chave Empreender')}
                              </strong>
                              <div>
                                <span className="ml-auto mr-2 fe-copy" />
                                {lojaAtivo !== 0 &&
                                  lojas[lojaAtivo] &&
                                  lojas[lojaAtivo].chave_empreender}
                              </div>
                            </span>
                          </DropdownItem>
                        </DropdownSection>
                        <DropdownSection>
                          <DropdownItem
                            href="https://app.sak.com.br/atualizarUsuario"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <DropdownIcon icon={HiOutlineUser} />
                            {t('Minha Conta')}
                          </DropdownItem>
                          <DropdownItem
                            onClick={e => {
                              e.preventDefault();
                              useThemeStore.getState().cycleTheme();
                            }}
                          >
                            <DropdownIcon icon={HiOutlineMoon} />
                            { themeDict[theme] }
                          </DropdownItem>
                          <DropdownItem
                            href="https://app.sak.com.br/dashboard"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <DropdownIcon icon={HiOutlineLogout} />
                            {t('Voltar para o SAK')}
                          </DropdownItem>
                        </DropdownSection>
                      </DropdownMenu>
                    </Dropdown>

                    <Dropdown as="div">
                      <DropdownButton
                        as="div"
                        className="
                          w-auto tw-p-1 tw-cursor-pointer
                          tw-text-side-icons dark:tw-text-side-icons-dark
                          hover:tw-scale-105 hover:tw-brightness-95 hover:dark:tw-brightness-110
                        "
                      >
                        <Tooltip text="Configurações" position="right">
                          <FaGear className="tw-size-6 "/>
                        </Tooltip>
                      </DropdownButton>

                      <DropdownMenu
                        anchor="top start"
                        className="[--anchor-gap:1rem]"
                      >
                        <DropdownSection>
                          <DropdownItem
                            onClick={() => useModalStore.getState().setModal('screenshot', { loja: lojas[lojaAtivo] })}
                          >
                            <DropdownIcon icon={MdScreenshotMonitor} />
                            {t('Ver WhatsappWeb')}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => useModalStore.getState().setModal('manage_labels')}
                          >
                            <DropdownIcon icon={TbTag} />
                            {t('Gerenciar marcadores')}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => useModalStore.getState().setModal('preferences')}
                          >
                            <DropdownIcon icon={HiOutlineWrench } />
                            {t('Preferências')}
                          </DropdownItem>
                          <LanguageSelector>
                            {
                              lang => (
                                <DropdownItem onClick={e => e.preventDefault()}>
                                  <DropdownIcon icon={HiOutlineGlobeAlt } />
                                  {lang}
                                </DropdownItem>
                              )
                            }
                          </LanguageSelector>
                        </DropdownSection>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>

                <div
                  className="
                    d-flex flex-column h-100
                    tw-min-w-0 tw-flex-1
                  "
                >
                  <div className="container-fluid tw-py-2 tw-px-5">
                    <div className="d-flex justify-content-between align-items-center tw-h-[3.5rem]">
                      <div className="d-flex align-items-center mt-2 text-truncate">
                        <h2 className="text-truncate tw-text-[1.25rem] tw-leading[1.5rem] tw-font-semibold tw-text-gray-900 dark:tw-text-white">
                          {lojaAtivo && lojas[lojaAtivo]
                            ? lojas[lojaAtivo].nome
                            : 'Conversas'}
                        </h2>
                      </div>

                      {/* <div
                        className="dropdown"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <CountrySelect />
                      </div> */}
                    </div>
                  </div>
                  <ErrorChat codeError={3}>
                    {lojaAtivo && (
                      <Aside
                        contatos={contatos[lojaAtivo]}
                        mensagens={mensagens[lojaAtivo]}
                        lojaAtivo={lojaAtivo}
                        marcadores={marcadores[lojaAtivo]}
                        lojas={lojas[lojaAtivo]}
                        search={search}
                        setSearch={setSearch}
                        tab={tab}
                        setTab={setTab}
                        lastRender={lastRender}
                      />
                    )}
                  </ErrorChat>
                </div>
              </div>
            </div>
            <div className="main tw-bg-chat-area dark:tw-bg-chat-area-dark" id="main-chat" data-mobile-height>
              {contatoAtivo && Object.values(contatoAtivo).length ? (
                <RespostaProvider>
                  <RespostasRapidas>
                    <CaixaMensagem
                      // lastMessage={lastMsg ? lastMsg[lastMsg.length - 1] : ''}
                      contato={contatos[lojaAtivo]}
                      userchat={userChat}
                      mensagens={mensagens[lojaAtivo]}
                      inicial={userLogado}
                      lojas={lojas[lojaAtivo]}
                      lojaAtivo={lojaAtivo}
                      marcadores={marcadores[lojaAtivo]}
                    />
                  </RespostasRapidas>
                </RespostaProvider>
              ) : (
                <ErrorChat codeError={7}>
                  <BackgroundChat
                    display={contatoAtivo && contatoAtivo.chatid}
                  >
                    <img
                      className="dark:tw-brightness-90 tw-select-none [-webkit-user-drag:none]"
                      src={`${process.env.PUBLIC_URL}assets/criativo.webp`}
                      alt="backgroundChat"
                    />
                    <span>
                      {userLogado.userchat && userChat[userLogado.userchat]
                        ? `${t(getGreetings())}, ` +
                          userChat[userLogado.userchat].nome
                        : `${t('Carregando...')}`}
                    </span>
                    <p className="!tw-text-gray-500 dark:!tw-text-gray-400">
                      {t(
                        'Selecione uma conversa ao lado para começar'
                      )}
                    </p>
                  </BackgroundChat>
                </ErrorChat>
              )}
            </div>
          </div>
        </LoadApp>
      </ErrorChat>
    </>
  );
}
