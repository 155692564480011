import { create } from 'zustand';

const THEMES = ['system', 'light', 'dark'];

const useThemeStore = create((set) => ({
  theme: 'system',
  setTheme: (theme) => {
    set({ theme });
  },
  cycleTheme: () => {
    set((state) => {
      const currentIndex = THEMES.indexOf(state.theme);
      const nextIndex = (currentIndex + 1) % THEMES.length;
      return { theme: THEMES[nextIndex] };
    });
  }
}));

export default useThemeStore;
