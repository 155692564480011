import { useEffect } from 'react';
import useThemeStore from '../stores/useThemeStore';

function toggleThemeClasses(isDark) {
  document.body.classList.toggle('darkmode', isDark);
  document.documentElement.classList.toggle('tw-dark', isDark);
}

export default function ThemeProvider() {
  const { theme, setTheme } = useThemeStore();

  useEffect(() => {
    const preferredTheme = window.localStorage.getItem('theme');
    setTheme(preferredTheme || 'system');
  }, [setTheme]);

  useEffect(() => {
    let cleanupFunction;
    let isDark = theme === 'dark';

    if (theme === 'system') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      isDark = mediaQuery.matches;

      const mediaChangeHandler = (change) => {
        const prefersDark = change.matches;
        toggleThemeClasses(prefersDark);
      };

      mediaQuery.addEventListener('change', mediaChangeHandler);
      cleanupFunction = () => {
        mediaQuery.removeEventListener('change', mediaChangeHandler);
      };
    }

    toggleThemeClasses(isDark);
    window.localStorage.setItem('theme', theme);

    return cleanupFunction;
  }, [theme]);

  return null;
}
