import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: `
    tw-rounded-lg
    tw-border tw-border-seg-border dark:tw-border-seg-border-dark
    tw-text-side-icons dark:tw-text-side-icons-dark
    tw-font-medium tw-text-sm/6

    [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark
    [&.active]:tw-border-active [&.active]:dark:tw-border-active-dark

    data-[time-type=Customizado]:tw-mr-2
  `
})`
  cursor: pointer;
  padding: 4px 8px;
  min-height: 34px;
  min-width: 34px;
  display: flex;
  align-items: center;

  .react-datepicker__input-container {
    & > input {
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      width: 95px;
    }
  }
`;
