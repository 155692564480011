import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsPtBR from './pt-BR.json';
import translationsEs from './es.json';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (cb) => {
    const preferred_language = localStorage.getItem('preferred_language');
    const browser_language = navigator.language;

    let language;
    switch (true) {
      case !!preferred_language:
        language = preferred_language;
        break;
      case browser_language.includes('es'):
        language = 'es';
        break;
      default:
        language = 'pt-BR';
        break;
    }

    cb(language);
  },
  init: () => {},
  cacheUserLanguage: (language) => {
    localStorage.setItem('preferred_language', language);
  },
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources: {
      'pt-BR': { translation: translationsPtBR },
      es: { translation: translationsEs },
      'es-mx': { translation: translationsEs },
      'es-ar': { translation: translationsEs },
      'es-cl': { translation: translationsEs },
      'es-co': { translation: translationsEs },
    },
    // fallbackLng: JSON.parse(localStorage.getItem("user"))?.language ?? (initial_language?.code ?? (document.documentElement.lang == 'es' ? 'es-mx' : 'pt-BR')),
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    fallbackLng: "pt-BR",
  });

export default i18n;
