import { useRef, useEffect } from 'react';
import { classnames } from '../../utils/usefulFunctions';

export default function HorizontallyScrollable({ className, children, as = 'div', ...props }) {
  const container = useRef(null);

  useEffect(() => {
    if (!container.current) return;

    const containerElement = container.current;

    const handleScroll = (e) => {
      if (e.deltaY === 0) return;
      e.preventDefault();
      containerElement.scrollTo({
        left: containerElement.scrollLeft + (e.deltaY > 0 ? 20 : -20),
        // behavior: 'smooth',
      });
    };

    containerElement.addEventListener('wheel', handleScroll);

    return () => {
      containerElement.removeEventListener('wheel', handleScroll);
    };
  }, [container]);

  return (
    <as
      ref={container}
      {...props}
      className={classnames(className, 'tw-overflow-x-auto')}
    >
      {children}
    </as>
  );
}
