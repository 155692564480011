import useModalStore from '../../stores/useModalStore';

import BaseModal from './modals/BaseModal';
import ScreenshotModal from './modals/ScreenshotModal';
import PrefsModal from './modals/PrefsModal';
import AddContactModal from './modals/AddContactModal';
import JoinGroupModal from './modals/JoinGroupModal';
import ManageLabelsModal from './modals/ManageLabelsModal';
import ConfirmationModal from './modals/ConfirmationModal';
import EditMessageModal from './modals/EditMessageModal';
import FilterContactsModal from './modals/FilterContactsModal';

export default function ModalManager () {
  const modal = useModalStore((state) => state.modal);
  if (!modal) return null;

  switch (modal.name) {
    case 'base':
      return <BaseModal />;
    case 'screenshot':
      return <ScreenshotModal />;
    case 'preferences':
      return <PrefsModal />;
    case 'add_contact':
      return <AddContactModal />;
    case 'join_group':
      return <JoinGroupModal />;
    case 'manage_labels':
      return <ManageLabelsModal />;
    case 'confirmation':
      return <ConfirmationModal />;
    case 'edit_message':
      return <EditMessageModal />;
    case 'filter_contacts':
      return <FilterContactsModal />;
    default:
      return null;
  }
}
