import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@headlessui/react'

import { BiSmile, BiCalendar, BiDotsVerticalRounded } from "react-icons/bi";
import { HiOutlineUser, HiOutlineUserRemove  } from "react-icons/hi";
import { HiMiniPaperClip, HiOutlineArchiveBoxArrowDown, HiOutlineChevronDown, HiMagnifyingGlass } from "react-icons/hi2";
import { PiImageFill } from "react-icons/pi";
import { FiSend } from "react-icons/fi";
import { TbTag } from "react-icons/tb";

import CuteTag from '../Contato/components/CuteTag';

import { ReactComponent as AddTag } from '../../assets/add_tag.svg'
import { ReactComponent as UserOff } from '../../assets/user_off.svg'
import ChatAssistant from './components/ChatAssistant';
import FAQAssistant from './components/ChatAssistant/FAQAssistant';

import {
  ListAtends,
  // EncerrarConversa,
  AtualAtendente,
  // Assumir,
  // Tabs,
  Anotacao,
  TextArea,
  AvatarEdit,
  Marcador,
} from './styles';

import Conversa from '../Conversa';

import SideExtension, { toggleSidebar } from '../SideExtension';

import 'emoji-mart/css/emoji-mart.css';
import listaEmojis from 'emoji-mart/data/twitter.json';
import { NimblePicker } from 'emoji-mart';

import Tag from '../Tags';

import { ErrorChat } from '../../components/errors';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { restringirNome, formatPhoneNumber } from '../../utils/formatDados';

import { Dropdown, DropdownButton, DropdownMenu, DropdownSection, DropdownItem, DropdownIcon } from '../../components/Dropdown';
import HorizontallyScrollable from '../../components/HorizontallyScrollable';
import Avatar from '../../components/avatar';
import Tooltip from '../Tooltip';

// import { gamification } from '../../utils/gamification';

import { useResposta } from '../../hooks/responderMsgContext';
// import RespostasRapidas from '../../hooks/rapidaMsgContext';
import { useMensagemRapida } from '../../hooks/rapidaMsgContext';
import DelayMessage from './components/DelayMessage';
import RecordAudio from './components/RecordAudio';
import useDadosSakStore from '../../stores/useDadosSakStore';
import useSocketStore from '../../stores/useSocketStore';
import useModalStore from '../../stores/useModalStore';
import ImageRepository from './components/ImageRepository/index';
import { useTranslation } from 'react-i18next';
import { classnames } from '../../utils/usefulFunctions';

const translateEmojis = {
  search: 'Pesquisar emoji',
  clear: 'Limpar',
  notfound: 'Emoji não encontrado!',
  categories: {
    search: 'Resultados',
    recent: 'Recentes',
    people: 'Smileys & Pessoas',
    nature: 'Animais & Natureza',
    foods: 'Comidas e Bebidas',
    activity: 'Atividades',
    places: 'Viagens e Lugares',
    objects: 'Objetos',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
    custom: 'Custom',
  },
};

const CaixaMensagem = ({
  contato,
  userchat,
  mensagens,
  inicial,
  marcadores,
  lastMessage,
  lojas,
}) => {
  const [tab, setTab] = useState('Publica');
  // const { sendSocketDados } = useSocket();
  const { t, i18n } = useTranslation();
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  const delayMessage = useSocketStore((state) => state.delayMessage);

  const textAreaSendMessage = useRef([]);
  const { idMessage, setIdMessage } = useResposta();
  const { setTextMessage } = useMensagemRapida();
  // const { lojaAtivo } = useLojaAtiva();
  // const { userLogado } = useUserLogado();
  // const { contatoAtivo, setContatoAtivo } = useContatoAtivo();
  const [attFotoTime, setAttFotoTime] = useState(0);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showDelayMessage, setShowDelayMessage] = useState(false);
  const [showRepositoryBox, setshowRepositoryBox] = useState(false);
  const inputTextRef = useRef(null);
  const emojiRef = useRef(null);
  // const emojiBtn = useRef(null);

  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const setContatoAtivo = useDadosSakStore(
    (state) => state.setContatoAtivoStore
  );
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);

  useEffect(() => {
    if (!showEmojis) return;

    const handleCloseEmoji = (event) =>
      event.keyCode === 27 && setShowEmojis(false);

    document.addEventListener('keyup', handleCloseEmoji);

    return () => {
      document.removeEventListener('keyup', handleCloseEmoji);
    };
  }, [showEmojis]);

  useEffect(() => {
    typeof window.inicializarFuncaoTemplate === 'function' &&
      window.inicializarFuncaoTemplate(window.jQuery);

    // typeof window.submitdado === 'function' &&
    //   window.submitdado({}, `${window.SakChatUrl}/Js/loadBoxChatSak`, () => {
    //     window.submitdado({}, `${window.SakChatUrl}/Js/getShortcut`);
    //   });
    const findExtensao = document.querySelector('#chat-1-info');

    findExtensao &&
      window.innerWidth < 1200 &&
      findExtensao.classList.contains('chat-sidebar-visible') &&
      findExtensao.classList.remove('chat-sidebar-visible');
  }, [lojaAtivo]);

  useEffect(() => {
    const handleTextAreaSeparatedMessages = () => {
      const textAreaInput = document.getElementById('chat-id-1-input');

      if (textAreaInput) {
        textAreaInput.value =
          textAreaSendMessage.current[`Conversa-${contatoAtivo.chatid}`] || '';
      }
    };
    handleTextAreaSeparatedMessages();

    if (!contatoAtivo.texto) return;
    const inputState = inputTextRef ? inputTextRef.current : '';
    if (!inputState) return;
    inputState.value = contatoAtivo.texto;
    delete contatoAtivo.texto;
    setContatoAtivo(() => contatoAtivo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contatoAtivo]);

  useEffect(() => {
    let numeroContato = '+' + contatoAtivo.chatid.split('@')[0].toString();
    let cancelar = {};
    if (typeof window.submitdado === 'function') {
      cancelar = window.submitdado(
        { q: formatPhoneNumber(numeroContato), use_phone: 1 },
        `${window.SakChatUrl}/Js/SFBuscaInfo/navapps`
      );
    }
    return () => {
      if (typeof cancelar === 'function') {
        cancelar();
      }
    };
  }, [contatoAtivo]);

  const handleTextSend = (event) => {
    const keyCode = event.which || event.keyCode;

    if (keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleSubmitMensagem(event);
    }
  };

  const handleTextChange = (event) => {
    const msgText = event.target.value;
    textAreaSendMessage.current[event.target.name] = event.target.value;
    const arrayMsgs = window.SAKshortcut && Object.entries(window.SAKshortcut);
    const containerInput = inputTextRef?.current || null;

    setTextMessage(String(msgText));

    if (!arrayMsgs) return;

    arrayMsgs.some((item) => {
      if (
        msgText.includes(`/${item[0]}`) &&
        !msgText.split(`/${item[0]}`)[
          Object.keys(msgText.split(`/${item[0]}`)).length - 1
        ]
      ) {
        event.target.value = event.target.value.replace(`/${item[0]}`, item[1]);
        textAreaSendMessage.current[event.target.name] = event.target.value;
        setTextMessage(String(event.target.value));
        return event.target.value;
      }
      return '';
    });

    if (!containerInput) return;

    /* Redimensionamento do textarea */
    const sizeLength = 7; // quantidade px de cada letra
    const textLength = containerInput?.value?.length || 0; // quantidade de carater
    const baseWidth = 141; // é o tamanho mínimo do bloco de texto
    const lengthWidth = baseWidth + textLength * sizeLength; // tamanho do container com caracteres
    const containerWidth = containerInput.offsetWidth;
    const calcLines = ~~(lengthWidth / containerWidth);

    const numeroDeLinhas = (containerInput.value.match(/\n/g) || [])?.length;
    const defaultHeight = 30 + (calcLines + numeroDeLinhas) * 18 + 12 + 2;
    containerInput.style.height = `${defaultHeight}px`;
  };

  const telNumber = () => {
    const isGroup = contatoAtivo['chatid'].split('@')[1] === 'g.us';
    if (isGroup) {
      return 'Grupo';
    } else {
      let numeroContato = '+' + contatoAtivo.chatid.split('@')[0].toString();
      return formatPhoneNumber(numeroContato);
    }
  };

  const copyPhone = () => {
    const dados = contatoAtivo.chatid.split('@')[0].toString();

    if (typeof navigator.clipboard == 'undefined') {
      const textArea = document.createElement('textarea');
      textArea.value = formatPhoneNumber(dados);
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      window.notifChat('Número de telefone copiado!', 'success');
      document.body.removeChild(textArea);
    } else {
      navigator.clipboard
        .writeText(formatPhoneNumber(dados))
        .then(() => window.notifChat('Número de telefone copiado!', 'success'))
        .catch((_) => window.notifChat('Algo deu errado!', 'warning'));
    }
  };

  function handleAddTag(id) {
    if (
      !sendSocketDados({
        function: 'enviarMarcadorContato',
        data: [
          {
            id,
            userserv: contatoAtivo.userserv,
            userchat: userLogado.userchat,
            chatid: contatoAtivo.chatid,
          },
        ],
      })
    )
      return null;

    // gamification('enviarMarcadorContato');
    setContatoAtivo((prev) => {
      if (!prev['marcadores']) {
        prev['marcadores'] = [id];
        return { ...prev };
      }
      prev['marcadores'] = [...prev['marcadores'], id];
      return { ...prev };
    }, true);
  }

  function atualizarFoto() {
    const atualTimeStamp = ~~(Date.now() / 1000);
    if (attFotoTime) {
      const tempoRestante = ~~(attFotoTime - atualTimeStamp);
      window.notifChat(
        `Você só pode atualizar a foto de perfil novamente em ${tempoRestante} segundos!`,
        'warning'
      );
      return;
    }

    const oneMinute = 1000 * 60;
    const atualTimeStampPosOneMinute =
      new Date(Date.now() + oneMinute).getTime() / 1000;
    setAttFotoTime(atualTimeStampPosOneMinute);
    console.log('contato atualizado');
    if (
      !sendSocketDados({
        function: 'atualizarFotos',
        data: [
          {
            chatid: contatoAtivo.chatid,
            userserv: contatoAtivo.userserv,
          },
        ],
      })
    )
      return null;
    setTimeout(() => setAttFotoTime(0), oneMinute);
  }

  function sairGrupo() {
    sendSocketDados({
      function: 'sairGrupo',
      data: [
        {
          chatid: contatoAtivo.chatid,
          userserv: contatoAtivo.userserv,
        },
      ],
    });
  }

  function handleRemoveTag(id) {
    if (
      !sendSocketDados({
        function: 'removerMarcadorContato',
        data: [
          {
            id,
            userserv: contatoAtivo.userserv,
            userchat: userLogado.userchat,
            chatid: contatoAtivo.chatid,
          },
        ],
      })
    )
      return null;

    setContatoAtivo((prev) => {
      const findNovosMarcadores =
        prev['marcadores'] &&
        Object.values(prev['marcadores']).filter(
          (marcador) => String(marcador) !== String(id)
        );
      prev['marcadores'] = findNovosMarcadores;
      return { ...prev };
    }, true);

    // gamification('removerMarcadorContato');
  }

  function handleSubmitMensagem(event) {
    event.preventDefault();
    const inputState = inputTextRef ? inputTextRef.current : '';

    if (
      !inputState.value.trim() &&
      window.cacheImgDrop &&
      Object.values(window.cacheImgDrop).length === 0
    ) {
      return null;
    }

    const contato = contatoAtivo.chatid;
    let dadosStorage = mensagens;
    for (let msgIndex in dadosStorage[contato]) {
      if (!dadosStorage?.[contato]?.[msgIndex]) continue;
      if (typeof dadosStorage?.[contato]?.[msgIndex] != 'object') continue;
      dadosStorage[contato][msgIndex].lida = 1;
    }

    const filterAnexosList = window.cacheImgDrop
      ? Object.values(window.cacheImgDrop)?.filter(
          (v, i, a) => a.findIndex((t) => t.uuid === v.uuid) === i
        ) || []
      : [];

    const findAnexosIfStatusOk = filterAnexosList.every(
      (img) => img.status === 'ok'
    );

    if (!findAnexosIfStatusOk) {
      window.notifChat(t('Por favor, aguarde o carregamento dos anexos'), 'error');
      return;
    }

    window.cacheImgDrop = {};

    const allCacheImages = document.querySelectorAll('[data-dz-remove=true]');
    allCacheImages &&
      Object.values(allCacheImages).forEach((img) => img.click());

    let resposta = '';

    const tipoMensagem = {
      Publica: 'enviarMensagem',
      Privada: 'enviarMensagemInterna',
    };

    if (idMessage.length) {
      const findResposta = idMessage.find(
        (item) => item.conversaID === contatoAtivo.chatid
      );
      if (findResposta) {
        resposta = findResposta.id;
        if (tipoMensagem[tab] === 'enviarMensagem') {
          idMessage.splice(findResposta);
          setIdMessage([...idMessage]);
        } else {
          window.notifChat(
            'Você não pode responder com uma mensagem privada!',
            'warning'
          );
        }
      }
    }

    // if (tab === 'Publica') gamification('EnviarNovaMensagem');
    // else gamification('enviarMensagemInterna');

    const idProvisorio = crypto.randomUUID();
    // const atualTimeStamp = Date.now() / 1000;

    // setMensagens((prevMsg) => {
    //   if (!prevMsg[lojaAtivo][contato]) return { ...prevMsg };

    //   const dadosMSG = {
    //     Nfrom: `${lojas.whatsapp}@c.us`,
    //     conversa: contato,
    //     data_whats: atualTimeStamp,
    //     id: idProvisorio,
    //     inclusao: atualTimeStamp,
    //     privado: 0,
    //     me: '1',
    //     status: '-2',
    //     texto: inputState.value.trim() || '',
    //     userchat: userLogado.userchat,
    //     userserv: lojaAtivo,
    //   };

    //   const possuiAnexos = Object.values(filterAnexosList).length;
    //   console.log(filterAnexosList)

    //   if(!possuiAnexos) {
    //     prevMsg[lojaAtivo][contato][idProvisorio] = dadosMSG;
    //   }else {
    //     filterAnexosList.forEach(anexo => {
    //       const { idProvisorio: id, url } = anexo || {};
    //       console.log(anexo['uuid'])
    //       dadosMSG['anexo'] = url;
    //       dadosMSG['id'] = id;
    //       prevMsg[lojaAtivo][contato][id] = dadosMSG;
    //     })
    //   }

    //   const lastMessage = {};
    //   lastMessage[contato] = dadosMSG;

    //   setLastMsg(lastMessage);

    //   setContatos((contact) => {
    //     if (!contact?.[lojaAtivo]?.[contato]) return { ...contact };

    //     contact[lojaAtivo][contato] = Object.assign(
    //       contact[lojaAtivo][contato],
    //       {
    //         ultimamsg: atualTimeStamp,
    //         ultimamsgtexto: possuiAnexos ? 'Anexo' : dadosMSG['texto'],
    //         naolidas: 0,
    //       }
    //     );

    //     return { ...contact };
    //   });

    //   return { ...prevMsg };
    // });

    const adiar = () => {
      const isThereActive = document.querySelector(
        '.delay-message-badge.active'
      );

      if (isThereActive && tipoMensagem[tab] === 'enviarMensagemInterna') {
        return { adiar: delayMessage };
      } else {
        return {};
      }
    };

    if (
      !sendSocketDados({
        function: tipoMensagem[tab],
        data: [
          {
            idProvisorio,
            texto: inputState.value.trim(),
            conversa: contato,
            userserv: lojaAtivo,
            anexo: filterAnexosList,
            userchat: userLogado.userchat,
            resposta,
            ...adiar(),
          },
        ],
      })
    )
      return null;

    if (
      contatoAtivo &&
      String(contatoAtivo.userchat) === '0' &&
      tab === 'Publica'
    ) {
      if (
        !sendSocketDados({
          function: 'assumirConversa',
          data: [
            {
              id: contatoAtivo.chatid,
              userserv: lojaAtivo,
              userchat: userLogado.userchat,
            },
          ],
        })
      )
        return null;

      contatoAtivo.userchat = userLogado.userchat;

      setContatoAtivo(() => ({
        ...contatoAtivo,
        ...{
          userchat: userLogado.userchat,
        },
      }));
    }

    inputState.value = '';
    textAreaSendMessage.current[`Conversa-${contatoAtivo.chatid}`] = '';

    inputState.focus();
    inputState.setAttribute('style', '');
    setShowEmojis(false);
  }

  function assumirConversa() {
    if (
      !sendSocketDados({
        function: 'assumirConversa',
        data: [
          {
            id: contatoAtivo.chatid,
            userserv: lojaAtivo,
            userchat: userLogado.userchat,
          },
        ],
      })
    )
      return null;

    setContatoAtivo(() => ({
      ...contatoAtivo,
      ...{
        userchat: userLogado.userchat,
      },
    }));
  }

  async function ArquivarContato() {
    const nomeDoContato = restringirNome(
      contatoAtivo.nome,
      19,
      true,
      contatoAtivo.chatid
    );

    const arquivar = () => {
      if (
        !sendSocketDados({
          function: 'arquivarContato',
          data: [
            {
              id: contatoAtivo.chatid,
              userserv: lojaAtivo,
              userchat: userLogado.userchat,
            },
          ],
        })
      )
        return null;

      window.notifChat(`${nomeDoContato} foi arquivado com sucesso!`);
    };

    // gamification('encerrarContato');

    const dadosFetch = {
      method: 'POST',
      body: JSON.stringify({
        user_pai_id: userchat[userLogado.userchat].user_pai_id,
        phone_loja: lojas.whatsapp,
        phone_user: contatoAtivo.chatid.split('@')[0],
        chave_empreender: lojas['chave_empreender'],
        version: 2,
      }),
    };

    const teenDays = 1000 * 60 * 60 * 24 * 10;

    // Só avalia se for menor que 10 dias
    if (
      !Number(contatoAtivo?.userchat) ||
      contatoAtivo?.ultimamsg <= teenDays
    ) {
      return arquivar();
    }

    fetch(process.env.REACT_APP_AVALIACOES_LINK, dadosFetch)
      .then((r) => {
        if (r.ok) {
          r.json().then((dados) => {
            const texto = dados['mensagem']
              ? dados['mensagem']
              : `*#Robô:* Atendimento encerrado. Clique no *link* para avaliar nosso atendimento ${dados['url']}`;

            if (
              !sendSocketDados({
                function: 'enviarMensagem',
                data: [
                  {
                    texto,
                    conversa: contatoAtivo.chatid,
                    userserv: lojaAtivo,
                    anexo: [],
                    userchat: userLogado.userchat,
                    resposta: 0,
                    avaliacao: true,
                  },
                ],
              })
            )
              return null;
            arquivar();
          });
        } else {
          arquivar();
        }
      })
      .catch((_) => arquivar());
    setContatoAtivo(() => '');
  }

  function fecharChat() {
    const chat = document.getElementById('main-chat');
    chat.classList.remove('main-visible');
  }

  function TrocarAtendente(atendente) {
    if (
      !sendSocketDados({
        function: 'trocarAtendente',
        data: [
          {
            conversa: contatoAtivo.chatid,
            userchat_acao: userLogado.userchat,
            userchat: atendente,
            userserv: lojaAtivo,
          },
        ],
      })
    )
      return null;

    // gamification('trocarAtendente');
    setContatoAtivo(() => ({
      ...contatoAtivo,
      ...{
        userchat: atendente,
      },
    }));

    window.jQuery('#chat-atendente').collapse('hide');
  }

  function RemoverAtendente(atendente) {
    if (
      !sendSocketDados({
        function: 'removerAtendente',
        data: [
          {
            conversa: contatoAtivo.chatid,
            userchat: atendente,
            userserv: lojaAtivo,
          },
        ],
      })
    )
      return null;

    // gamification('removerAtendente');
    setContatoAtivo(() => ({
      ...contatoAtivo,
      ...{
        userchat: 0,
      },
    }));

    window.jQuery('#chat-atendente').collapse('hide');
  }

  function listarAtendentes() {
    const Loja = lojas;
    contatoAtivo.arquivado = String(contatoAtivo.arquivado);

    return (
      <>
        {userchat &&
          Loja.userchat &&
          Loja.userchat.map((atendente) => {
            if (!atendente || !userchat[atendente]) return null;
            if (userchat[atendente]['excluido'] !== '0') return null;
            return (
              <ListAtends
                key={atendente}
                className="dropdown-item d-flex align-items-center"
                href="# "
                onClick={() => TrocarAtendente(userchat[atendente].userchat)}
                color={userchat[atendente].cor}
              >
                {userchat[atendente].nome}
              </ListAtends>
            );
          })}
        {contatoAtivo.userchat ? (
          <ListAtends
            onClick={() => RemoverAtendente(contatoAtivo.userchat)}
            color={'#868e9a'}
            className="dropdown-item d-flex align-items-center"
            href="# "
          >
            {t('Remover Atendente')}
          </ListAtends>
        ) : (
          ''
        )}
        {/* {contatoAtivo.arquivado === '0' && (
          <ListAtends
            onClick={() => ArquivarContato()}
            color={'#FF5848'}
            className="dropdown-item d-flex align-items-center"
            href="# "
          >
            Encerrar Conversa
          </ListAtends>
        )} */}
      </>
    );
  }
  return (
    <>
      <div className="tw-flex tw-flex-col tw-h-full">
        <div
          id="chat-1"
          className="chat dropzone-form-js"
          data-dz-url="https://chat.sak.com.br/neo_upload.php"
          data-dz-fallback-url="https://chat.sak.com.br/upload.php"
        >
          <div className="chat-body">
            <div className="chat-header tw-px-5 tw-py-3 tw-border-b tw-border-seg-border dark:tw-border-seg-border-dark">
              <div className="align-items-center d-flex justify-content-between tw-flex-nowrap tw-gap-3">
                <div className="tw-flex tw-min-w-0 tw-gap-3">
                  <span
                    className="
                      tw-py-0 tw-px-2 -tw-mx-2
                      min-[1200px]:tw-hidden tw-flex tw-items-center tw-cursor-pointer tw-text-gray-900 dark:tw-text-gray-400
                    "
                    onClick={() => fecharChat()}
                  >
                    <i className="icon-md fe-chevron-left" />
                  </span>
                  <div className="d-flex align-items-center tw-justify-start //justify-content-center tw-gap-3 tw-min-w-0">
                    <AvatarEdit>
                      <Avatar data={contatoAtivo} />
                      <span onClick={() => atualizarFoto()}>
                        <i className="fe-refresh-cw"></i>
                      </span>
                    </AvatarEdit>

                    <div className="tw-flex tw-flex-col tw-justify-center tw-flex-shrink tw-min-w-0">
                      <span
                        className="tw-truncate text-left tw-text-sm tw-leading-6 tw-font-semibold tw-text-gray-900 dark:tw-text-white"
                        id="contatoNome"
                        title={ restringirNome(contatoAtivo.nome, null, null, contatoAtivo.chatid) }
                      >
                        { restringirNome(contatoAtivo.nome, null, null, contatoAtivo.chatid) }
                      </span>
                      {
                        contatoAtivo.chatid &&
                        contatoAtivo.chatid.split('@')[0].length < 20 ? (
                          contatoAtivo.nome !== 'undefined' &&
                          contatoAtivo.nome !== '' && (
                            <IntlTelInput
                              fieldId="contatoWhatsapp"
                              containerClassName="intl-tel-input intl-tel-input-ctt"
                              inputClassName="form-control form-control-sm tw-font-normal tw-text-sm tw-leading-6 tw-text-gray-500 dark:tw-text-gray-400"
                              value={telNumber()}
                              onFlagClick={() => copyPhone()}
                              disabled={true}
                              allowDropdown={false}
                              format={true}
                            />
                          )
                        ) : (
                          <span className="tw-text-sm tw-leading-6 tw-font-normal tw-truncate tw-text-gray-500 dark:tw-text-gray-400">
                            (Grupo)
                          </span>
                        )
                      }
                    </div>
                  </div>
                </div>

                {/* Chat toolbar */}
                <div
                  className="
                    max-1200:tw-hidden
                    tw-flex tw-gap-3 tw-items-center tw-justify-end tw-flex-1 
                    tw-text-right pr-5 pr-lg-0
                  "
                >
                  <HorizontallyScrollable
                    className="
                      tw-flex tw-flex-nowrap
                      tw-items-center tw-gap-1 tw-flex-1 tw-w-0 tw-max-w-fit
                      [&>*]:tw-flex-shrink-0 hide-scrollbar
                      [&:not(:empty)]:tw-w-20
                    "
                  >
                    {
                      contatoAtivo?.marcadores &&
                      Object.values(contatoAtivo.marcadores)
                        .filter(id => id && marcadores[id])  
                        .map((id, index) =>
                          (
                            <CuteTag
                              key={index}
                              className="tw-flex tw-gap-1 tw-items-center"
                              color={marcadores[id].cor}
                            >
                              {marcadores[id].titulo}
                              <i
                                onClick={() => handleRemoveTag(id)}
                                className="fe fe-x tw-cursor-pointer"
                              />
                            </CuteTag>
                          )
                      )
                    }
                  </HorizontallyScrollable>
                </div>

                <div
                  className="
                    max-1200:tw-hidden
                    tw-flex tw-gap-3 tw-items-center tw-justify-end //tw-flex-1 tw-flex-shrink-0
                    tw-text-right pr-5 pr-lg-0
                  "
                >
                  <Dropdown as="div">
                    <DropdownButton as="span">
                      <AddTag
                        className="tw-size-5 tw-text-gray-400 tw-cursor-pointer"
                      />
                    </DropdownButton>

                    <DropdownMenu
                      anchor="bottom end"
                      className="[--anchor-gap:.5rem] [--anchor-max-height:30dvh]"
                    >
                      <DropdownSection>
                        {
                          marcadores &&
                          Object.values(marcadores)
                            .filter(item => {
                              if (!item) return false;
                              if (contatoAtivo.marcadores?.includes(item.id)) return false;

                              return true;
                            }).map(item => {
                              return (
                                <DropdownItem
                                  key={'marcador_conversa_' + item.id}
                                  className="tw-justify-center !tw-py-1"
                                  onClick={() => handleAddTag(item.id)}
                                >
                                  <CuteTag color={item.cor}>
                                    {item.titulo}
                                  </CuteTag>
                                </DropdownItem>
                              );
                          })
                        }
                      </DropdownSection>
                      <DropdownSection>
                        <DropdownItem
                          onClick={() => useModalStore.getState().setModal('manage_labels')}
                        >
                          <DropdownIcon icon={TbTag} />
                          {t('Gerenciar marcadores')}
                        </DropdownItem>
                      </DropdownSection>
                    </DropdownMenu>
                  </Dropdown>

                  <Dropdown as="div">
                    <DropdownButton
                      as="span"
                      className="tw-flex tw-gap-1.5 tw-items-center tw-cursor-pointer"
                    >
                      {
                        contatoAtivo.chatid &&
                        contato[contatoAtivo.chatid]?.userchat > 0 &&
                        userchat?.[contatoAtivo.userchat] ? (
                          <AtualAtendente
                            color={userchat[contato[contatoAtivo.chatid].userchat].cor}
                          >
                            { restringirNome(userchat[contato[contatoAtivo.chatid].userchat].nome, 1) }
                          </AtualAtendente>
                        ) : (
                          <AtualAtendente
                            color={'var(--secondary)'}
                            colorText
                          >
                            <UserOff className="tw-size-5" />
                          </AtualAtendente>
                        )
                      }

                      <HiOutlineChevronDown className="tw-size-4 tw-text-gray-400" />
                    </DropdownButton>

                    <DropdownMenu
                      anchor="bottom end"
                      className="[--anchor-gap:.5rem]"
                    >
                      <DropdownSection>
                        {
                          contatoAtivo.userchat && (
                            <DropdownItem
                              className="tw-gap-2"
                              onClick={() => RemoverAtendente(contatoAtivo.userchat)}
                            >
                              <AtualAtendente
                                color={'var(--secondary)'}
                                colorText
                                small
                              >
                                <UserOff className="tw-size-3.5" />
                              </AtualAtendente>

                              { t('Remover Atendente') }
                            </DropdownItem>
                          )
                        }

                        {
                          userchat &&
                          lojas.userchat
                            ?.filter(atendente => {
                              if (!atendente || !userchat[atendente]) return false;
                              if (userchat[atendente]['excluido'] !== '0') return false;

                              return true;
                            })
                            .map((atendente) => {
                              return (
                                <DropdownItem
                                  key={atendente}
                                  className="tw-gap-2"
                                  onClick={() => TrocarAtendente(userchat[atendente].userchat)}
                                >
                                  <AtualAtendente
                                    color={userchat[atendente].cor}
                                    small
                                  >
                                    { restringirNome(userchat[atendente].nome, 1) }
                                  </AtualAtendente>

                                  { userchat[atendente].nome }
                                </DropdownItem>
                              );
                          })
                        }
                      </DropdownSection>
                    </DropdownMenu>
                  </Dropdown>

                  <span
                    className="tw-cursor-pointer tw-flex tw-items-center"
                    data-toggle="collapse"
                    data-target="#chat-1-search"
                    title={t('Procurar')}
                  >
                    <HiMagnifyingGlass className="tw-size-5 tw-text-gray-400" />
                  </span>

                  {
                    // eslint-disable-next-line eqeqeq
                    contatoAtivo.arquivado == 0 &&
                    contatoAtivo.chatid?.includes('@g.us') && (

                    <Dropdown as="div">
                      <DropdownButton as="span" className="tw-flex tw-items-center tw-cursor-pointer">
                        <BiDotsVerticalRounded
                          className="tw-size-5 tw-text-gray-400"
                        />
                      </DropdownButton>

                      <DropdownMenu
                        anchor="bottom end"
                        className="[--anchor-gap:.5rem] tw-z-[1020]"
                      >
                        <DropdownSection>
                              <DropdownItem
                                onClick={sairGrupo}
                              >
                                <DropdownIcon icon={HiOutlineUserRemove} />
                                {t('Sair do grupo')}
                              </DropdownItem>
                        </DropdownSection>
                      </DropdownMenu>
                    </Dropdown>
                    )
                  }
                </div>

                {/* Mobile nav */}
                <div
                  className="min-[1200px]:tw-hidden tw-flex tw-gap-3 tw-items-center tw-justify-end"
                >
                  <Dropdown as="span">
                    <DropdownButton as="span" className="tw-flex tw-items-center tw-p-2 tw-cursor-pointer">
                      <BiDotsVerticalRounded
                        className="tw-size-5 tw-text-gray-400"
                      />
                    </DropdownButton>

                    <DropdownMenu
                      anchor="bottom end"
                      className="tw-z-[1020]"
                    >
                      <DropdownSection>
                        <DropdownItem
                          onClick={() => window.jQuery('#chat-1-search').collapse('toggle')}
                        >
                          <DropdownIcon icon={HiMagnifyingGlass} />
                          {t('Pesquisar')}
                        </DropdownItem>

                        <DropdownItem
                          onClick={() => toggleSidebar('#chat-1-info')}
                        >
                          <DropdownIcon icon={HiOutlineUser} />
                          {t('Dados do Cliente')}
                        </DropdownItem>

                        <DropdownItem
                          onClick={() => window.jQuery('#chat-marcadores').collapse('toggle')}
                        >
                          <DropdownIcon icon={AddTag} />
                          {t('Marcadores')}
                        </DropdownItem>

                        <DropdownItem
                          onClick={() => window.jQuery('#chat-atendente').collapse('toggle')}
                        >
                          <DropdownIcon icon={HiOutlineUser} />
                          {t('Atendentes')}
                        </DropdownItem>

                        {
                          // eslint-disable-next-line eqeqeq
                          contatoAtivo.arquivado == 0 &&
                          contatoAtivo.chatid?.includes('@g.us') && (
                            <DropdownItem
                              onClick={sairGrupo}
                            >
                              <DropdownIcon icon={HiOutlineUserRemove} />
                              {t('Sair do grupo')}
                            </DropdownItem>
                          )
                        }
                      </DropdownSection>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="collapse border-bottom px-lg-8" id="chat-marcadores">
              <div className="container-fluid py-4 py-lg-6">
                <ErrorChat codeError={45}>
                  {/* <div className="d-flex"> */}
                  <div
                    className="d-flex align-items-center justify-content-end flex-wrap"
                    style={{ gap: '1.5rem' }}
                  >
                    {contatoAtivo &&
                    Object.values(contatoAtivo).length &&
                    contatoAtivo.marcadores &&
                    Object.values(contatoAtivo.marcadores)?.map(
                      (id, index) =>
                        id &&
                        marcadores &&
                        marcadores[id] && (
                          <Tooltip
                            key={index}
                            text={marcadores[id].titulo}
                          >
                            <>
                            <span style={{ marginRight: '-1rem' }}>{marcadores[id].titulo}</span>
                            <Marcador cor={marcadores[id].cor}>
                              <i className="fas fa-bookmark"></i>
                              <span>
                                <i
                                  onClick={() => handleRemoveTag(id)}
                                  className="fe fe-x"
                                />
                              </span>
                            </Marcador>
                            </>
                          </Tooltip>
                        )
                    )}
                    <Tooltip
                      text={'Adicione um marcador para este contato'}
                      position="right"
                    >
                      <Marcador
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        cor={'#aab0b7'}
                        corIcon={'#fddb78'}
                        style={{ cursor: 'pointer' }}
                      >
                        <i className="fal fa-bookmark"></i>
                        <span>
                          <i className="fe fe-plus" />
                        </span>
                      </Marcador>
                    </Tooltip>
                    <div className="dropdown-menu mr-5">
                      {marcadores &&
                        Object.values(marcadores).length !== 0 &&
                        Object.values(marcadores).map((item, index) => {
                          if (!item) return null;
                          if (
                            contatoAtivo.marcadores &&
                            contatoAtivo.marcadores.includes(item.id)
                          )
                            return null;
                          return (
                            <div
                              className="mb-2"
                              key={index}
                              onClick={() => handleAddTag(item.id)}
                            >
                              <Tag
                                cor={item.cor}
                                idTag={item.id}
                                select
                              >
                                <span>{item.titulo}</span>
                              </Tag>
                            </div>
                          );
                        })}
                      <div
                        className="mb-2"
                        onClick={() => useModalStore.getState().setModal('manage_labels')}
                      >          
                        <Tag cor={'#49c18f'} select>
                          <span>{t('Criar Marcador')}</span>
                        </Tag>
                      </div>
                    </div>
                  </div>
                </ErrorChat>
              </div>
            </div>
            <div className="collapse border-bottom px-lg-8" id="chat-atendente">
              <div className="container-fluid py-4 py-lg-6">
                <ErrorChat codeError={44}>
                  {contatoAtivo.chatid &&
                  contato[contatoAtivo.chatid] &&
                  contato[contatoAtivo.chatid].userchat &&
                  contato[contatoAtivo.chatid].userchat > 0
                    ? listarAtendentes()
                    : ''}
                </ErrorChat>
              </div>
            </div>
            <ErrorChat codeError={43}>
              {mensagens ? (
                <Conversa
                  lastMessage={lastMessage}
                  mensagens={mensagens[contatoAtivo.chatid]}
                  userchat={userchat}
                  inicial={inicial}
                  lojas={lojas}
                  loja={lojaAtivo}
                />
              ) : null}
            </ErrorChat>

            <div className="chat-footer tw-m-2">
              {
                contatoAtivo.chatid &&
                contato[contatoAtivo.chatid] &&
                contato[contatoAtivo.chatid].userchat !== userLogado.userchat &&
                (contato[contatoAtivo.chatid].fechado > 0 || inicial)
                  && (
                  <div className="tw-flex tw-items-center tw-justify-center tw-mb-4">
                    <Button
                      className="
                        tw-rounded-md tw-py-2 tw-px-3 tw-font-semibold
                        tw-bg-indigo-600 tw-text-white
                        hover:tw-bg-indigo-700
                        focus:tw-outline-none
                      "
                      onClick={() => assumirConversa()}
                    >
                      {t('Assumir Conversa')}
                    </Button>
                  </div>
                )
              }
              <div className="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-mb-2">
                <div className="tw-max-h-fit tw-flex tw-gap-2">
                  <Button
                    className={classnames(`
                      tw-rounded-md tw-py-2 tw-px-3 tw-font-semibold
                      [&.active]:tw-bg-west-bar [&.active]:dark:tw-bg-west-bar-dark
                      [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark
                      hover:tw-bg-west-bar/60 dark:hover:tw-bg-west-bar-dark/60
                      hover:tw-text-active/90 dark:hover:tw-text-active-dark/90
                      focus:tw-outline-none
                    `, { active: tab === 'Publica' })}
                    onClick={() => setTab('Publica')}
                  >
                    {t('Resposta Pública')}
                  </Button>

                  <Button
                    className={classnames(`
                      tw-rounded-md tw-py-2 tw-px-3 tw-font-semibold
                      [&.active]:tw-bg-west-bar [&.active]:dark:tw-bg-west-bar-dark
                      [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark
                      hover:tw-bg-west-bar/60 dark:hover:tw-bg-west-bar-dark/60
                      hover:tw-text-active/90 dark:hover:tw-text-active-dark/90
                      focus:tw-outline-none
                    `, { active: tab === 'Privada' })}
                    onClick={() => setTab('Privada')}
                  >
                    {t('Anotação Interna')}
                  </Button>
                </div>
                <ErrorChat codeError={40}>
                  {/*  eslint-disable-next-line eqeqeq */}
                  {contatoAtivo && contatoAtivo.arquivado == '0' && (
                    <Button
                      className="
                        tw-flex tw-gap-1.5 tw-justify-between tw-items-center
                        tw-rounded-md tw-py-2 tw-px-3 tw-font-medium
                        tw-text-white tw-bg-red-500
                        hover:tw-bg-red-600
                        focus:tw-outline-none
                      "
                      onClick={() => ArquivarContato()}
                    >
                      <HiOutlineArchiveBoxArrowDown className="tw-w-5 tw-h-5"/>
                      {t('Finalizar')}
                    </Button>
                  )}
                </ErrorChat>
              </div>
              <div className="hide-scrollbar flex-fill">
                <div className="chat-files hide-scrollbar">
                  <div>
                    <div
                      className="
                        dropzone-previews-js form-row
                        tw-mb-2 tw-mx-0
                        tw-bg-transparent

                        [&_.card]:!tw-bg-west-bar [&_.card]:dark:!tw-bg-west-bar-dark
                        [&_.card]:!tw-rounded-md [&_.card]:!tw-h-full

                        [&_.dropzone-image-preview_.avatar]:!tw-flex

                        [&_.progress-dropzone-bar]:!tw-bg-active [&_.progress-dropzone-bar]:dark:!tw-bg-active-dark
                        [&_.progress-dropzone-bar]:!tw-rounded-b-md
                        [&_.avatar]:!tw-bg-transparent
                      "
                    />
                  </div>
                </div>
                <div
                  className="chat-files px-lg-4 d-none"
                  id="dropzone-preview-img"
                >
                  <div className="container-fluid">
                    <div className="form-row py-7 justify-content-center form-row-spacing">
                      Arraste seus arquivos e solte aqui.
                    </div>
                  </div>
                </div>

                {
                  showEmojis && (
                    <div data-emoji-element ref={emojiRef}>
                      <NimblePicker
                        i18n={translateEmojis}
                        set="twitter"
                        data={listaEmojis}
                        showPreview={false}
                        onSelect={(emoji) => {
                          if (!inputTextRef.current) return;
                          inputTextRef.current.value += emoji['native'];
                          inputTextRef.current.focus();
                        }}
                      />
                    </div>
                  )
                }
                {showDelayMessage && tab === 'Privada' && <DelayMessage />}
                {showRepositoryBox && tab === 'Publica' && <ImageRepository />}

                <div
                  className='tw-border tw-border-seg-border dark:tw-border-seg-border-dark tw-rounded-md'
                >
                  <form
                    onSubmit={handleSubmitMensagem}
                    id="chat-id-1-form"
                    data-emoji-form
                  >
                    <Anotacao>
                      <div className="tw-flex tw-flex-col tw-w-full tw-items-center">
                        <div className="tw-w-full tw-relative">
                          <TextArea
                            className="tw-w-full !tw-max-h-[min(60dvh,500px)]"
                            ref={inputTextRef}
                            id="chat-id-1-input"
                            onKeyPress={handleTextSend}
                            onChange={handleTextChange}
                            placeholder={t('Escreva uma mensagem')}
                            data-emoji-input
                            data-autosize="true"
                            rows="2"
                            name={`Conversa-${contatoAtivo.chatid}`}
                          ></TextArea>
                        </div>

                        <div className="
                          tw-flex tw-items-center tw-w-full tw-justify-between
                          tw-border-t tw-border-seg-border dark:tw-border-seg-border-dark
                          tw-px-3 tw-py-2 [&>div]:tw-h-9
                        ">
                          <div className='tw-flex tw-gap-3 tw-items-center'>
                            <Tooltip text="Emojis">
                              <span
                                className={classnames("tw-cursor-pointer [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark", { active: showEmojis })}
                                onClick={_ => setShowEmojis(!showEmojis)}
                              >
                                <BiSmile className="tw-w-5 tw-h-5"/>
                              </span>
                            </Tooltip>

                            <Tooltip text="Carregar anexo">
                              <span
                                id="chat-upload-btn-1"
                                className="tw-cursor-pointer dropzone-button-js"
                              >
                                <HiMiniPaperClip className="tw-w-5 tw-h-5"/>
                              </span>
                            </Tooltip>

                            {
                              tab === 'Publica' && (
                                <Tooltip text="Galeria">
                                  <span
                                    className={classnames("tw-cursor-pointer [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark", { active: showRepositoryBox })}
                                    onClick={_ => setshowRepositoryBox(!showRepositoryBox)}
                                  >
                                    <PiImageFill className="tw-w-5 tw-h-5"/>
                                  </span>
                                </Tooltip>
                              )
                            }

                            {tab === 'Publica' && <ChatAssistant />}
                            {tab === 'Publica' && <FAQAssistant />}
                            {tab === 'Publica' && <RecordAudio />}

                            {tab === 'Privada' && (
                              <Tooltip text="Agendar lembrete">
                                <span
                                  className={classnames("tw-cursor-pointer [&.active]:tw-text-active [&.active]:dark:tw-text-active-dark", { active: showDelayMessage })}
                                  onClick={_ => setShowDelayMessage(!showDelayMessage) }
                                >
                                  <BiCalendar className="tw-w-5 tw-h-5"/>
                                </span>
                              </Tooltip>
                            )}
                          </div>
                          <div className='tw-flex tw-items-center'>
                            <Tooltip text="Enviar mensagem">
                              <Button
                                type="submit"
                                className="
                                  tw-p-2 tw-rounded-md
                                  tw-bg-west-bar dark:tw-bg-west-bar-dark
                                  hover:tw-brightness-95 dark:hover:tw-brightness-125
                                  focus:tw-outline-none
                                "
                              >
                                <FiSend className="tw-w-5 tw-h-5 tw-text-gray-900 dark:tw-text-white"/>
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </Anotacao>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <ErrorChat codeError={46}>
            <SideExtension />
          </ErrorChat>
        </div>
      </div>
      {/* Conteúdo onde fica os arquivos upados */}
      <div id="dropzone-template-js" style={{ display: 'none' }}>
        <div className="col-lg-4 my-3">
          <div className="card" style={{ background: 'var(--basic)' }}>
            <div className="card-body p-3">
              <div className="media align-items-center">
                <div className="dropzone-file-preview">
                  <div className="avatar avatar rounded bg-secondary text-basic-inverse d-block mr-5">
                    <i className="fe-paperclip" />
                  </div>
                </div>
                <div className="dropzone-image-preview">
                  <div className="avatar avatar mr-5">
                    <img
                      src="# "
                      className="avatar-img rounded"
                      data-dz-thumbnail
                      alt=""
                    />
                  </div>
                </div>
                <div className="media-body overflow-hidden">
                  <p className="extra-small" data-dz-size />
                </div>
                <div className="ml-5">
                  <a
                    href="# "
                    className="btn btn-sm btn-link text-decoration-none text-muted"
                    data-dz-remove
                  >
                    <i className="fe-x" />
                  </a>
                </div>
              </div>
            </div>
            <div className="progress-dropzone">
              <div
                className="progress-dropzone-bar"
                role="progressbar"
                data-dz-uploadprogress
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaixaMensagem;
