import { useTranslation } from 'react-i18next';
import { HiOutlineChevronDown, HiOutlinePencil, HiOutlineTrash, HiOutlineDownload } from 'react-icons/hi';

import { Dropdown, DropdownButton, DropdownMenu, DropdownSection, DropdownItem, DropdownIcon } from '../../../../components/Dropdown';
import { classnames } from '../../../../utils/usefulFunctions';
import useModalStore from '../../../../stores/useModalStore';
import useSocketStore from '../../../../stores/useSocketStore';

function confirmDeletion (message) {
  const onConfirm = () => {
    useSocketStore.getState().sendSocketDados({
      function: 'excluirMensagem',
      data: [{
        idFull: message.idFull,
        userserv: message.userserv,
        userchat: message.userchat
      }]
    });
  };

  useModalStore.getState().setModal('confirmation', {
    title: 'Deseja apagar esta mensagem?',
    description: 'Esta mensagem será apagada para você e todos os outros participantes desta conversa.',
    confirmBtnText: 'Apagar mensagem',
    onConfirm: onConfirm
  });
}

function editMessage (message) {
  useModalStore.getState().setModal('edit_message', { message });
}

const DropdownMensagem = ({ id, mensagens }) => {
  const { t } = useTranslation();

  if (!mensagens?.[id]) return null;

  const me = mensagens[id].me === '1';
  const anexo = mensagens[id].anexo;

  if (!me && !anexo) return null;

  return (
    <div
      className={classnames('tw-absolute tw-top-1 tw-z-[9]', me ? 'tw-right-1' : 'tw-left-1')}
    >
      <Dropdown
        as="div"
      >
        <DropdownButton
          className="
            tw-rounded-full tw-p-1 -tw-m-1
            tw-text-black/30 dark:tw-text-white/50
            hover:tw-text-gray-500 dark:hover:tw-text-white
            focus:tw-outline-none

            tw-invisible group-hover:tw-visible
          "
        >
          <HiOutlineChevronDown className="tw-w-5 tw-h-5"/>
        </DropdownButton>

        <DropdownMenu anchor={ me ? 'bottom end' : 'bottom start' }>
          <DropdownSection>
            {
              me && (
                <>
                  <DropdownItem
                    onClick={() => editMessage(mensagens[id])}
                  >
                    <DropdownIcon icon={HiOutlinePencil} />
                    { t('Editar mensagem') }
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => confirmDeletion(mensagens[id])}
                  >
                    <DropdownIcon icon={HiOutlineTrash} />
                    { t('Apagar mensagem') }
                  </DropdownItem>
                </>
              )
            }
            {
              anexo && (
                <DropdownItem
                  href={anexo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <DropdownIcon icon={HiOutlineDownload} />
                  { t('Baixar anexo') }
                </DropdownItem>
              )
            } 
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default DropdownMensagem;
