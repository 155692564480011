import styled from 'styled-components';
import { tint, shade } from 'polished';

export const Marcador = styled.div`
  color: ${(props) => (props.cor ? props.cor : '#ccc')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > i {
    color: unset;
    font-size: 1.3rem;
  }

  span {
    position: absolute;
    right: -9px;
    top: -9px;
    background: ${(props) => (props.corIcon ? props.corIcon : '#FF5848')};
    border-radius: 50%;
    color: ${(props) => (props.corIcon ? 'black' : 'white')};
    width: 15px;
    height: 15px;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    > i {
      margin-top: 1px;
    }
  }
`;

export const EncerrarConversa = styled.a`
  background: #ff8181;
  color: #560303;
  font-weight: 500;
  border-radius: 20px;
  color: #7a0000;
  margin-bottom: 5px;
  padding: 6px 15px;
  font-size: 0.8rem;
  height: 100%;
  margin-right: 22px;

  &:hover {
    background: #ff6565;
    color: #200000;
  }

  @media (max-width: 562px) {
    max-width: 80px;
    white-space: break-spaces;
    text-align: center;
    margin-right: 10px;
  }
`;

export const ListAtends = styled.a`
  background: ${(props) => (props.color ? props.color : '#ccc')};
  margin-bottom: 5px;
  color: white;
  padding: 6px 15px;
  font-size: 0.8rem;

  &:hover {
    background: ${(props) => (props.color ? tint(0.2, props.color) : '#ccc')};
    color: ${tint(0.8, '#ccc')};
  }
`;

export const CloseChat = styled.button`
  border: 0;
  border-radius: 6px;
  background: #ff5848;
  color: white;
  padding: 6px 16px;
  font-size: 0.8rem;
`;

export const TextArea = styled.textarea`
  background: transparent;
  display: table-cell;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex: 1;
  border: 0;
  min-height: 62px;
  padding: 14px 20px 6px 12px;
  line-height: 18px;
  font-size: 0.9375rem;
  font-weight: 400 !important;
  max-height: 500px;
  color: #7f808c;
  overflow-x: hidden !important;
  overflow-y: scroll !important;

  &:focus {
    border: none;
    outline: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #63cab2;
  }
`;

export const SelectTag = styled.a`
  border-radius: 4px;
  font-size: 1rem;
  background: var(--secondary);
  color: var(--basic-inverse);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-height: 100%;
  padding: 4px 8px;
  margin-right: 5px;
  transition: background 0.2s;

  i {
    cursor: pointer;
    padding: 2px;
    border-radius: 50%;
  }

  &:hover {
    color: var(--basic-inverse);
    background: ${shade(0.1, '#ccc')};
  }
`;

export const AvatarEdit = styled.div`
  position: relative;
  height: 2.75rem;
  min-height: 2.75rem;
  width: 2.75rem;
  min-width: 2.75rem;

  transition: all 0.3s;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover > span:nth-last-child(1) {
    opacity: 1;
  }

  & > span:nth-last-child(1) {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;

    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.3s;

    i {
      color: white;
    }
  }
`;

export const AtualAtendente = styled.div`
  border-radius: 100%;
  width: ${(props) => 'small' in props ? '1.375rem' : '2rem'};
  height: ${(props) => 'small' in props ? '1.375rem' : '2rem'};

  flex-shrink: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: ${(props) => 'small' in props ? '.625rem' : '.8125rem'};
  line-height: ${(props) => 'small' in props ? '.6875rem' : '1rem'};
  color: ${(props) => (props.colorText ? 'var(--basic-inverse)' : '#fff')};

  background: ${(props) => (props.color ? props.color : '#ccc')};
  transition: all 0.3s;
  cursor: pointer;
  user-select: none;

  i {
    margin-left: 4px;
    border-radius: 50%;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const Assumir = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--basic-inverse);
  color: var(--basic-inverse);
  padding: 8px 46px;
  border-radius: 3px;

  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    border-color: #171a31;
    background: var(--dark);
    color: white;
  }
`;

export const Tabs = styled.li`
  position: relative;
  max-height: fit-content;

  a {
    font-size: 0.9rem;
    padding: 0.7rem 0.5rem;
    font-weight: bold;
    margin: 0;
    // border-radius: 0 !important;
    // border: 0 !important;
    // border-bottom: 2px solid transparent !important;
  }

  // .active {
  //   border-radius: 0 !important;
  //   border: 0 !important;
  //   border-bottom: 2px solid var(--azul) !important;
  // }
`;

export const Anotacao = styled.div`
  // margin-left: -6px;

  button[type='submit'] {
    // margin-right: 12px;
    font-size: 1.5rem;
    border: 0;
    // background: transparent;
    // color: var(--svg);
    outline: 0;
  }

  // .col {
  //   .input-group {
  //     textarea {
  //       color: #677079;
  //       font-weight: bold;

  //       &::placeholder {
  //         color: #959da5;
  //       }
  //     }
  //   }
  // }
`;
