const DEFAULT_STOPS = [0, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950, 1000];

function unsignedModulo(x, n) {
  return ((x % n) + n) % n;
}

function clamp(x, min, max) {
  return Math.min(Math.max(x, min), max);
}

function round(value, precision = 0) {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

function luminanceFromHex(hex) {
  return round(luminanceFromRGB(...Object.values(hexToRGB(hex))), 2);
}

function luminanceFromRGB(r, g, b) {
  const toLin = c => {
    c /= 255;

    return c < 0.03928
      ? c / 12.92
      : Math.pow((c + 0.055) / 1.055, 2.4);
  };

  return 21.26 * toLin(r) + 71.52 * toLin(g) + 7.22 * toLin(b);
}

function hexToRGB(hex) {
  if (hex.startsWith('#')) hex = hex.slice(1);

  const dec = parseInt(hex, 16);

  const r = (dec >> 16) & 0xFF;
  const g = (dec >> 8)  & 0xFF;
  const b = dec         & 0xFF;

  return { r, g, b };
}

function HSLToHex(h, s, l) {
  let { r, g, b } = HSLtoRGB(h, s, l);

  r = r.toString(16).padStart(2, '0');
  g = g.toString(16).padStart(2, '0');
  b = b.toString(16).padStart(2, '0');

  return `#${r}${g}${b}`;
}

function HSLtoRGB(h, s, l) {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((h / 60) % 2 - 1));
  const m = l - c / 2;

  const [r, g, b] = h < 60 ? [c, x, 0] :
                    h < 120 ? [x, c, 0] :
                    h < 180 ? [0, c, x] :
                    h < 240 ? [0, x, c] :
                    h < 300 ? [x, 0, c] : [c, 0, x];

  return {
    r: Math.round((r + m) * 255),
    g: Math.round((g + m) * 255),
    b: Math.round((b + m) * 255),
  };
}

function hexToHSL(hex) {
  if (hex.startsWith('#')) hex = hex.slice(1);

  let { r, g, b } = hexToRGB(hex);

  r /= 255;
  g /= 255;
  b /= 255;

  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;

  let h = 0;
  if (delta !== 0) {
    if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;
  }
  h = Math.round(h * 60);
  if (h < 0) h += 360;

  const l = (cmax + cmin) / 2;
  const s = delta === 0
    ? 0 : delta / (1 - Math.abs(2 * l - 1));

  return {
    h,
    s: +(s * 100).toFixed(1),
    l: +(l * 100).toFixed(1)
  };
}

function createDistributionValues(min = 0, max = 100, lightness, stop = 500) {
  const stops = [...DEFAULT_STOPS];
  const newValues = stops.map((stopValue) => {
    if (stopValue === 0) return { stop: 0, tweak: max };
    if (stopValue === 1000) return { stop: 1000, tweak: min };
    if (stopValue === stop) return { stop, tweak: lightness };

    const diff = Math.abs((stopValue - stop) / 100);
    const totalDiff =
      stopValue < stop
        ? Math.abs(stops.indexOf(stop) - stops.indexOf(DEFAULT_STOPS[0])) - 1
        : Math.abs(stops.indexOf(stop) - stops.indexOf(DEFAULT_STOPS[DEFAULT_STOPS.length - 1])) - 1

    const increment = stopValue < stop ? max - lightness : lightness - min;
    const tweak = stopValue < stop
      ? (increment / totalDiff) * diff + lightness
      : lightness - (increment / totalDiff) * diff;

    return { stop: stopValue, tweak: Math.round(tweak) };
  });

  newValues.sort((a, b) => a.stop - b.stop);
  return newValues;
}

function lightnessFromHSLum(H, S, Lum) {
  let closestL = 0;
  let smallestDiff = Infinity;

  for (let L = 0; L <= 100; L++) {
    const diff = Math.abs(Lum - luminanceFromRGB(...Object.values(HSLtoRGB(H, S, L))));
    if (diff < smallestDiff) {
      smallestDiff = diff;
      closestL = L;
    }
  }

  return closestL;
}

export function createPalette(baseColor, baseStop) {
  if (baseColor.startsWith('#')) baseColor = baseColor.slice(1);

  let { h: hue, s: saturation } = hexToHSL(baseColor);
  hue = unsignedModulo(hue, 360);
  saturation = clamp(saturation, 0, 100);

  const baseLuminance = luminanceFromHex(baseColor);
  const distribution = createDistributionValues(0, 100, baseLuminance, baseStop);

  const palette = DEFAULT_STOPS.map((stop, index) => {
    const newL = lightnessFromHSLum(hue, saturation, distribution[index].tweak);
    const newColor = HSLToHex(hue, saturation, clamp(newL, 0, 100));

    return {
      stop,
      hex: stop === baseStop
        ? '#' + baseColor.toUpperCase()
        : newColor.toUpperCase()
    };
  });

  return palette;
}

const cachedPalettes = new Map();

export function getPalette(baseColor, baseStop) {
  const key = baseColor + '-' + baseStop;
  if (cachedPalettes.has(key)) return cachedPalettes.get(key);

  const palette = createPalette(baseColor, baseStop);
  cachedPalettes.set(key, palette);

  return palette;
}

function hexDistance(hex1, hex2) {
  const rgb1 = Object.values(hexToRGB(hex1));
  const rgb2 = Object.values(hexToRGB(hex2));

  return Math.sqrt(
    rgb1.reduce((acc, val, index) => acc + (val - rgb2[index]) ** 2, 0)
  );
}

const TAILWIND_BADGE_COLORS = [
  '#4b5563',
  '#b91c1c',
  '#854d0e',
  '#15803d',
  '#1d4ed8',
  '#4338ca',
  '#7e22ce',
  '#be185d'
];

const cachedBadges = new Map();

export function getClosestTailwindColor(baseColor) {
  if (cachedBadges.has(baseColor))
      return TAILWIND_BADGE_COLORS[cachedBadges.get(baseColor)];

  const distances = TAILWIND_BADGE_COLORS.map(badgeColor => hexDistance(baseColor, badgeColor));
  const closestIndex = distances.indexOf(Math.min(...distances));

  const tailwindColor = TAILWIND_BADGE_COLORS[closestIndex];
  cachedBadges.set(baseColor, closestIndex);

  return tailwindColor;
}

export function randomHex() {
  return '#' + Math.floor(Math.random() * ((1 << 24) - 1)).toString(16).padStart(6, '0');
}

export function calculateContrastRatio(textColor, bgColor) {
  const textLuminance = luminanceFromHex(textColor);
  const bgLuminance = luminanceFromHex(bgColor);

  const [brightest, darkest] = textLuminance > bgLuminance
    ? [textLuminance, bgLuminance] : [bgLuminance, textLuminance];

  return (brightest + 0.05) / (darkest + 0.05);
}
