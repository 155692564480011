import { useRef, useState, useEffect } from 'react';
import { PiPauseFill, PiPlayFill } from "react-icons/pi";

import { classnames } from '../../../../utils/usefulFunctions';
import { formatDuration } from '../../../../utils/formatDados';

const SPEEDS = [0.5, 1, 1.5, 2];

function WaveSlider ({
  className,
  progress,
  onScrubEnd
}) {
  const inputRef = useRef(null);
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [internalProgress, setInternalProgress] = useState(progress);

  useEffect(() => {
    const _inputRef = inputRef.current;

    const onMouseDownHandler = () => setIsScrubbing(true);
    const onMouseUpHandler = () => {
      onScrubEnd(Number(_inputRef.value));
      setIsScrubbing(false)
    };

    _inputRef.addEventListener('mousedown', onMouseDownHandler);
    _inputRef.addEventListener('mouseup', onMouseUpHandler);
    _inputRef.addEventListener('touchstart', onMouseDownHandler);
    _inputRef.addEventListener('touchend', onMouseUpHandler);

    return () => {
      _inputRef.removeEventListener('mousedown', onMouseDownHandler);
      _inputRef.removeEventListener('mouseup', onMouseUpHandler);
      _inputRef.removeEventListener('touchstart', onMouseDownHandler);
      _inputRef.removeEventListener('touchend', onMouseUpHandler);
    };
  }, [inputRef, onScrubEnd]);

  useEffect(() => {
    if (!isScrubbing)
      setInternalProgress(progress);
  }, [isScrubbing, progress]);

  return (
    <div className={classnames('tw-relative', className)}>
      <div
        className="
          tw-cursor-pointer
          tw-w-[10.0625rem] tw-h-6
          tw-bg-black/20 dark:tw-bg-white/20
        "
        style={{
          mask: 'url(assets/base-wave.svg)',
          maskSize: 'cover',
          maskRepeat: 'no-repeat',
        }}
      >
        <div
          className="tw-h-full tw-bg-black/[.68] dark:tw-bg-icon-secondary-dark"
          style={{ width: `${internalProgress * 100}%` }}
        />
      </div>

      <input
        ref={inputRef}
        className="
          tw-absolute tw-top-0 tw-left-0 -tw-ml-1.5
          tw-w-[calc(100%+.75rem)] tw-h-full tw-cursor-pointer
          tw-bg-transparent tw-appearance-none
          [&::-webkit-slider-thumb]:tw-bg-[#007BFC] [&::-moz-range-thumb]:tw-bg-[#007BFC]
          [&::-webkit-slider-thumb]:tw-size-3 [&::-moz-range-thumb]:tw-size-3
        "
        type="range"
        min="0" max="1" step="0.01"
        value={internalProgress}
        onChange={e => { setInternalProgress(Number(e.target.value)); }}
      />
    </div>
  );
};

export function AudioPlayer ({
  className,
  src
}) {
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [speed, setSpeed] = useState(1);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef(null);

  const play = () => {
    if (audioRef.current.paused) {
      document.querySelectorAll('audio')
        .forEach(audio => {
          if (!audio.paused) audio.pause();
        });

      audioRef.current.play();
    }
  };

  const pause = () => {
    if (!audioRef.current.paused)
      audioRef.current.pause();
  };

  const scrubble = (perc) => {
    setProgress(perc);
    audioRef.current.currentTime = audioRef.current.duration * perc;
  };

  const changeSpeed = () => {
    const index = SPEEDS.indexOf(speed);
    const newSpeed = SPEEDS[(index + 1) % SPEEDS.length];

    audioRef.current.playbackRate = newSpeed;
    setSpeed(newSpeed);
  };

  useEffect(() => {
    const _audioRef = audioRef.current;

    const canPlayHandler = () => setIsReady(true);
    const playHandler = () => setIsPlaying(true);
    const pauseHandler = () => setIsPlaying(false);
    const timeUpdateHandler = () => setProgress(_audioRef.currentTime / _audioRef.duration);

    _audioRef.addEventListener('loadeddata', canPlayHandler);
    _audioRef.addEventListener('play', playHandler);
    _audioRef.addEventListener('pause', pauseHandler);
    _audioRef.addEventListener('timeupdate', timeUpdateHandler);

    return () => {
      if (_audioRef.paused)
        _audioRef.pause();

      _audioRef.removeEventListener('loadeddata', canPlayHandler);
      _audioRef.removeEventListener('play', playHandler);
      _audioRef.removeEventListener('pause', pauseHandler);
      _audioRef.removeEventListener('timeupdate', timeUpdateHandler);
    };
  }, [audioRef]);

  useEffect(() => {
    if (!isReady) return;

    setDuration(Math.round(isPlaying
      ? audioRef.current.duration * progress
      : audioRef.current.duration
    ));
  }, [isReady, isPlaying, progress, audioRef]);

  return (
    <div className={classnames('tw-py-1', className)}>
      <audio
        ref={audioRef}
        src={src}
        controls={false}
        className="tw-hidden"
        preload="metadata"
      />

      <div className="tw-flex tw-gap-2.5 tw-relative">
        <span
          className="
            tw-cursor-pointer tw-select-none
            tw-rounded-xl tw-h-6 tw-w-11
            tw-flex tw-items-center tw-justify-center
            tw-text-white tw-text-xs tw-font-semibold
            tw-bg-black/50 dark:tw-bg-white/50

            tw-order-2 [.message-right_&]:tw-order-none
          "
          onClick={changeSpeed}
        >
          {speed}&times;
        </span>

        <div className="tw-flex tw-gap-4">
          <span
            className="tw-cursor-pointer tw-p-0.5 tw-text-icon-secondary dark:tw-text-icon-secondary-dark"
            onClick={ isPlaying ? pause : play }
          >
            {
              isPlaying
                ? <PiPauseFill className="tw-w-5 tw-h-5"/>
                : <PiPlayFill className="tw-w-5 tw-h-5"/>
            }
          </span>
          
          <WaveSlider
            progress={Math.floor(progress * 100) / 100}
            onScrubEnd={scrubble}
          />
        </div>

        <span
          className="
            tw-absolute -tw-bottom-6
            tw-text-[.6875rem] tw-leading-[.8125rem] tw-font-normal
            tw-text-black/50 dark:tw-text-white/50

            tw-left-8 [.message-right_&]:tw-left-[5.5rem]
          "
        >
          { formatDuration(duration) }
        </span>
      </div>
    </div>
  )
}
