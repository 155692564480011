// import { useTranslation } from 'react-i18next';

import { Modal, ModalFooter, ModalHeader, ModalTitle, ModalSubTitle, ModalCloseButton, ModalBody } from './Modal';
import useModalStore from '../../../stores/useModalStore';

export default function BaseModal () {
  const { data } = useModalStore((state) => state.modal);
  const closeModal = useModalStore((state) => state.closeModal);

  // const { t } = useTranslation();

  return (
    <Modal open={true} onClose={closeModal} size="xl">
      <ModalHeader className="tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <ModalTitle>
            { data.title || 'Título' }
          </ModalTitle>
          <ModalSubTitle>
            { data.subtitle || 'Subtítulo' }
          </ModalSubTitle>
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        { data.message || 'Lorem ipsum odor amet, consectetuer adipiscing elit. Malesuada gravida euismod dis cursus semper sed. Lobortis mus ornare pellentesque torquent aptent. Ligula dui ultricies maximus quis per duis tempus torquent volutpat. Fames velit dolor ac elementum feugiat. Tortor placerat ultrices donec tempor porta. Per fames conubia sapien sodales mollis ultricies pulvinar euismod?' }
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          onClick={closeModal}
          className="
            tw-inline-flex tw-justify-center
            tw-rounded-md tw-bg-indigo-600
            tw-px-3 tw-py-2
            tw-text-sm tw-font-semibold tw-text-white
            tw-shadow-sm
            hover:tw-bg-indigo-500 focus-visible:tw-outline
            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
            focus-visible:tw-outline-indigo-600
          "
        >
          { data.buttonText || 'Fechar' }
        </button>
      </ModalFooter>
    </Modal>
  )
}
