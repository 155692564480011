import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ReactDom from 'react-dom/client';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';

import {
  GoBriefcase,
  GoCommentDiscussion,
  GoFold,
  GoPencil,
  GoSmiley
} from 'react-icons/go';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { ReactComponent as StarsIcon } from '../../../../assets/stars.svg';
import { ReactComponent as GoAwkwardIcon } from '../../../../assets/go_awkward.svg';
import { Dropdown, DropdownButton, DropdownMenu, DropdownSection, DropdownItem, DropdownIcon } from '../../../../components/Dropdown';
import { sakBaseUrl, classnames } from '../../../../utils/usefulFunctions';
import Tooltip from '../../../Tooltip';

function useHasAIProvider () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
  });

  const queryUrl = sakBaseUrl('/Js/TemProvedorIA?') + queryParams.toString();

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 7200_000,
  });

  return {
    hasProvider: data?.data?.has_provider ?? false,
    experimentalFeatures: data?.data?.experimental_features ?? false,
    isLoading,
    error,
    mutate
  };
}

function useTextEnhancement (text) {
  const { data, isMutating, error, trigger } = useSWRMutation('textEnhancement', async (key, { arg }) => {
    const queryParams = new URLSearchParams({
      tokenchat: window.sakChatToken,
      chave_empreender: window.sakChatChave,
      text: arg?.text || text,
      tone: arg?.tone || 'friendly',
    });
  
    const queryUrl = sakBaseUrl('/Js/AprimorarMensagem?' + queryParams.toString());

    const res = await fetch(queryUrl);
    const data = await res.json();

    if (data.status === 'error')
      data.code = res.status;

    return data;
  });

  let err = error;
  if (!err && data?.status === 'error') {
    err = new Error(data.message);
    err.code = data.code;
  }

  return {
    enhancedText: data?.data?.text || '',
    error: err,
    isMutating,
    trigger
  };
}

export default function ChatAssistant() {
  const { t } = useTranslation();

  const { enhancedText, error, isMutating, trigger } = useTextEnhancement();
  const { hasProvider } = useHasAIProvider();

  const rewriteMessage = (tone) => {
    const baseMessage = window.jQuery('#chat-id-1-input').val() || '';

    if (!baseMessage) {
      setImmediate(() => {
        const el = window.jQuery('#chat-id-1-input').focus().get(0);
        el?.setCustomValidity('Campo obrigatório');
        el?.reportValidity();
      });
      return;
    }

    const triggerPromise = trigger({
      text: baseMessage,
      tone
    });

    const wrapperEl = document.createElement('div');
    window.jQuery('#chat-id-1-input').parent().append(wrapperEl);

    const root = ReactDom.createRoot(wrapperEl);
    root.render((
      <div
        className="
          tw-absolute tw-z-10 tw-top-0 tw-left-0
          tw-w-full tw-h-full
          tw-flex tw-items-center tw-justify-center
          before:tw-bg-black before:tw-opacity-10 before:tw-rounded-t-md before:tw-absolute before:tw-inset-0
        "
      >
        <AiOutlineLoading3Quarters className="tw-w-8 tw-h-8 tw-text-gray-900 dark:tw-text-white tw-animate-spin" />
      </div>
    ));

    triggerPromise.finally(() => {
      root.unmount();
      wrapperEl.remove();
    });
  }

  useEffect(() => {
    if (enhancedText) {
      const chatInputEl = window.jQuery('#chat-id-1-input').get(0);
      Object.getOwnPropertyDescriptor(chatInputEl, 'value').set.call(chatInputEl, enhancedText);
      chatInputEl.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }, [enhancedText]);

  useEffect(() => {
    if (error) {
      const el = window.jQuery('#chat-id-1-input').focus().get(0);
      el?.setCustomValidity(error.message);
      el?.reportValidity();
    }
  }, [error]);

  return (
    <Dropdown as="div">
      <DropdownButton
        as="span"
        className="
          tw-flex tw-items-center tw-cursor-pointer tw-rounded-md
          data-[open]:tw-text-active data-[open]:dark:tw-text-active-dark
        "
      >
        <Tooltip text="Ferramentas de escrita" position="top">
          <StarsIcon className="tw-w-5 tw-h-5" />
        </Tooltip>
      </DropdownButton>
      <DropdownMenu
        anchor="top"
        className="[--anchor-gap:1rem]"
      >
        <DropdownSection>
          <div
            className="tw-px-4 tw-py-2"
          >
            <div
              className="
                tw-leading-7 tw-font-medium
                tw-text-gray-900 dark:tw-text-white
              "
            >
              { t('Ferramentas de escrita') }
            </div>
          </div>
        </DropdownSection>

        {
          !hasProvider && (
            <DropdownSection>
              <div
                className="tw-px-4 tw-py-2"
              >
                <div
                  className="
                    tw-text-xs tw-font-medium
                    //tw-text-gray-900 //dark:tw-text-white
                    tw-text-gray-500 dark:tw-text-gray-400

                    [&>a]:tw-text-active [&>a]:dark:tw-text-active-dark
                  "
                >
                  <i className="fe fe-alert-circle pr-2"/>
                  <Trans i18nKey="Nenhum provedor de IA">
                    Nenhum provedor de IA disponível. Configure um através do <a href="https://app.sak.com.br/integracoes#openai" target="_blank" rel="noreferrer">menu de integrações</a>.
                  </Trans>
                </div>
              </div>
            </DropdownSection>
          )
        }

        <DropdownSection
          className={classnames(
            !hasProvider && 'tw-cursor-not-allowed'
          )}
        >
          <div
            className={classnames(
              (!hasProvider || isMutating) && 'tw-pointer-events-none tw-opacity-50'
            )}
          >
            <DropdownItem
              onClick={() => rewriteMessage('friendly')}
            >
              <DropdownIcon icon={GoSmiley} />
              { t('Tornar amigável') }
            </DropdownItem>
            <DropdownItem
              onClick={() => rewriteMessage('persuasive')}
            >
              <DropdownIcon icon={GoCommentDiscussion} />
              { t('Tornar persuasivo') }
            </DropdownItem>
            <DropdownItem
              onClick={() => rewriteMessage('professional')}
            >
              <DropdownIcon icon={GoBriefcase} />
              { t('Tornar profissional') }
            </DropdownItem>
            <DropdownItem
              onClick={() => rewriteMessage('apologetic')}
            >
              <DropdownIcon icon={GoAwkwardIcon} />
              { t('Tornar apologético') }
            </DropdownItem>
            <DropdownItem
              onClick={() => rewriteMessage('concise')}
            >
              <DropdownIcon icon={GoFold} />
              { t('Tornar conciso') }
            </DropdownItem>
          </div>
        </DropdownSection>
        <DropdownSection
          className={classnames(
            !hasProvider && 'tw-cursor-not-allowed'
          )}
        >
          <div
            className={classnames(
              (!hasProvider || isMutating) && 'tw-pointer-events-none tw-opacity-50'
            )}
          >
            <DropdownItem
              onClick={() => rewriteMessage('none')}
            >
              <DropdownIcon icon={GoPencil} />
              { t('Revisar gramática e ortografia') }
            </DropdownItem>
          </div>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  )
}
