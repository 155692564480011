import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
    border-top-color: var(--secondary);
  }
  50% {
    transform: rotate(180deg);
    border-top-color: var(--scroll);
  }
  100% {
    transform: rotate(360deg);
    border-top-color: var(--secondary);
  }
`;

export const Container = styled.section`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  > div {
    position: fixed;
    // background: var(--light);
    width: 100%;
    height: 100%;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  span {
    position: fixed;
    width: 100%;
    height: 100%;

    border: 6px solid var(--border);
    border-radius: 50%;
    border-top: 6px solid var(--button-color);
    width: 75px;
    height: 75px;
    animation: ${rotate} 2s linear infinite;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked ~ label > div img {
    transform: scale(2);
    cursor: zoom-out;
  }

  > img {
    position: fixed;
    top: 15px;
    left: 15px;
    max-width: 45px;
  }

  label {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    max-width: 70vw;
    max-height: 70vh;

    div img {
      width: 100%;
      /* height: 100%; */
      max-height: 100vh;
      transition: transform 0.25s ease;
      cursor: zoom-in;
    }
  }

  canvas {
    width: 100%;
    /* height: 100%; */
    max-height: 100dvh;
    min-width: 30dvh;
    transition: transform 0.25s ease;
    cursor: grab;
  }

  i {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 2rem;
    z-index: 101;
    cursor: pointer;
  }
`;
